import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
import {
  DrawerContext,
  AuthContext,
  FullScreenContext,
  AlertContext,
} from "../../contexts";
import { DrawerProps } from "../../utils";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  Header,
  CustomizeTabs,
  AppointmentListCard,
  SearchFilter,
  AppointmentCancel,
} from "../../components";
import {
  getUpcomingAppointments,
  getPastAppointments,
} from "../../graphql/queries";
import { useLazyQuery } from "@apollo/client";
import moment from "moment";
import { NetWorkCallMethods, AlertProps } from "../../utils";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
    paddingTop: 80,
  },
  header: {
    position: "fixed",
    zIndex: 1000,
    [theme.breakpoints.up("md")]: {
      width: 768,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  gridContainer: {
    height: "100%",
    width: "100%",
    justify: "center",
    alignItems: "center",
  },
  headerText: {
    marginLeft: "8px",
    marginBottom: "10px",
  },
  noData: {
    height: "180px",
    textAlign: "center",
    background: "#0079650A",
    borderRadius: "8px",
    marginTop: "24px",
  },
}));

export const Appointments = (props) => {
  const history = useHistory();
  const classes = useStyles(props);
  let currentDateTime = moment.utc();
  const drawerContext = React.useContext(DrawerContext);
  const alert = React.useContext(AlertContext);
  const fullScreenLoader = React.useContext(FullScreenContext);
  const auth = React.useContext(AuthContext);
  const [state, setState] = React.useState({ search: "" });
  const [pastState, setPastState] = React.useState({ search: "" });
  const [reasonCancel, setReasonCancel] = React.useState({});
  const [getDoctors, { data, loading, error }] = useLazyQuery(
    getUpcomingAppointments(auth?.user?.profile?.id, state.search),
    { fetchPolicy: "no-cache" }
  );
  const [
    pastRecordDoctors,
    { data: pastData, error: isError, loading: isLoading },
  ] = useLazyQuery(
    getPastAppointments(auth?.user?.profile?.id, pastState.search)
  );

  const cancelAppointment = async (
    appointment_id,
    appointed_doctor_id,
    event_id
  ) => {
    try {
      drawerContext.setDrawer({
        ...drawerContext,
        open: false,
      });
      fullScreenLoader.setLoader({
        ...fullScreenLoader,
        open: true,
        message: "Cancelling Your Appointment...",
      });
      let body = {
        appointment_id,
        appointed_doctor_id,
        event_id,
        cancellationReason: reasonCancel?.text,
      };
      // Cancel appointment API call
      const res = await NetworkCall(
        config.rest_api_service + "/appointment/cancel",
        NetWorkCallMethods.post,
        body,
        null,
        true
      );
      // Stoping the Loaders
      fullScreenLoader.setLoader({
        ...fullScreenLoader,
        open: false,
        message: "",
      });
      // Navigating to appointments page
      if (parseInt(res.data.statusCode) === 200) {
        history.push(Routes.appointments);
      } else {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          msg: res.data?.message ?? "Something went wrong, Try Again!",
        });
      }
    } catch (error) {
      console.log("Error while cancelling appointment: ", error);
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        msg: "Something went wrong, Try Again!",
      });
    }
  };

  const goBack = () => {
    history.goBack();
  };
  const goHome = () => {
    history.push(Routes.home);
  };
  const handleNoCancel = () => {
    drawerContext.setDrawer({
      ...drawerContext,
      open: false,
    });
    history.push(Routes.appointments);
  };

  const reasonTextChange = (key, value) => {
    if (value !== null) {
      reasonCancel[key] = value;
      setReasonCancel({
        ...reasonCancel,
      });
    }
  };

  const handleCancel = (appointmentId, doctorId, eventId) => {
    drawerContext.setDrawer({
      ...drawerContext,
      open: true,
      direction: DrawerProps.direction.bottom,
      variant: DrawerProps.variant.temporary,
      component: (
        <div>
          <AppointmentCancel
            value={reasonCancel?.text}
            goCancel={handleNoCancel}
            yesCancel={() =>
              cancelAppointment(appointmentId, doctorId, eventId)
            }
            onChange={(value) => reasonTextChange("text", value)}
          />
        </div>
      ),
      onClose: () => onDrawerClosed(),
    });
  };

  const onDrawerClosed = () => {};

  const searchUser = (newData) => {
    setState({
      search: newData,
    });
  };

  const searchpastRecord = (newData) => {
    setPastState({
      search: newData,
    });
  };

  React.useEffect(() => {
    if (getDoctors) {
      getDoctors({
        variables: {
          search: state.search,
        },
      });
    }
    if (pastRecordDoctors) {
      pastRecordDoctors({
        variables: {
          search: pastState.search,
        },
      });
    }
    // eslint-disable-next-line
  }, [state.search, pastState.search]);

  const appointmetView = (appointmentId) => {
    history.push(`${Routes.appointmentSummaryViewParent}${appointmentId}`);
  };

  return (
    <>
      <div className={classes.header}>
        <Header
          showBackIcon={true}
          showHomeIcon={true}
          handleBack={goBack}
          handleHome={goHome}
        />
      </div>
      <div className={classes.root}>
        <Grid className={classes.gridContainer}>
          <Grid item className={classes.headerText}>
            <Typography variant="h6">Appointments</Typography>
          </Grid>
          <Grid item>
            <CustomizeTabs
              searchFilter={
                <SearchFilter
                  showFilter={false}
                  handleChange={searchUser}
                  value={state.search}
                />
              }
              tab1={
                loading ? (
                  <Grid>
                    {[...Array(2)].map((e, i) => {
                      return (
                        <Grid item key={i} style={{ marginTop: "16px" }}>
                          <Skeleton
                            variant="rect"
                            width={"100%"}
                            height={180}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                ) : data?.allAppointments?.appointments?.length > 0 ? (
                  data?.allAppointments?.appointments?.map((item) => {
                    return (
                      <AppointmentListCard
                        appointmetView={() => appointmetView(item.id)}
                        key={item.id}
                        title={"Dr. " + item?.doctorDetail?.name ?? ""}
                        profile_pic={item?.doctorDetail?.profilePic}
                        firstSubText={`${
                          item.isSelfBooking
                            ? " For " + item?.bookingFor?.name + " (you) "
                            : " For " + item?.bookingFor?.name
                        }`}
                        price={item?.doctorDetail?.consulationCharge}
                        date={`${moment
                          .utc(item?.appointmentDateTime)
                          .format("DD MMM, YYYY")}`}
                        time={moment(item?.appointmentDateTime).format(
                          "hh:mm A"
                        )}
                        firstBtn="Cancel"
                        secondBtn="Join"
                        subText={moment
                          .utc(item?.appointmentDateTime)
                          .diff(currentDateTime, "hours")}
                        handleCancel={() =>
                          handleCancel(
                            item?.id,
                            item?.doctorDetail?.id,
                            item?.eventId
                          )
                        }
                        handleClick={() => window.open(item?.meetUrl)}
                      />
                    );
                  })
                ) : (
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    className={classes.noData}
                  >
                    <Grid item>
                      <img src="/images/Group 5135.svg" alt="No" />
                      <Typography variant="body2" color="textPrimary">
                        {error
                          ? "Something went wrong, Try Again!"
                          : "No actions here at the moment!"}
                      </Typography>
                    </Grid>
                  </Grid>
                )
              }
              tab1heading={
                loading
                  ? "Upcoming"
                  : `Upcoming (${
                      data?.allAppointments?.appointments?.length < 1
                        ? 0
                        : data?.allAppointments?.appointments?.length < 10
                        ? `0${data?.allAppointments?.appointments?.length}`
                        : data?.allAppointments?.appointments?.length
                    })`
              }
              showTab1={true}
              showSearchFilter={true}
              showSearchFilter2={true}
              tab2={
                isLoading ? (
                  <Grid>
                    {[...Array(2)].map((e, i) => {
                      return (
                        <Grid item key={i} style={{ marginTop: "16px" }}>
                          <Skeleton
                            variant="rect"
                            width={"100%"}
                            height={180}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                ) : pastData?.allAppointments?.appointments?.length > 0 ? (
                  pastData?.allAppointments?.appointments?.map((item) => {
                    return (
                      <AppointmentListCard
                        appointmetView={() => appointmetView(item.id)}
                        key={item.id}
                        title={"Dr. " + item?.doctorDetail?.name ?? ""}
                        profile_pic={item?.doctorDetail?.profilePic}
                        firstSubText={`${
                          item.isSelfBooking
                            ? " For " + item?.bookingFor?.name + " (you) "
                            : item?.bookingFor?.name
                        }`}
                        price={item?.doctorDetail?.consulationCharge}
                        appointmentStatus={item?.appointmentStatus}
                        date={`${moment
                          .utc(item?.appointmentDateTime)
                          .format("DD MMM, YYYY")}`}
                        time={moment(item?.appointmentDateTime).format(
                          "hh:mm A"
                        )}
                        statusText={item?.status?.appointmentStatus}
                      />
                    );
                  })
                ) : (
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    className={classes.noData}
                  >
                    <Grid item>
                      <img src="/images/Group 5135.svg" alt="No" />
                      <Typography variant="body2" color="textPrimary">
                        {isError
                          ? "Something went wrong, Try Again!"
                          : "No actions here at the moment!"}
                      </Typography>
                    </Grid>
                  </Grid>
                )
              }
              tab2heading={
                isLoading
                  ? "Past"
                  : `Past (${
                      pastData?.allAppointments?.appointments?.length < 1
                        ? 0
                        : pastData?.allAppointments?.appointments?.length < 10
                        ? `0${pastData?.allAppointments?.appointments?.length}`
                        : pastData?.allAppointments?.appointments?.length
                    })`
              }
              showTab2={true}
              searchFilter2={
                <SearchFilter
                  showFilter={false}
                  handleChange={searchpastRecord}
                  value={pastState.search}
                />
              }
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
