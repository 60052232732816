import React from "react";
import OtpInput from "react-otp-input";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  otp: {
    margin: "auto",
    boxShadow: "none",
    "& input": {
      boxShadow: "none",
      height: "50px",
      width: "70px !important",
      background: theme.palette.common.white,
      borderRadius: 8,
      border: `2px solid ${theme.palette.border.light}`,
      "&:focus": {
        border: `2px solid ${theme.palette.primary.main}`,
        outline: "none",
      },
    },
  },
}));

export const EnterOtp = (props) => {
  const classes = useStyles(props);
  return (
    <div>
      <Typography variant="body2" color="primary" align="left" gutterBottom>
        OTP
      </Typography>
      <OtpInput
        isInputNum
        shouldAutoFocus={true}
        className={classes.otp}
        value={props.value}
        onChange={props.handleChange}
        numInputs={4}
        separator={<span>&nbsp;&nbsp;&nbsp;</span>}
      />
    </div>
  );
};
