import React from "react";
import {
  makeStyles,
  Grid,
  Typography,
  CardContent,
  Card,
  Avatar,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
    "& .MuiCardContent-root": {
      padding: "10px",
      paddingLeft: "16px",
      paddingRight: "16px",
    },
  },
}));

export const CardDateTimeChild = (props) => {
  const classes = useStyles(props);
  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Avatar
              style={{
                color: "#0F0B11",
                backgroundColor: "#F5F5F5",
              }}
            >
              <img src="/images/icons8-calendar.svg" alt="No" />
            </Avatar>
          </Grid>
          <Grid item>
            <div>
              <Typography variant="body2" color="textSecondary">
                Date
              </Typography>
              <Typography variant="subtitle1" color="textPrimary">
                {props.date}
              </Typography>
            </div>
          </Grid>
          <Grid item>
            <Avatar
              style={{
                color: "#0F0B11",
                backgroundColor: "#F5F5F5",
              }}
            >
              <img src="/images/user.svg" alt="userImage" />
            </Avatar>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              Patient
            </Typography>
            <Typography variant="subtitle2" color="textPrimary">
              {props.patient}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
