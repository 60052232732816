import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
import { DatePatient } from "../../components";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  Header,
  SearchFilter,
  SimpleModal,
  DocumentViewer,
} from "../../components";
import { getCaseSummary } from "../../graphql/queries";
import { useLazyQuery } from "@apollo/client";
import { AuthContext } from "../../contexts";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
    paddingTop: 80,
  },
  header: {
    position: "fixed",
    zIndex: 1000,
    [theme.breakpoints.up("md")]: {
      width: 768,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  gridContainer: {
    height: "100%",
    width: "100%",
    justify: "center",
    alignItems: "center",
  },
  headerText: {
    marginLeft: "8px",
    marginBottom: "10px",
  },
  noData: {
    height: "180px",
    textAlign: "center",
    background: "#0079650A",
    borderRadius: "8px",
    marginTop: "24px",
  },
}));

export const CaseSummary = (props) => {
  const history = useHistory();
  const classes = useStyles(props);
  const auth = React.useContext(AuthContext);
  const [state, setState] = React.useState({ search: "" });
  const [open, setOpen] = React.useState(false);
  const [view, setView] = React.useState("");
  let daybeforeYesterday = moment.utc().subtract(2, "day").format("YYYY-MM-DD");
  const [getDoctors, { data, loading, error }] = useLazyQuery(
    getCaseSummary(auth?.user?.profile?.id, state.search)
  );
  const goBack = () => {
    history.goBack();
  };
  const goHome = () => {
    history.push(Routes.home);
  };
  const searchUser = (newData) => {
    setState({
      search: newData,
    });
  };
  const handleOpen = (i) => {
    setOpen(true);
    setView(i);
  };
  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (getDoctors) {
      getDoctors({
        variables: {
          search: state.search,
        },
      });
    }
    // eslint-disable-next-line
  }, [state.search]);

  return (
    <>
      <div className={classes.header}>
        <Header
          showBackIcon={true}
          showHomeIcon={true}
          handleBack={goBack}
          handleHome={goHome}
        />
      </div>
      <div className={classes.root}>
        <Grid className={classes.gridContainer}>
          <Grid item className={classes.headerText}>
            <Typography variant="h6">Case Summary</Typography>
          </Grid>
          <Grid item>
            <SearchFilter
              showFilter={false}
              handleChange={searchUser}
              value={state.search}
            />
          </Grid>
          {loading ? (
            <Grid container>
              {[...Array(2)].map((e, i) => {
                return (
                  <Grid item xs={12} key={i} style={{ marginTop: "16px" }}>
                    <Skeleton variant="rect" width={"100%"} height={180} />
                  </Grid>
                );
              })}
            </Grid>
          ) : data?.allAppointments?.caseSummary?.length > 0 ? (
            data?.allAppointments?.caseSummary?.map((item) => {
              let consultDate = moment
                .utc(item?.appointmentDate)
                .format("YYYY-MM-DD");
              return (
                <Grid item style={{ marginTop: "15px" }}>
                  <DatePatient
                    title="Consultation"
                    firstSubText={`by Dr. ${item?.userProfileByAssignedDoctor?.firstName}`}
                    patient={item?.userProfileByBookingFor?.firstName}
                    date={`${moment
                      .utc(item?.appointmentDate)
                      .format("DD MMM, YYYY")}`}
                    new={consultDate > daybeforeYesterday ? "new" : ""}
                    report_img="/images/icons8-flip-chart.svg"
                    handleReport={() =>
                      handleOpen(
                        item?.visitSummariesByAppointmentId?.Report[0]
                          ?.visitReportUrl
                      )
                    }
                  />
                </Grid>
              );
            })
          ) : (
            <Grid
              container
              justify="center"
              alignItems="center"
              className={classes.noData}
            >
              <Grid item>
                <img src="/images/Group 5135.svg" alt="No" />
                <Typography variant="body2" color="textPrimary">
                  {error
                    ? "Something went wrong, Try Again!"
                    : "No actions here at the moment!"}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
        <SimpleModal
          open={open}
          onClose={handleClose}
          download={view}
          component={<DocumentViewer url={view} />}
        />
      </div>
    </>
  );
};
