import React from "react";
import { withNavBars } from "../../HOCs";
import { CaseSummary } from "./caseSummary";
class caseSummaryParent extends React.Component {
  render() {
    return <CaseSummary />;
  }
}

export default withNavBars(caseSummaryParent);
