import React from "react";
import { Typography, Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Cardheader } from "../cardheader";
import { CalendarRoller } from "../calendarroller";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import moment from "moment";
import { AppointmentContext } from "../../contexts";
import momentTimeZone from "moment-timezone";

const useStyles = makeStyles((theme) => ({
  slotCard: {
    border: `1px solid ${theme.palette.border.light}`,
    borderBottom: "none",
    boxShadow: "0px -10px 10px #0000000A",
  },
  root1: {
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
  root: {
    margin: theme.spacing(2),
    marginTop:(props) => props.followUp ? "280px" : "155px",
    backgroundColor: theme.palette.background.paper,
    paddingBottom: "95px",
  },
  slotTitle: {
    padding: theme.spacing(2),
    borderTop: "1px dashed #C5C5C5",
  },
  toggleBtn: {
    height: "40px",
    display: "inline-flex",
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  secondButton: {
    backgroundColor: `${theme.palette.primary.main}`,
    color: theme.palette.common.white,
    fontSize: "14px",
    boxShadow: "none",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "20%",
    },
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: `${theme.palette.primary.main}`,
      color: theme.palette.common.white,
      boxShadow: "none",
    },
  },
  footerbtn: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    padding: 24,
    borderTop: "2px dashed #00000014",
    boxShadow: "0px -5px 10px #00000014",
    textAlign: "center",
      bottom: 0,
      position: "fixed",
  },
  root3: {
    "& .MuiToggleButton-root": {
      borderRadius: 10,
      fontFamily: "healthcircles_medium",
      backgroundColor: `${theme.palette.common.white}!important`,
      border: `1.5px solid ${theme.palette.border.light}`,
      textTransform: "capitalize",
      "& .MuiToggleButton-label": {
        color: (props) => (props?.showTimeIcon ? "#184457" : "#0F0B11"),
      },
    },
    "& .Mui-selected": {
      backgroundColor: `${theme.palette.primary.main} !important`,
      "& .MuiToggleButton-label": {
        color: `${theme.palette.background.paper} !important`,
      },
      scheduleIcon: {
        color: theme.palette.common.white,
      },
    },
  },
  clockIcon: {
    padding: "3px",
    marginTop: "1px",
  },
  scheduleIcon: {
    paddingTop: "5px",
    paddingRight: "1px",
  },
  btnGroup: {
    flexWrap: "wrap",
    "& .MuiToggleButton-root": {
      marginLeft: 0,
      marginTop: 0,
      width: "95px",
      padding: 0,
      height: "40px",
    },
  },
  header: {
    position: "fixed",
    borderRadius: "24px 24px 0px 0px",
    backgroundColor: theme.palette.background.paper,
    zIndex: 1000,
    width: "100%",
  },
}));

export const TimeSlotToggle = (props) => {
  const classes = useStyles(props);

  const [formats, setFormats] = React.useState(() => props?.slotValue ?? "");
  const [btnDisable, setBtnDisable] = React.useState(true);
  const [selectedDate, selectDate] = React.useState(props.selectedDate);
  let appointmentContext = React.useContext(AppointmentContext);

  const handleFormat = (event, newFormats) => {
    setFormats(newFormats);
    setBtnDisable(false)
    props?.onSelect("appointment_time_slot", moment.utc(moment(newFormats)).format("HH:mm:ss"));
    props?.onSelect("appointment_date", moment.utc(moment(newFormats)).format("YYYY-MM-DD"));
    props?.onSelect("appointment_client_start_time", newFormats);
    props?.onSelect("appointment_client_end_time", moment(newFormats).add(15,'minute').format().toString());
    appointmentContext.selectedDoctor = props.doctor;
    appointmentContext.clientTimeZone = momentTimeZone.tz.guess();
  };

  const giveMeCalendarHeader = () => {
    let header = '';
    header += moment(selectedDate).format('MMM, YYYY');

    if (moment(selectedDate).add(1, 'day').format("MM") !== moment().add(7, 'day').format("MM")) {
      header += moment(selectedDate).format('MMM, YYYY');
    }
    return header;
  }

  const giveMeDates = () => {

    let dates = [];
    if (props.followUpRollerIndex){
      //from appointment date to followupdate plus 7 days
      for (let index = 1; index <= (props.followUpRollerIndex + 7) ; index++) {
        let date = moment(props.appointmentDate).add(index, 'day')
        dates.push({
          date: date.format("YYYY-MM-DD"),
          isAvailable: props?.toggleButtonData?.[date.format("YYYY-MM-DD").toString()]?.length > 0,
          day_of_month: date.format("DD"),
          day_of_week: date.format("ddd"),
        })
      }
    }else{
    for (let index = 1; index <= 7; index++) {
      let date = moment().add(index, 'day')
      dates.push({
        date: date.format("YYYY-MM-DD"),
        isAvailable: props?.toggleButtonData?.[date.format("YYYY-MM-DD").toString()]?.length > 0,
        day_of_month: date.format("DD"),
        day_of_week: date.format("ddd"),
      })
    }
  }
    return dates;
  }

  return (
    <div className={classes.slotCard}>
      <div className={classes.header}>
        <div className={classes.root1}>
          <Cardheader
            profile_pic={props.profile_pic}
            price={props.price}
            title={props.title}
            firstSubText={props.firstSubText}
            firstIcon={props.firstIcon}
            secondSubText={props.secondSubText}
            secondIcon={props.secondIcon}
          />
        </div>
          <>
          {props.followUp && (
            <div style={{marginBottom: "16px"}}>
              <CalendarRoller
                selectBtn={selectDate}
                Header={giveMeCalendarHeader()}
                date={giveMeDates()}
                selectedDate={selectedDate}
              />
            </div>
          )}
          <div className={classes.slotTitle}>
            <Typography variant="subtitle2" color="textPrimary">
              Slots available on {moment(selectedDate, 'YYYY-MM-DD').format('Do MMM, YYYY')}
            </Typography>
          </div>
          </>
      </div>
      <div className={classes.root}>
        <div className={classes.root3}>
          <ToggleButtonGroup
            className={classes.btnGroup}
            value={formats}
            onChange={handleFormat}
            exclusive
          >
            {props?.toggleButtonData?.[selectedDate]?.length > 0 &&
              props?.toggleButtonData?.[selectedDate].map((_, key) => {
                if (_.client_date_time) {
                  return (
                    <ToggleButton
                      disabled={props.disabled}
                      className={classes.toggleBtn}
                      key={"tog" + key}
                      value={_.client_date_time}
                      aria-label={_.client_date_time}
                    >
                      {props.showTimeIcon && (
                        <span className={classes.scheduleIcon}>
                          <ScheduleIcon className={classes.clockIcon} />
                        </span>
                      )}
                      {_.slot}
                    </ToggleButton>
                  );
                }
                return <></>;
              })}
          </ToggleButtonGroup>
        </div>
      </div>
      <Grid className={classes.footerbtn}>
        <Grid item xs={12}>
          <Button
            onClick={() => props.goNext()}
            disabled={btnDisable}
            className={classes.secondButton}
            variant="contained"
          >
            continue
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
