import React from "react";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import { makeStyles, Typography } from "@material-ui/core";
import DOB from "../dob";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiToggleButton-root": {
      borderRadius: 10,
      fontFamily: "healthcircles_medium",
      backgroundColor: `${theme.palette.common.white}!important`,
      border: `1.5px solid ${theme.palette.border.light}`,
      textTransform: "capitalize",
      "& .MuiToggleButton-label": {
        color: (props) => (props?.labelColor ? "#184457" : "#0F0B11"),
      },
    },
    "& .Mui-selected": {
      backgroundColor: `${theme.palette.primary.main} !important`,
      "& .MuiToggleButton-label": {
        color: `${theme.palette.background.paper} !important`,
      },
    },
  },
  btnGroup: {
    flexWrap: "wrap",
    "& .MuiToggleButton-root": {
      marginLeft: 0,
      marginTop: 0,
    },
  },
  toggleBtn: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export const ToggleButtonComponent = (props) => {
  const classes = useStyles(props);

  const handleFormat = (event, newFormats) => {
    props?.onSelect(newFormats);
  };

  return (
    <div className={classes.root}>
      <ToggleButtonGroup
        className={classes.btnGroup}
        value={props.isSelected}
        onChange={handleFormat}
        exclusive={props?.exclusive ?? false}
      >
        {props?.toggleButtonData &&
          props?.toggleButtonData.map((_, key) => {
            if (_.label) {
              return (
                <ToggleButton
                  disabled={props.disabled}
                  className={classes.toggleBtn}
                  key={"tog" + key}
                  value={_.value}
                  aria-label={_.value}
                >
                  {_.label}
                </ToggleButton>
              );
            }
            return <></>;
          })}
      </ToggleButtonGroup>
      {props.dobInput && (
        <DOB
          value={props.fieldValue}
          isSelected={props.isSelected}
          onChange={props.onChange}
        />
      )}
      <div>
        {props.error && (
          <Typography variant="caption" color="error">
            {props.helperText}
          </Typography>
        )}
      </div>
    </div>
  );
};
