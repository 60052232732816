import React from "react";
import { Grid, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginTop: "10%"
    }
}))

export const FindDoctorNotFound = ({
    props = false
}) => {

    const classes = useStyles();

    return <div className={classes.root}>
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
        >
            <Grid item>
                <img src="/images/Group 5135.svg" alt="No" />
            </Grid>
            <Grid item>
                <Typography variant="body2" color="textPrimary">
                    {"Sorry! No Doctor available at the moment"}
                </Typography>
            </Grid>
        </Grid>
    </div>
}