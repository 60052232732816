import React, { useRef } from "react";
// import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
} from "@material-ui/core";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const useStyles = makeStyles((theme) => ({
  root: {
    borderTop:(noBorder) => noBorder ? "" : "1px dashed #C5C5C5",
  },
  parent: {
    // borderRadius: 6,
    padding: "4px 4px",
    alignItems: "center",
    scrollBehavior: "smooth",
    display: "flex",
    justifyContent: "space-between",
    "& .MuiIconButton-root": {
      padding: 3,
    },
  },
  listroot: {
    paddingBottom: 0,
    "& .MuiListItem-root": {
      paddingTop: 0,
      paddingBottom: 0,
      "& .MuiListItemIcon-root": {
        minWidth: 24,
      },
    },
  },

  newBtn1: {
    padding: "5px 5px",
    "& .MuiTypography-body1": {
      color: "#101010",
      fontFamily: "healthcircles_medium",
    },
    "& .MuiTypography-body2": {
      color: "#101010",
      textTransform: "uppercase",
      fontFamily: "healthcircles_medium",
    },
    textTransform: "none",
    fontWeight: 600,
    marginRight: "3%",
    border: `2px dashed ${theme.palette.border.light}`,
    borderRadius: "24px",
    minWidth: "35px",
    background: theme.palette.common.white,
    marginTop: 1,
    height: "65px",
    "&:hover": {
      "& .MuiTypography-body1": {
        color: theme.palette.common.white,
      },
      "& .MuiTypography-body2": {
        color: theme.palette.common.white,
      },
      backgroundColor: "#007965",
      border: "none",
    },
  },

  selectedbtn: {
    padding: "5px 5px",
    backgroundColor: "#007965",
    borderRadius: "24px",
    "& .MuiTypography-body1": {
      color: "#FFF",
      fontFamily: "healthcircles_medium",
    },
    "& .MuiTypography-body2": {
      color: "#FFF",
      textTransform: "uppercase",
      fontFamily: "healthcircles_medium",
    },
    fontWeight: 300,
    textTransform: "none",
    marginRight: "3%",
    minWidth: "35px",
    marginTop: 1,
    height: "auto",
    "&:hover": {
      backgroundColor: "#F5F5F5",
      border: `2px dashed ${theme.palette.primary.main}`,
      "& .MuiTypography-body1": {
        color: theme.palette.primary.main,
        fontFamily: "healthcircles_medium",
      },
      "& .MuiTypography-body2": {
        color: theme.palette.primary.main,
        textTransform: "uppercase",
        fontFamily: "healthcircles_medium",
      },
    },
  },
  unselectedbtn: {
    padding: "5px 5px",
    backgroundColor: "#F5F5F5",
    "& .MuiTypography-body1": {
      color: "#C5C5C5",
      fontFamily: "healthcircles_medium",
    },
    "& .MuiTypography-body2": {
      color: "#C5C5C5",
      textTransform: "uppercase",
      fontFamily: "healthcircles_medium",
    },
    fontWeight: 300,
    textTransform: "none",
    marginRight: "3%",
    border: `2px dashed ${theme.palette.border.light}`,
    borderRadius: "24px",
    minWidth: "35px",
    marginTop: 1,
    height: "auto",
    "&:hover": {
      backgroundColor: "#F5F5F5",
      color: "#C5C5C5",
    },
  },
  iconSize: {
    padding: 0,
    fontSize: 18,
    color: "#C5C5C5",
  },
  screen: {
    textAlign: "left",
    justifyContent: "center",
    display: "flex",
    // overflowX: "auto",
    marginLeft: "2%",
    width: "100%",
    scrollBehavior: "smooth",
    "& .MuiButton-contained.Mui-disabled":{
      backgroundColor: "#F5F5F5"
    },
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "none",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
    },
  },
}));

export const CalendarRoller = ({
  Header = "",
  date = [],
  selectBtn = () => null,
  handleScrollNext = () => false,
  handleScrollPrevious = () => false,
  selectedDate = null,
  noBorder = false,
}) => {
  const classes = useStyles(noBorder);
  const ref = useRef(null);

  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };

  return (
    <div className={classes.root}>
      <List
        component="div"
        aria-labelledby="nested-list-subheader"
        className={classes.listroot}
      >
        {Header && (
          <ListItem>
            <ListItemIcon>
              <img src="/images/icons8-calendar.svg" alt="No" />
            </ListItemIcon>
            <ListItemText primary={Header} />
          </ListItem>
        )}
      </List>
      <Grid className={classes.parent}>
        <Grid>
          <IconButton onClick={() => handleScrollPrevious() ? handleScrollPrevious() : scroll(-80)}>
            <ArrowBackIosIcon className={classes.iconSize} />
          </IconButton>
        </Grid>
        <Grid className={classes.screen} ref={ref}>
          {date?.map((val, i) => (
            <Button
              key={i}
              variant="contained"
              color={"inherit"}
              className={val.date === selectedDate ? classes.selectedbtn : classes.newBtn1}
              disableElevation
              disabled={!val.isAvailable}
              onClick={() => selectBtn(val.date)}
            >
              <div>
                <div>
                  <Typography variant="body1">{val.day_of_month} </Typography>
                </div>
                <div>
                  <Typography variant="body2">{val.day_of_week}</Typography>
                </div>
              </div>
            </Button>
          ))}
        </Grid>
        <Grid>
          <IconButton onClick={() => handleScrollNext() ? handleScrollNext() : scroll(80)}>
            <ArrowForwardIosIcon className={classes.iconSize} />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
};
