import React from "react";
import { makeStyles, Typography, Button, MenuItem, Menu, Grid } from "@material-ui/core";
import { Header, Textwithbutton } from "../../components";
import { Routes } from "../../router/routes";
import { useHistory } from "react-router-dom";
import { DrawerContext, AuthContext } from "../../contexts";
import { DrawerProps } from "../../utils";
import { StatusUpdate } from "./statusUpdate";
import { UpdateActivityStatus } from "../../graphql/mutations";
import { useMutation } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { getActivity } from "../../graphql/queries";
import moment from "moment";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    height: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  root: {
    padding: theme.spacing(3),
    paddingTop: 70,
    paddingBottom: theme.spacing(2),
    "& .MuiButton-root": {
      minWidth: 0,
    },
  },
  moreIcon: {
    border: `1.5px solid ${theme.palette.border.light}`,
    padding: "16px 10px",
    borderRadius: "8px",
  },
  time: {
    alignItems: "center",
    textAlign: "center",
    marginTop: "5px",
  },
  bigClock: {
    backgroundImage: `url(${'"/images/BigClock.svg"'})`,
    height: "295px",
    backgroundRepeat: "no-repeat",
    width: "295px",
    [theme.breakpoints.up("xs")]: {
      margin: "0% 30%",
      paddingTop: "12%",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "0%",
      paddingTop: "25%",
    },
  },
  activityTime: {
    marginTop: theme.spacing(3),
    fontSize: "24px",
  },
  completed: {
    borderRadius: "8px",
    width: "100%",
    border: `1.5px solid ${theme.palette.primary.main}`,
    textTransform: "capitalize",
    justifyContent: "left",
    [theme.breakpoints.up("xs")]: {padding: "11px 24px"},
    [theme.breakpoints.down("xs")]: {padding: "18px 24px"},
  },
  notCompleted: {
    textTransform: "capitalize",
    borderRadius: "8px",
    width: "100%",
    border: `1.5px solid ${theme.palette.error.light}`,
    marginTop: "16px",
    justifyContent: "left",
    "& .MuiTypography-subtitle1": {
      color: theme.palette.error.light,
    },
    [theme.breakpoints.up("xs")]: {padding: "11px 24px"},
    [theme.breakpoints.down("xs")]: {padding: "18px 24px"},
  },
  header: {
    position: "fixed",
    zIndex: 1000,
    [theme.breakpoints.up("md")]: {
      width: 768,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  thumbup:{
    border: `1.5px dotted ${theme.palette.primary.main}`,
    borderRadius: "50%",
    padding: "5px",
    marginRight: "16px",
    paddingTop: "10px",
    width: "40px",
    height: "40px",
  },
  thumbdown:{
    border: `1.5px dotted ${theme.palette.error.light}`,
    borderRadius: "50%",
    padding: "5px",
    marginRight: "16px",
    paddingTop: "8px",
    width: "40px",
    height: "40px",
  },
  moreActions: {
    [theme.breakpoints.up("xs")]: {
      marginTop: "5%",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "15%",
    },
    padding: "0px",
    "& .MuiMenu-paper": {
      borderRadius: "8px",
      border: `1.5px solid ${theme.palette.border.light}`,
      width: "160px",
    },
    "& .MuiListItem-gutters": {
      paddingLeft: "16px",
    },
    "& .MuiMenuItem-root": {
      "&:last-child": {
        borderTop: `1px solid ${theme.palette.border.light}`,
      },
    },
  },
}));

export const ActivityUpdate = (props) => {
  const habitUpdate = props?.habitUpdate;
  const classes = useStyles();
  const history = useHistory();
  const [updateActivityStatus, { loading }] = useMutation(UpdateActivityStatus);
  const { data, loading: isLoading, error }  = useQuery(getActivity(habitUpdate?.activityId));
  const drawerContext = React.useContext(DrawerContext);
  const auth = React.useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const goBack = () => {
    history.goBack();
  };

  const goHome = () => {
    history.push(Routes.home);
  };

  const handleMore = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateActivity = (isSelfCompleted, isCompleted) => {
   
    updateActivityStatus({
      variables: {
        id: habitUpdate?.activityId,
        isCompleted: isCompleted === "isCompleted" ? true : false,
        isSelfCompleted: isSelfCompleted === "bySelf" ? true : false,
        updatedAt: new Date().toISOString(),
        updatedBy: auth?.user?.profile?.id,
      },
    });
    drawerContext.setDrawer({
      ...drawerContext,
      open: loading
    })
    props?.habit?.home ? history.push(Routes.home) :  history.push(Routes.goalTracker)
  };

  const handleStatus = (isCompleted) => {
    drawerContext.setDrawer({
      ...drawerContext,
      open: true,
      direction: DrawerProps.direction.bottom,
      variant: DrawerProps.variant.temporary,
      component: (<StatusUpdate updateStatus={(v) => updateActivity(v, isCompleted)}/>),
      onClose: () => onDrawerClosed(),
    });
  };

  const onDrawerClosed = () => {};

  const handleNotcompleted = () => {
    updateActivityStatus({
      variables: {
        id: habitUpdate?.activityId,
        isCompleted: false,
        isSelfCompleted: false,
        updatedAt: new Date().toISOString(),
        updatedBy: auth?.user?.profile?.id,
      },
    });
    !loading && props?.habit?.home ? history.push(Routes.home) :  history.push(Routes.goalTracker);
  }

  return (
    <div className={classes.rootContainer}>
      <div className={classes.header}>
        <Header
          showBackIcon
          showHomeIcon
          handleBack={goBack}
          handleHome={goHome}
        />
      </div>
      <div className={classes.root} noValidate>
        {isLoading ? (
            <Grid>
              {[...Array(5)].map((e, i) => {
                return (
                  <Grid item key={i} style={{ margin: "16px 0px" }}>
                    <Skeleton variant="rect" width={"100%"} height={60} />
                  </Grid>
                );
              })}
            </Grid>
          ) : (
        <div>
          <Textwithbutton
            actions={<Button className={classes.moreIcon} onClick={handleMore}><img src="/images/icons8-more.svg" alt="No" /></Button>}
            cardText={<Typography variant="body1" color={"textPrimary"}>{data?.Habits?.data?.habits?.habitName ?? data?.Habits?.data?.habit?.habitName}</Typography>}
            subText={<Typography variant="body2">{`Scheduled till ${moment(habitUpdate?.endDate).format("Do MMM, YYYY")}`}</Typography>}
          />
          <div className={classes.time}>
            <div className={classes.bigClock}>
              <img src="/images/alarmClock.svg" alt="No" />
              <Typography color="primary" className={classes.activityTime}>{moment.utc(data?.Habits?.time).format("hh:mm A")}</Typography>
            </div>
          </div>
          <div style={{ marginTop: "16px" }}>
            <Button variant="outlined" className={classes.completed} onClick={() => handleStatus("isCompleted")}>
              <span className={classes.thumbup}><img src="/images/thumbup.svg" alt="No" /></span>
              <Typography variant="subtitle1" color="primary">I have completed</Typography>
            </Button>
            <Button variant="outlined" className={classes.notCompleted} onClick={() => handleNotcompleted()}>
              <span className={classes.thumbdown}><img src="/images/thumbdown.svg" alt="No" /></span>
              <Typography variant="subtitle1">I have not completed</Typography>
            </Button>
          </div>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            keepMounted
            transformOrigin={{vertical: 'top', horizontal: 'right',}}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            className={classes.moreActions}
          >
            <MenuItem><img src="/images/icons8-edit.svg" alt="edit" /><Typography variant="body1" color="primary" style={{marginLeft: "12px"}}>Edit</Typography></MenuItem>
            <MenuItem><img src="/images/icons8-trash.svg" alt="NO" /><Typography variant="body1" color="primary" style={{marginLeft: "12px"}}>Delete</Typography></MenuItem>
          </Menu>
        </div>)}
        {/* Erro Message */}
        {error && (
          <Typography variant="body2" color="textPrimary">
            Something went wrong, Try Again!
          </Typography>
        )}
      </div>
    </div>
  );
};
