import React from "react";
import {
  makeStyles,
  Chip,
  Grid,
  Card,
  CardContent,
  CardActions,
  IconButton,
} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: (props) => props.backgroundColor,
    boxShadow: "none",
    height: "291px",
    // marginBottom: "8px",
    marginTop: "16px",
    cursor: "pointer",
    borderRadius: "8px",
    "& .MuiCardActions-root":{
      paddingBottom: 0,
      paddingTop: "16px"
    },
    "& .MuiIconButton-root":{
      padding: "3px",
      marginRight: "8px"
    }
  },

  expand: {
    marginLeft: "auto",
  },

  content: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 0,
  },
  chip: {
    backgroundColor: (props) => props.backgroundColor,
    marginLeft: "8px",
    border: `1.5px solid #184457`,
    color: "#0F0B11",
    fontSize: "16px",
    fontFamily: "healthcircles_semi_bold",
    cursor: "pointer",
  },
}));

export const Togglecard = (props) => {
  const classes = useStyles(props);

  return (
    <Card className={classes.root} onClick={props.handleCard}>
      <CardActions disableSpacing>
        <Grid>
          <Chip label={props.label} className={classes.chip} />
        </Grid>

        <IconButton className={clsx(classes.expand)}>
          <img src="/images/Group 4585.svg" alt="arrow" />
        </IconButton>
      </CardActions>

      <CardContent className={classes.content}>
        <img src={props.src} alt="arrow" height="215px"/>
      </CardContent>
    </Card>
  );
};
