import React from "react";
import {
  makeStyles,
  Grid,
  Card,
  CardHeader,
  Button,
  IconButton,
  Typography,
} from "@material-ui/core";
import { SelectBox } from "../select";
import { TabSelect, Textwithcount } from "../../components";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles((theme) => ({
  Card: {
    backgroundColor: "#18445714",
    border: "1px solid #18445729",
    padding: "6px",
    paddingTop: 2,
    paddingBottom: 2,
    marginBottom: 12,
    boxShadow: "none",
    "& .MuiCardHeader-root": {
      padding: "6px",
    },
    "& .MuiTypography-body1 ": {
      fontSize: "14px",
      color: theme.palette.text.primary,
      opacity: 1,
    },
    "& .MuiSvgIcon-root": {
      fontSize: "16px",
    },
  },
  gridItem: {
    padding: 16,
    paddingLeft: 0,
    paddingRight: 0,
  },
  grid: {
    borderTop: "1px dashed #C5C5C5",
    display: "inline-flex",
    justifyContent: "center",
  },
  header: {
    // marginBottom: "16px",
  },
  clearall: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export const MultiSelectDropdown = ({
  //General Props
  label = "",

  //Select Box Props
  selectBoxlabel = "",
  selectBoxIsRequired = false,
  selectBoxIsPaginate = false,
  selectBoxValue = "",
  selectBoxPlaceholder = "",
  selectBoxOptions = [],
  selectBoxLoadOptions = () => null,
  selectBoxOnChange = () => null,
  selectBoxIsLoading = false,

  // Quick Suggestion Props
  quickSugLabel = "",
  quickSugValue = "",
  quickSugOptions = [],
  quickSugIsReadyOnly = false,
  quickSugOnClick = () => false,
  quickSugErrorValidation = {},
  quickSugDisabled = false,
  quickSugMutliple = true,

  // Display Section Props
  displaySecTitle = "",
  displaySecValues = [],
  displaySecOnClearAllClick = () => false,
  displaySecOnDeleteClick = () => false
}) => {

  const classes = useStyles();

  return (
    <div>
      <Typography
        variant="body1"
        color={"textPrimary"}
        className={classes.header}
        gutterBottom
      >
        {label}
      </Typography>

      {/* Search Select DropDown */}
      <SelectBox
        label={selectBoxlabel}
        isRequired={selectBoxIsRequired}
        isPaginate={selectBoxIsPaginate}
        value={selectBoxValue}
        loading={selectBoxIsLoading}
        options={selectBoxOptions}
        onChange={selectBoxOnChange}
        placeholder={selectBoxPlaceholder}
        loadOptions={selectBoxLoadOptions}
      />

      {/* Quick Suggestion Section */}
      {quickSugLabel && (
        <TabSelect
          labelColor
          subLabel={quickSugLabel}
          quicksugLoading={selectBoxIsLoading}
          value={quickSugValue}
          items={quickSugOptions}
          isReadyOnly={quickSugIsReadyOnly}
          onClick={quickSugOnClick}
          errorValidation={quickSugErrorValidation}
          disabled={quickSugDisabled}
          multiple={quickSugMutliple}
        />
      )}

      {/* Data Display Section  */}
      <Grid container direction="row" className={classes.grid}>

        {/* Title */}
        <Grid item xs={8} className={classes.gridItem}>
          <Textwithcount
            text={displaySecTitle}
            count={displaySecValues?.length < 1 ? 0
              : displaySecValues?.length < 10
                ? `0${displaySecValues?.length}`
                : displaySecValues?.length
            }
          />
        </Grid>

        {/* Clear All Button */}
        <Grid item xs={4} className={classes.clearall}>
          {displaySecValues?.length > 0 && (
            <Button
              onClick={displaySecOnClearAllClick}
              variant="text"
              size={"small"}>

              <Typography
                color="error"
                variant="subtitle2">
                CLEAR ALL
              </Typography>

            </Button>
          )}
        </Grid>
      </Grid>

      {/* Display Grid of Items  */}
      {displaySecValues?.map((item, i) => {
        return (
          <Card key={i} className={classes.Card}>
            <CardHeader
              subheader={
                <Typography variant="body1" color={"textPrimary"}>
                  {item.label}
                </Typography>
              }
              action={
                <IconButton
                  onClick={() => displaySecOnDeleteClick(i)}
                  aria-label="settings"
                >
                  <ClearIcon />
                </IconButton>
              }
            />
          </Card>
        );
      })}
    </div>
  );
};
