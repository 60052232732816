import React from "react";
import {
  makeStyles,
  Grid,
  Typography,
  CardContent,
  Card,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    border: (props) => (props?.cardStyle ? "1px dashed #C5C5C5" : "none"),
    borderTopLeftRadius: (props) => (props?.cardStyle ? "15px" : "none"),
    borderTopRightRadius: (props) => (props?.cardStyle ? "15px" : "none"),
    boxShadow: "none",
    "& .MuiCardContent-root": {
      padding: "10px",
      paddingLeft: "16px",
      paddingRight: "16px"
    },
  },
  gridText: {
    display: "inline-flex",
  },
  scheduleIcon: {
    paddingLeft: "14px",
  },
  clockIcon: {
    padding: "3px",
  },
}));

export const CardDateTime = (props) => {
  const classes = useStyles(props);

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item className={classes.gridText}>
            <span style={{ padding: "12px", paddingLeft: "0px" }}>
              <img src="/images/icons8-calendar.svg" alt="No" />
            </span>

            <div>
              <Typography variant="body2" color="textSecondary">
                Date
              </Typography>
              <Typography variant="subtitle2" color="textPrimary">
                {props.date}
              </Typography>
            </div>
          </Grid>
          <Grid item className={classes.gridText}>
            <span style={{ padding: "12px", paddingLeft: "0px" }}>
              <img src="/images/icons8-clock.svg" alt="No" />
            </span>
            <div>
              <Typography variant="body2" color="textSecondary">
                Time
              </Typography>
              <Typography variant="subtitle2" color="textPrimary">
                {props.time}
              </Typography>
            </div>
          </Grid>
          <Grid item>
            <img src="/images/icons8-video-call.svg" alt="no" />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
