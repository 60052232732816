import React from "react";
import { FullScreenContext } from "./contexts";
import { OtpLoader } from './components';
import { withStyles, Grid } from "@material-ui/core";

const styles = (theme) => ({
    root: {
        flexGrow: 1,
        height: "100%",
        position: "absolute",
        top: "0",
        width: "100%",
    },
    contentParent: {
        height: '100%',
        overflow: "auto",
        backgroundColor: theme.palette.background.default
    },
    content: {
        [theme.breakpoints.up("md")]: {
            width: 768
        },
        [theme.breakpoints.down("sm")]: {
            width: '100%'
        },
        height: '100%'
    }
});

class AppFullScreenLoaderDrop extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            message: '',
            setLoader: () => null
        };
    }

    close = () => {
        this.setState({
            open: false,
            message: ""
        });
    };

    set = (props) => {
        this.setState({ ...props });
    };

    render() {

        const { classes } = this.props;

        const {
            open,
            message
        } = this.state;

        return (
            <FullScreenContext.Provider
                value={{
                    ...this.state,
                    setLoader: this.set,
                }}>
                {this.props.children}
                {open && <div className={classes.root}>
                    {/* Content */}
                    <Grid
                        className={classes.contentParent}
                        container
                        direction={"row"}
                        justify={"center"}
                        alignItems={"center"}
                    >
                        <Grid item className={classes.content}>
                            <OtpLoader message={message} />
                        </Grid>
                    </Grid>
                </div>}
            </FullScreenContext.Provider>
        );
    }
}

export default withStyles(styles)(AppFullScreenLoaderDrop);
