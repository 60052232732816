import React from "react";
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  root: {
    width: "100%",
    height: "100%",
    "& input": {
      position: "absolute",
      width: "100%",
      height: "100%",
      opacity: 0,
      left: 0,
      cursor: "pointer",
      zIndex: 1000,
    },
    "& .MuiButton-root": {
      [theme.breakpoints.up("xs")]: {top: "55px"},
      [theme.breakpoints.down("xs")]: {top: "80px"},
    },
    "& .MuiTypography-colorPrimary":{
      [theme.breakpoints.up("xs")]: {marginTop: "65px"},
      [theme.breakpoints.down("xs")]: {marginTop: "88px"},
    }
  },
  drap: {
    position: "relative",
    borderRadius: 6,
    textAlign: "center",
    background: theme.palette.background.paper,
    border: "2px dashed #0079657A",
    [theme.breakpoints.up("xs")]: {height: 230},
    [theme.breakpoints.down("xs")]: {height: 276},
  },
}));

export const Upload = (props) => {
  const classes = useStyles(props);

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.gridContainer}
    >
      <Grid item xs={12}>
        <div className={classes.drap}>
          <div className={classes.root}>
            <input
              type="file"
              multiple
              onChange={(e) => props.handleUpload(e)}
              disabled={props.disabled}
            />
            <Button>
              <img src="/images/Group 4627.svg" alt="No" />
            </Button>
            <Typography variant="subtitle2" color="primary">
              Click to browse and upload
            </Typography>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};
