import React from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import { Input, TabSelect, SelectBox, MobileNumber } from "../../components";
import { genderData, dobAgeData } from "../../utils/constants";
import { Message } from "../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    // paddingBottom: 80,
  },
  gridContainer: {
    height: "100%",
    width: 290,
    justify: "center",
    alignItems: "center",
  },
}));

export const Profile = ({
  profileText = "",
  name = "",
  gender = "",
  dob_toogle = "1",
  dob_age = "",
  mobile = "",
  email = "",
  membership = "",
  organization_name = "",
  releationship = "",

  hideProfileText = false,
  hideRelationshipName = false,
  hideRelationship = false,
  hideName = false,
  hideGender = false,
  hideDobAge = false,
  hideMobileNo = false,
  hideEmail = false,
  hideMemberShip = false,
  hideEnterMobileNo = false,
  relationOptions = [],
  relationLoading = false,
  selectBoxOptions = [],
  selectBoxIsLoading = false,
  error = {},
  changeState = () => false,
  showInHc = false,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root} noValidate>
      <Grid className={classes.gridContainer}>
        {/* Head Text */}
        {!hideProfileText && (
          <Grid item style={{ marginBottom: "16px" }}>
            <Typography variant="body1" color="textPrimary">
              {profileText}
            </Typography>
          </Grid>
        )}
        {/* Name */}
        {!hideName && (
          <Grid item>
            <Input
              label={"Name"}
              isRequired
              type={"text"}
              onChange={(value) => changeState("name", value)}
              error={error.name}
              helperText={error.name === true ? Message.requiredMessage : ""}
              value={name}
            />
          </Grid>
        )}
        {/* Relationship Name */}
        {!hideRelationshipName && (
          <Grid item>
            <SelectBox
              isClearable
              label="Name"
              placeholder="Select"
              isRequired
              options={relationOptions}
              loading={relationLoading}
              createSelect={true}
              value={name}
              onChange={(value) => changeState("name", value)}
              error={error.name}
              helperText={error.name === true ? Message.requiredMessage : ""}
            />
          </Grid>
        )}

        {/* Gender */}
        {!hideGender && (
          <Grid item>
            <TabSelect
              label={"Gender"}
              isrequired
              toggleValue={gender}
              items={genderData}
              onClick={(value) => changeState("gender", value)}
              error={error.gender}
              helperText={error.gender === true ? Message.requiredMessage : ""}
            />
          </Grid>
        )}

        {/* DOB & Age */}
        {!hideDobAge && (
          <Grid item style={{ marginBottom: "16px" }}>
            <TabSelect
              label="Age"
              dobInput
              isrequired
              onClick={(value) => changeState("dob_toogle", value)}
              items={dobAgeData}
              toggleValue={dob_toogle}
              value={dob_age}
              onChange={(value) => changeState("dob_age", value)}
              error={error.dob_age}
              helperText={error.dob_age === true ? Message.requiredMessage : ""}
            />
          </Grid>
        )}
        {!hideRelationship && (
          <Grid item>
            <SelectBox
              label="Relationship"
              placeholder="Select"
              isRequired
              options={selectBoxOptions}
              loading={selectBoxIsLoading}
              onChange={(value) => changeState("releationship", value)}
              value={releationship}
              error={error.releationship}
              helperText={
                error.releationship === true ? Message.requiredMessage : ""
              }
            />
          </Grid>
        )}
        {!hideMobileNo && (
          <Grid item>
            <Input
              label={"Mobile Number"}
              isRequired
              disable={true}
              icon={<img src="/images/icons8-ok.svg" alt="tick" />}
              value={mobile}
            />
          </Grid>
        )}
        {!hideEnterMobileNo && (
          <Grid item style={{ marginBottom: "16px" }}>
            <MobileNumber
              type={"number"}
              label={"Mobile Number"}
              value={mobile}
              // onErrorOccured={(error) => changeState("error", error)}
              isRequired={true}
              handleChange={(value) => changeState("mobile", value)}
              error={error.mobile}
              helperText={error.mobile === true ? Message.requiredMessage : ""}
            />
          </Grid>
        )}
        {!hideEmail && (
          <Grid item>
            <Input
              label={"Email"}
              value={email}
              onChange={(value) => changeState("email", value)}
              error={error.email}
              helperText={error.email === true ? Message.validEmailMessage : ""}
            />
          </Grid>
        )}

        {!hideMemberShip && showInHc &&(
          <Grid item>
            <Input
              label={"Membership Code (For corporate account holders)"}
              showInfoIcon
              value={membership}
              disable={true}
              infoText={organization_name}
              onChange={(value) => changeState("membership", value)}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};
