import React from "react";
import {
  makeStyles,
  Fab,
  MenuItem,
  Typography,
  Backdrop,
  Menu,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import { Routes } from "../../router/routes";
import { HomeEmergencyHelper } from "./emergencyHelper";
// import { TodaysAction } from "./todaysAction";
// import { FollowUp } from "./followUp";
import {
  HealthCircle,
  BannerCard,
  Carouselcomponentcard,
  NotifyComponent,
} from "../../components";
import { carouselList, LocalStorageKeys } from "../../utils";
import { TodaysGoals } from "./todaysGoals";
import { FirebaseContext } from "../../contexts";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  root2: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  textCount: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  fab: {
    textTransform: "capitalize",
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    "& .MuiButton-root": {
      color: theme.palette.background.paper,
    },
  },
  menuContainer: {
    width: "100%",
    background: "transparent",
  },
  menuItem: {
    position: "absolute",
    background: theme.palette.common.white,
    borderRadius: "8px",
    border: `2px solid ${theme.palette.border.light}`,
    bottom: theme.spacing(10),
    right: theme.spacing(2),
    "& .MuiMenuItem-root": {
      "&:last-child": {
        borderTop: `2px solid ${theme.palette.border.light}`,
      },
    },
  },
  profileMenu: {
    [theme.breakpoints.up("xs")]: { marginTop: "4%" },
    [theme.breakpoints.down("xs")]: { marginTop: "15%" },
    padding: "0px",
    "& .MuiMenu-paper": {
      borderRadius: "8px",
      border: `1px solid ${theme.palette.border.light}`,
      width: "176px",
    },
    "& .MuiListItem-gutters": {
      paddingLeft: "24px",
    },
  },
}));

export const Home = (props) => {
  const history = useHistory();
  const classes = useStyles(props);
  const [open, setOpen] = React.useState(false);
  const [profileAnchorEl, setProfileAnchorEl] = React.useState(null);
  const [showInHc] = React.useState(false);
  const firebaseContext = React.useContext(FirebaseContext);

  const viewProfile = () => {
    history.push(Routes.profileDetails);
  };

  const handlePatient = () => {
    history.push(Routes.selectPatient);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const onDrawerClosed = () => {};

  const onLogOut = () => {
    localStorage.removeItem(LocalStorageKeys.authToken);
    firebaseContext.deleteToken();
    history.push(Routes.login);
  };

  const isProfileOpen = Boolean(profileAnchorEl);
  const handleProfileClose = () => {
    setProfileAnchorEl(null);
  };

  const handleProfileOpen = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const notificationFunc = () => {
    let url = "https://support.google.com/chrome/answer/3220216/";
    window.open(url);
  };

  // Profile Menu
  const renderProfile = (
    <Menu
      anchorEl={profileAnchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      keepMounted
      className={classes.profileMenu}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isProfileOpen}
      onClose={handleProfileClose}
    >
      <MenuItem onClick={viewProfile}>
        <Typography variant="subtitle1" color="textPrimary">
          View Profile
        </Typography>
      </MenuItem>
      <MenuItem onClick={notificationFunc}>
        <Typography variant="subtitle1" color="textPrimary">
          Notification
        </Typography>
      </MenuItem>
      <MenuItem onClick={onLogOut}>
        <Typography variant="subtitle1" color="textPrimary">
          Log Out
        </Typography>
      </MenuItem>
      <MenuItem>
        <Typography variant="caption" color="textSecondary">
          {`v${localStorage.getItem(LocalStorageKeys.version)}`}
        </Typography>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.root}>
      <HealthCircle
        showHealthCircleIcon
        showNotificationIcon
        showUserIcon
        renderProfile={renderProfile}
        onClick={handleProfileOpen}
        showInHc={showInHc}
      />
      <div className={classes.root2}>
        {Notification.permission === "denied" && <NotifyComponent />}
      </div>
      {showInHc && (
        <>
          <div className={classes.root2}>
            <BannerCard
              onClick={() => handlePatient()}
              bannerText={"Want to consult a doctor?"}
              backgroundColor={"#FFC7A2"}
              bannerImage={"/images/BannerImage.svg"}
              buttonText={"Book Appointment"}
            />
          </div>
          <HomeEmergencyHelper onDrawerClosed={onDrawerClosed} />
        </>
      )}
      <div className={classes.root2}>
        {/* {showInHc && (
          <>
            <TodaysAction />
            <FollowUp onDrawerClosed={onDrawerClosed} />
          </>
        )} */}
        <TodaysGoals />
        <Carouselcomponentcard
          text="Stay Safe, Stay Healthy!"
          carouseldata={carouselList}
        />
        {showInHc && (
          <Fab
            color="primary"
            className={classes.fab}
            aria-label="add"
            onClick={handleToggle}
          >
            {open ? <CloseIcon /> : <MenuIcon />}
            <Backdrop
              className={classes.backdrop}
              open={open}
              onClick={handleClose}
            >
              <div className={classes.menuItem}>
                <MenuItem onClick={() => history.push(Routes.appointments)}>
                  <Typography variant="body1" color="primary">
                    <img src="/images/icons8-schedule.svg" alt="NO" />
                    &nbsp; Appointments
                  </Typography>
                </MenuItem>
                <MenuItem onClick={() => history.push(Routes.caseSummary)}>
                  <Typography variant="body1" color="primary">
                    <img src="/images/icons8-documents.svg" alt="NO" />
                    &nbsp; Case Summary
                  </Typography>
                </MenuItem>
              </div>
            </Backdrop>
          </Fab>
        )}
      </div>
    </div>
  );
};
