import React from "react";
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

export const FindDoctorLoading = ({
    props = false
}) => {
    return <Grid>
        {[...Array(5)].map((e, i) => {
            return (
                <Grid item key={i} style={{ marginBottom: "16px" }}>
                    <Skeleton variant="rect" width={"100%"} height={203} />
                </Grid>
            );
        })}
    </Grid>
}