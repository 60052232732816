import React from "react";
import { Header, FooterButton, Profile } from "../../components";
import {
  AlertProps,
  LocalStorageKeys,
  NetWorkCallMethods,
  ValidateEmail,
} from "../../utils";
import { Routes } from "../../router/routes";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { AlertContext, FullScreenContext, AuthContext } from "../../contexts";
import moment from "moment";
// import { updateUserToken } from "../../../src/firebase-functions";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  profile: {
    paddingBottom: "95px",
    backgroundColor: theme.palette.background.paper,
    paddingTop: 64,
  },
  header: {
    position: "fixed",
    [theme.breakpoints.up("md")]: {
      width: 768,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  footer: {
    bottom: 0,
    position: "fixed",
    [theme.breakpoints.up("md")]: {
      width: 768,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

export const CompleteProfile = ({
  mobile = "",
  mobile_code = "",
  auth_token = "",
  profile = {},
  user_id = "",
}) => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const alert = React.useContext(AlertContext);
  const fullScreenLoader = React.useContext(FullScreenContext);
  const auth = React.useContext(AuthContext);
  const [showInHc] = React.useState(false);
  const [state, setState] = React.useState({
    name: profile?.name ?? "",
    gender: "",
    dob_toogle: "1",
    dob_age: "",
    mobile: `${mobile_code} ${mobile}`,
    mobile_no: mobile,
    mobile_code: mobile_code,
    email: profile?.email ?? "",
    membership: profile?.membership_detail?.membership?.[0]?.code,
    organization_name:
      profile?.membership_detail?.membership?.[0]?.organization_detail
        ?.organizationName,
    role_id: 3,
    user_id: user_id,
    error: {
      name: false,
      gender: false,
      dob_age: false,
      mobile: false,
      email: false,
      membership: false,
    },
  });
  const [isLoading, setIsLoading ] = React.useState(false);

  const changeState = (key, value) => {
    if (value !== null) {
      state[key] = value;
      state.error[key] = false;
      setState({
        ...state,
      });
    }
  };

  const validation = () => {
    if(state?.dob_age?.dob && state?.dob_age?.dob?.length > 0){
      let years = moment().diff(state?.dob_age?.dob, 'years');
      state["dob_age"] = {
        dob: state?.dob_age?.dob,
        age: years};
      setState({
        ...state, 
      });
    }

    let emailvalid = ValidateEmail(state?.email) ? true : false;
    let namevalid = state?.name?.length > 0 ? true : false;
    let gendervalid = state?.gender?.length > 0 ? true : false;
    let dobvalid =
      state?.dob_age?.dob?.length > 0 || state?.dob_age?.age?.length > 0
        ? true
        : false;
    if (emailvalid && namevalid && gendervalid && dobvalid) {
      return true;
    } else {
      state.error = {
        email: emailvalid ? false : true,
        name: namevalid ? false : true,
        gender: gendervalid ? false : true,
        dob_age: dobvalid ? false : true,
      };
      setState({ ...state });
    }
  };

  const saveForm = async () => {
    const isValidated = validation();
    if (!isValidated) {
      return false;
    } else {
      hitAPI();
    }
  };
  
  // Update Device Token
  const deviceToken = async (user_id) => {
    let user_device_token = localStorage.getItem(LocalStorageKeys.deviceToken);
    const response = await NetworkCall(
      config.rest_api_service + "/users/token/upsert",
      NetWorkCallMethods.post,
      { user_device_token, user_id },
      { Authorization: "Bearer " + auth_token },
      false
    );
    console.log("User Device Token Updated Response : ", response);
  }

  const hitAPI = async () => {
    try {
      setIsLoading(true);
      fullScreenLoader.setLoader({
        ...fullScreenLoader,
        open: true,
        message: "Saving Your Profile...",
      });

      const response = await NetworkCall(
        config.auth_service + "/user/upsert",
        NetWorkCallMethods.post,
        { ...state, welcome: true },
        { Authorization: "Bearer " + auth_token },
        false
      );

      if (response?.data?.statusCode === "200") {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          msg: "Welcome to HealthCircles",
        });

        localStorage.setItem(
          LocalStorageKeys.authToken,
          response?.data?.data?.auth_token
        );
        auth.reload();
        history.push(location?.state?.from?.pathname ?? Routes.home);

        // Update User Device Token 
        deviceToken(response?.data?.data?.user_profile_id);

        // updateUserToken(
        //   response?.data?.data?.user_profile_id,
        //   response?.data?.data?.auth_token
        // );
      } else {
        // eslint-disable-next-line
        throw "Unsuccessfull Profile Updation!!!";
      }
    } catch (err) {
      console.log("Error while creating users:", err);

      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        msg: "Something went wrong, Try Again!",
      });
    }

    setIsLoading(false);
    fullScreenLoader.setLoader({
      ...fullScreenLoader,
      open: false,
      message: "",
    });
  };

  const handleProfile = () => {
    history.push(Routes.profile);
  };

  const goBack = () => {
    history.goBack();
  };

  return (
    <div className={classes.root}>
      <div className={classes.header} style={{zIndex: isLoading ? "" : 1000}}>
        <Header
          profileText={"Complete your profile"}
          showPersonIcon
          onClick={handleProfile}
        />
      </div>
      <div className={classes.profile}>
        <Profile
          {...state}
          changeState={changeState}
          hideProfileText
          hideRelationshipName
          hideRelationship
          hideEnterMobileNo
          hideMemberShip={state.membership ? false : true}
          showInHc={showInHc}
        />
      </div>

      <div className={classes.footer}>
        <FooterButton
          firstButton="Cancel"
          secondButton="Save"
          handleClick={goBack}
          onClick={saveForm}
        />
      </div>
    </div>
  );
};
