import React from "react";
import { makeStyles, Grid, Slide } from "@material-ui/core";
import { MultiSelectDropdown, Header, FooterButton } from "../../components";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
import { AppointmentContext } from "../../contexts";
import { useQuery } from "@apollo/client";
import { getAllMasterAllergies } from "../../graphql/queries";
import { getUniqueArrayByValue } from "../../utils";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    height: "100%",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
    margin: 0,
  },
  root: {
    padding: theme.spacing(3),
    paddingBottom: "95px",
    paddingTop: 80,
  },
  header: {
    position: "fixed",
    zIndex: 1000,
    [theme.breakpoints.up("md")]: {
      width: 768,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  footer: {
    bottom: 0,
    position: "fixed",
    [theme.breakpoints.up("md")]: {
      width: 768,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

export const Allergies = (props) => {
  const appointmentContext = React.useContext(AppointmentContext);
  const classes = useStyles(props);
  const history = useHistory();

  const { data, loading, fetchMore } = useQuery(getAllMasterAllergies(), {
    variables: {
      limit: 10,
      offset: 0,
      search: "",
    },
  });

  const [state, setState] = React.useState({
    allergies: appointmentContext.allergies,
  });

  const goBack = () => {
    history.goBack();
  };
  const goHome = () => {
    history.push(Routes.home);
  };
  const goNext = () => {
    history.push(Routes.medications);
  };

  const loadallergies = async (search, loadedOptions) => {
    let offset = loadedOptions?.length ?? 0;

    const res = await fetchMore({
      variables: {
        limit: 10,
        offset,
        search: search,
      },
    });

    return {
      options: res?.data?.allergies?.data,
      hasMore: res?.data?.allergies?.pageInfo?.hasNextPage,
    };
  };

  const handleAdd = (newData) => {
    //Taking the old allergies
    let allergies = state.allergies;

    //If it a object then it is coming from select box
    if (newData.value && newData) {
      allergies.push(newData);
    }
    // Else it is coming from quick suggestions
    else {
      // Taking the Quick suggestion data
      let qucikSugData = data?.allergies?.data?.slice(0, 10);

      // Removing Quick Suggestion from allergies
      allergies = allergies.filter((_) => _.value > 10);

      // Adding the new allergies
      newData = qucikSugData.filter((_) => newData.indexOf(_.value) > -1);
      allergies.push(...newData);
    }
    allergies = getUniqueArrayByValue(allergies, "value");
    setState({ ...state, allergies });
  };

  const handleDelete = (i) => {
    state.allergies.splice(i, 1);
    setState({ ...state });
  };

  const handleClear = () => {
    setState({
      ...state,
      allergies: [],
    });
  };
  React.useEffect(() => {
    appointmentContext.allergies = state.allergies;
    // eslint-disable-next-line
  }, [state]);
  return (
    <div className={classes.rootContainer}>
      <div className={classes.header}>
        <Header
          showBackIcon
          showHomeIcon
          handleBack={goBack}
          handleHome={goHome}
        />
      </div>
      <Slide direction="left" in={true} mountOnEnter unmountOnExit>
        <div className={classes.root} noValidate>
          <Grid>
            <Grid item>
              <MultiSelectDropdown
                label="What allergies do you have?"
                selectBoxPlaceholder="Eg. Skin Allergy"
                selectBoxIsPaginate={true}
                selectBoxOnChange={handleAdd}
                selectBoxLoadOptions={loadallergies}
                selectBoxIsLoading={loading}
                selectBoxOptions={data?.allergies?.data}
                quickSugLabel="Quick Suggestions"
                quickSugValue={state?.allergies?.map((_) => _.value)}
                quickSugOptions={data?.allergies?.data?.slice(0, 10) ?? []}
                quickSugOnClick={handleAdd}
                displaySecTitle="Symptoms Added"
                displaySecValues={state?.allergies}
                displaySecOnDeleteClick={handleDelete}
                displaySecOnClearAllClick={handleClear}
              />
            </Grid>
          </Grid>
        </div>
      </Slide>
      <div className={classes.footer}>
        <FooterButton
          firstButton="Skip"
          handleClick={goNext}
          btnDisable={state?.allergies?.length > 0 ? false : true}
          secondButton="Continue"
          onClick={goNext}
        />
      </div>
    </div>
  );
};
