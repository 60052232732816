import React from "react";
import { makeStyles, Grid, Avatar, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  small: {
    backgroundColor: theme.palette.text.primary,
    width: "24px",
    height: "24px",
    fontSize: "12px",
    marginLeft: "8px",
  },
  appointmentdiv: {
    display: "inline-flex",
  },
}));
export const Textwithcount = (props) => {
  const classes = useStyles();

  return (
    <div>
      <Grid className={classes.appointmentdiv}>
        <Typography variant="body1" color={"textPrimary"}>
          {props.text}
        </Typography>
        <Avatar alt="Remy Sharp" className={classes.small}>
          {props.count}
        </Avatar>
      </Grid>
    </div>
  );
};
