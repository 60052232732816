import React from "react";
import { withNavBars } from "../../HOCs";
import { withRouter, Redirect } from "react-router-dom";
import { PatientDetails } from "./patientDetails";
import { AppointmentContext } from "../../contexts";
import { initBeforeUnLoad } from "../../utils";

class PatientDetailsParent extends React.Component {
  constructor() {
    super();
    this.state = { readyToRedirect: false };
  }
  static contextType = AppointmentContext;

  componentDidMount() {
    // check for booking status
    const appointmentContext = this.context;
    if (appointmentContext.is_self_booking === null) {
      this.setState({
        readyToRedirect: true,
      });
    }
    initBeforeUnLoad();
  }
  render() {
    if (this.state.readyToRedirect) return <Redirect to="/select_patient" />;
    return <PatientDetails />;
  }
}

export default withRouter(withNavBars(PatientDetailsParent));
