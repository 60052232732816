import React from "react";
import { makeStyles, Grid, Drawer, Divider } from "@material-ui/core";
import {
  AppointmentBookingCard,
  Header,
  Textwithcount,
  TimeSlotToggle,
  SearchFilter,
} from "../../components";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
import { DrawerContext, AppointmentContext } from "../../contexts";
import { DrawerProps } from "../../utils";
import moment from "moment";
import { DoctorFilter } from "./filter";
import { FindDoctorLoading } from "./findDoctorLoading";
import { FindDoctorNotFound } from "./findDoctorNotFound";

const useStyles = makeStyles((theme) => ({
  parentRoot: {
    height: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  root: {
    height: "100%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    paddingTop: 80,
  },
  header: {
    position: "fixed",
    zIndex: 1000,
    [theme.breakpoints.up("md")]: {
      width: 768,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  searchFilter: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  drawer: {
    borderRadius: "24px 24px 0px 0px",
  },
  grid: {
    marginBottom: theme.spacing(2),
  },
}));

export const FindDoctor = (props) => {
  var doctorList = props.doctorList;
  const classes = useStyles();
  const history = useHistory();
  const drawerContext = React.useContext(DrawerContext);
  const appointmentContext = React.useContext(AppointmentContext);
  const [state, setState] = React.useState(
    appointmentContext.appointed_doctor_details
  );

  const [filterDrawerOpen, setFilterDrawerOpen] = React.useState(false);
  const [isFilterApplied, setIsFilterApplied] = React.useState(false);

  const changeState = (key, value) => {
    state[key] = value;
    appointmentContext.appointed_doctor_details[key] = value;
    setState({
      ...state,
    });
  };

  const goBack = () => {
    history.goBack();
  };

  const goHome = () => {
    history.push(Routes.home);
  };

  const goNext = () => {
    drawerContext.setDrawer({
      ...drawerContext,
      open: false,
    });
    history.push(Routes.uploadReports);
  };

  const giveMeSelectedDate = () => {
    let selectedDate = appointmentContext.appointed_doctor_details
      .appointment_date
      ? appointmentContext.appointed_doctor_details.appointment_date
      : props.dateFilter === "custom"
      ? props.customDate
      : props.dateFilter === "tomorrow" || props.dateFilter === "next_7_days"
      ? moment().add(1, "day").format("YYYY-MM-DD").toString()
      : moment().format("YYYY-MM-DD").toString();
    return selectedDate;
  };

  const viewSectionDrawer = (doctor) => {
    appointmentContext.appointed_doctor_details["doctor_name"] = doctor.name;
    appointmentContext.appointed_doctor_details["doctor_fee"] =
      doctor.consulationCharge;
    appointmentContext.appointed_doctor_details["doctor_profile_pic"] =
      doctor.profilePic;
    appointmentContext.appointed_doctor_details.id = doctor.id;

    drawerContext.setDrawer({
      ...drawerContext,
      open: true,
      direction: DrawerProps.direction.bottom,
      variant: DrawerProps.variant.temporary,
      drawerClassName: true, 
      component: (
        <div>
          <TimeSlotToggle
            title={doctor.name}
            profile_pic={doctor.profilePic}
            price={doctor.consulationCharge}
            firstSubText={doctor?.education ?? "-"}
            firstIcon={"/images/icons8-certificate.svg"}
            secondSubText={doctor?.hospital ?? "-"}
            secondIcon={"/images/icons8-hospital.svg"}
            disabled={false}
            toggleButtonData={doctor.slots}
            dateFilter={props.dateFilter}
            goNext={goNext}
            onSelect={changeState}
            showTimeIcon
            doctor={doctor}
            followUp={props.dateFilter === "next_7_days"}
            selectedDate={giveMeSelectedDate()}
            slotValue={moment(
              appointmentContext.appointed_doctor_details.appointment_time_slot,
              "HH:mm:ss"
            )
              .format("hh:mm A")
              .toString()}
          />
        </div>
      ),
      onClose: () => onDrawerClosed(),
    });
  };

  const onDrawerClosed = () => {
    drawerContext.setDrawer({
      ...drawerContext,
      open: false,
      direction: DrawerProps.direction.bottom,
      drawerClassName: false, 
    });
  };

  return (
    <div className={classes.parentRoot}>
      <div className={classes.header}>
        <Header
          showBackIcon
          showHomeIcon
          handleBack={goBack}
          handleHome={goHome}
        />
      </div>
      <div className={classes.root} noValidate>
        <Grid>
          <Grid item>
            <Textwithcount
              text="Doctors Available"
              count={
                doctorList?.length < 1
                  ? 0
                  : doctorList?.length < 10
                  ? `0${doctorList?.length}`
                  : doctorList?.length
              }
            />
          </Grid>
          <Grid item className={classes.searchFilter}>
            <SearchFilter
              showFilter
              handleChange={props.handleChange}
              value={props.search}
              onFilterButtonClicked={() => setFilterDrawerOpen(true)}
              isFilterApplied={isFilterApplied}
            />
          </Grid>

          {appointmentContext.selectedDoctor && (
            <Grid item key={appointmentContext.selectedDoctor.id}>
              <AppointmentBookingCard
                title={appointmentContext.selectedDoctor.name}
                profile_pic={appointmentContext.selectedDoctor.profilePic}
                price={appointmentContext.selectedDoctor.consulationCharge}
                firstSubText={
                  appointmentContext.selectedDoctor?.education ?? "-"
                }
                firstIcon={"/images/icons8-certificate.svg"}
                secondSubText={
                  appointmentContext.selectedDoctor?.hospital ?? "-"
                }
                secondIcon={"/images/icons8-hospital.svg"}
                bookSlot={() =>
                  viewSectionDrawer(appointmentContext.selectedDoctor)
                }
                slots={appointmentContext.selectedDoctor.slots}
                bookedOn={`Booked for ${moment(
                  appointmentContext.appointed_doctor_details
                    .appointment_client_start_time
                ).format("lll")}`}
                goNext={goNext}
              />
              <Divider
                variant="fullWidth"
                style={{ marginTop: 16, marginBottom: 16 }}
              />
            </Grid>
          )}

          {props.isLoading ? (
            <FindDoctorLoading />
          ) : (
            doctorList?.map((doctor) => {
              return (
                <Grid item key={doctor.id} className={classes.grid}>
                  <AppointmentBookingCard
                    title={doctor.name}
                    profile_pic={doctor.profilePic}
                    price={doctor.consulationCharge}
                    firstSubText={doctor?.education ?? "-"}
                    firstIcon={"/images/icons8-certificate.svg"}
                    secondSubText={doctor?.hospital ?? "-"}
                    secondIcon={"/images/icons8-hospital.svg"}
                    bookSlot={() => viewSectionDrawer(doctor)}
                    slots={doctor.slots}
                  />
                </Grid>
              );
            })
          )}

          {!props.isLoading && doctorList?.length === 0 && (
            <FindDoctorNotFound />
          )}
        </Grid>

        <Drawer
          anchor={"bottom"}
          open={filterDrawerOpen}
          classes={{ paper: classes.drawer }}
          onClose={() => setFilterDrawerOpen(false)}
        >
          <DoctorFilter
            filterArray={props.availabilityFilter}
            selectedFilter={props.dateFilter}
            onFilterChange={(newFormat, customDate) => {

              if(newFormat !== "today"){
                setIsFilterApplied(true)
              }else{
                setIsFilterApplied(false)
              }
              if (newFormat !== "custom") {
                setFilterDrawerOpen(false);
              } else if (customDate) {
                setFilterDrawerOpen(false);
              }
              props.setDateFilter(newFormat, customDate);
            }}
            customDate={props.customDate}
          />
        </Drawer>
      </div>
    </div>
  );
};
