import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

import Card from "@material-ui/core/Card";

import CardContent from "@material-ui/core/CardContent";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 140,
    border: `2px solid ${theme.palette.border.light}`,
    backgroundColor: theme.palette.common.white,
    boxShadow: "0px 10px 15px #00000014",
    margin: 10,
    borderRadius: "8px",
  },

  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },

  content: {
    display: "flex",
    justifyContent: "center",

    "&:last-child": {
      paddingBottom: 0,
    },
  },
  content1: {
    display: "flex",
    justifyContent: "center",

    "&:last-child": {
      paddingTop: 0,
      paddingBottom: 10,
    },
    "& .MuiTypography-h6": {
      fontSize: "14px",
    },
  },
}));

export const Carouselcard = ({
  src="",
  cardText=""
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <img src={src} alt="contact" />
      </CardContent>
      <CardContent className={classes.content1}>
        <Typography variant="h6" className={classes.BannerText}>
          {cardText}
        </Typography>
      </CardContent>
    </Card>
  );
};
