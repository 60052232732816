import gql from "graphql-tag";

export const Updateprofile = gql`
  mutation (
    $id: UUID!
    $firstName: String
    $gender: EnumUserProfilesGender
    $dob: Date
    $age: Int
    $emailId: String
    $membershipCode: String
    $updatedAt: Datetime
    $updatedBy: UUID
  ) {
    updateUserProfileById(
      input: {
        userProfilePatch: {
          firstName: $firstName
          gender: $gender
          dob: $dob
          age: $age
          emailId: $emailId
          membershipCode: $membershipCode
          updatedAt: $updatedAt
          updatedBy: $updatedBy
          isActive: true
        }
        id: $id
      }
    ) {
      clientMutationId
    }
  }
`;

export const UpdateAppointment = gql`
  mutation (
    $id: UUID!
    $appointmentStatus: Int
    $cancellationReason: String
    $updatedAt: Datetime
    $updatedBy: UUID
  ) {
    updateAppointmentById(
      input: {
        appointmentPatch: {
          appointmentStatus: $appointmentStatus
          cancellationReason: $cancellationReason
          updatedAt: $updatedAt
          updatedBy: $updatedBy
          isActive: true
        }
        id: $id
      }
    ) {
      clientMutationId
    }
  }
`;

export const UpdateActivityStatus = gql`
  mutation (
    $id: UUID!
    $isCompleted: Boolean
    $isSelfCompleted: Boolean
    $updatedAt: Datetime
    $updatedBy: UUID
  ) {
    updateUserHabitActivityById(
      input: {
        id: $id
        userHabitActivityPatch: {
          isCompleted: $isCompleted
          isSelfCompleted: $isSelfCompleted
          updatedAt: $updatedAt
          updatedBy: $updatedBy
        }
      }
    ) {
      clientMutationId
    }
  }
`;
