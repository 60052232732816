import React from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import {
  Header,
  Cardheader,
  FooterButton,
  CardDateTime,
  Cardsection,
} from "../../components";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
import {
  AppointmentContext,
  AuthContext,
  AlertContext,
  FullScreenContext,
} from "../../contexts";
import { Download, NetWorkCallMethods, AlertProps } from "../../utils";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    paddingTop: 80,
    paddingBottom: "95px",
  },
  header: {
    position: "fixed",
    zIndex: 1000,
    [theme.breakpoints.up("md")]: {
      width: 768,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  footer: {
    bottom: 0,
    position: "fixed",
    [theme.breakpoints.up("md")]: {
      width: 768,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

export const AppoinmentSummary = (props) => {
  const appointmentContext = React.useContext(AppointmentContext);
  const auth = React.useContext(AuthContext);
  const fullScreenLoader = React.useContext(FullScreenContext);
  const alert = React.useContext(AlertContext);

  const classes = useStyles(props);
  const history = useHistory();
  const [state, setState] = React.useState({ loading: false });

  let myAge = auth?.user?.profile?.age
    ? `, ${auth?.user?.profile?.age}Yrs`
    : "";
  let othersAge = appointmentContext?.booking_for?.dob_age?.age
    ? `, ${appointmentContext?.booking_for?.dob_age?.age}Yrs`
    : "";

  const goBack = () => {
    history.goBack();
  };

  const goHome = () => {
    history.push(Routes.home);
  };

  const goNext = () => {
    history.goBack();
  };

  const handleClick = async () => {
    try {
      // Loading the State updated and loader screen visible
      setState({ ...state, loading: true });
      fullScreenLoader.setLoader({
        ...fullScreenLoader,
        open: true,
        message: "Booking Your Appointment",
      });

      delete appointmentContext.data;
      delete appointmentContext.dateFilter;
      delete appointmentContext.customDate;
      delete appointmentContext.selectedDoctor;

      // Book appointment API call
      const res = await NetworkCall(
        config.rest_api_service + "/appointment/book",
        NetWorkCallMethods.post,
        appointmentContext,
        null,
        true
      );

      // Stoping the Loaders
      fullScreenLoader.setLoader({
        ...fullScreenLoader,
        open: false,
        message: "",
      });
      setState({ ...state, loading: false });

      // Navigating to Confirmation page
      if (parseInt(res.data.statusCode) === 200) {
        history.push(Routes.appointmentConfirmation);
      } else {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          msg: res.data?.message ?? "Something went wrong, Try Again!",
        });
      }
    } catch (error) {
      console.log("Error while booking appointment: ", error);
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        msg: "Something went wrong, Try Again!",
      });
    }
  };

  const handleEdit = (v) => {
    if (v === "Patient details") {
      history.push(Routes.patientDetails);
    } else if (v === "Health Issue") {
      history.push(Routes.addHealthIssues);
    } else if (v === "Symptoms") {
      history.push(Routes.symptoms);
    } else if (v === "Problems") {
      history.push(Routes.healthProblems);
    } else if (v === "Allergies") {
      history.push(Routes.allergies);
    } else if (v === "Active Medications") {
      history.push(Routes.medications);
    } else if (v === "Reports") {
      history.push(Routes.uploadReports);
    } else if (v === "Vitals") {
      history.push(Routes.vitals);
    }
  };

  return (
    <div>
      <div className={classes.header} style={{display: state?.loading ? "none" : ""}}>
        <Header
          showBackIcon
          showHomeIcon
          handleBack={goBack}
          handleHome={goHome}
        />
      </div>
      <div className={classes.root} noValidate>
        <Typography
          variant="body1"
          color={"textPrimary"}
          style={{ marginBottom: "16px" }}
        >
          Appointment Summary
        </Typography>
        <Grid style={{boxShadow: "0px 10px 15px #00000014"}}>
          <Grid item>
            <Cardheader
              profile_pic={
                appointmentContext?.appointed_doctor_details?.doctor_profile_pic
              }
              title={`Dr. ${appointmentContext?.appointed_doctor_details?.doctor_name}`}
              price={appointmentContext?.appointed_doctor_details?.doctor_fee}
              cardStyle
            />
            <CardDateTime
              date={moment(
                appointmentContext?.appointed_doctor_details
                  ?.appointment_client_start_time
              ).format("DD MMM, YYYY")}
              time={moment(
                appointmentContext?.appointed_doctor_details
                  ?.appointment_client_start_time
              ).format("hh:mm A")}
              cardStyle
            />
          </Grid>
          <Grid item>
            <Cardsection
              Header="Patient details"
              headericon="/images/icons8-user (1).svg"
              hideEdit={appointmentContext.is_self_booking ? "hideEdit" : ""}
              primarytext={
                appointmentContext.is_self_booking
                  ? auth?.user?.profile?.name
                  : appointmentContext?.booking_for?.name?.label
              }
              secondartext={
                appointmentContext.is_self_booking
                  ? `${auth?.user?.profile?.mobile_code} ${auth?.user?.profile?.mobile}`
                  : `${appointmentContext?.booking_for?.mobile?.mobile_code} ${appointmentContext?.booking_for?.mobile?.mobile}`
              }
              label={
                appointmentContext.is_self_booking
                  ? auth?.user?.profile?.gender + myAge
                  : appointmentContext?.booking_for?.gender + othersAge
              }
              onClick={handleEdit}
            />
          </Grid>
          <Grid item>
            <Cardsection
              Header="Health Issue"
              headericon="/images/icons8-heart-with-pulse (1).svg"
              healthissue={appointmentContext?.health_issues}
              onClick={handleEdit}
            />
          </Grid>
          <Grid item>
            <Cardsection
              Header="Symptoms"
              headericon="/images/icons8-coughing (1).svg"
              symptoms={appointmentContext?.symptoms}
              onClick={handleEdit}
            />
          </Grid>
          <Grid item>
            <Cardsection
              Header="Problems"
              headericon="/images/icons8-back-pain.svg"
              symptoms={appointmentContext?.health_problems}
              onClick={handleEdit}
            />
          </Grid>
          <Grid item>
            <Cardsection
              Header="Allergies"
              headericon="/images/Allergies.svg"
              symptoms={appointmentContext?.allergies}
              onClick={handleEdit}
            />
          </Grid>
          <Grid item>
            <Cardsection
              Header="Active Medications"
              headericon="/images/icons8-pill.svg"
              symptoms={appointmentContext?.drugs}
              onClick={handleEdit}
            />
          </Grid>
          <Grid item>
            <Cardsection
              Header="Reports"
              headericon="/images/icons8-document (1).svg"
              onClick={handleEdit}
              documents={appointmentContext?.reports}
              handleDownload={Download}
            />
          </Grid>
          <Grid item>
            <Cardsection
              Header="Vitals"
              headericon="/images/icons8-heart-monitor.svg"
              vitals={appointmentContext?.vitals}
              onClick={handleEdit}
              hidevitals
            />
          </Grid>
        </Grid>
      </div>
      <div className={classes.footer}>
        <FooterButton
          firstButton="Cancel"
          handleClick={goNext}
          secondButton="Confrim"
          btnDisable={state.loading}
          onClick={handleClick}
        />
      </div>
    </div>
  );
};
