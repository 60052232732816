import React from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import {
  Header,
  Cardheader,
  CardDateTime,
  Cardsection,
} from "../../components";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
import moment from "moment";
import { getAppointmentSummaryView } from "../../graphql/queries";
import { useQuery } from "@apollo/client";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    paddingTop: 80,
  },
  header: {
    position: "fixed",
    zIndex: 1000,
    [theme.breakpoints.up("md")]: {
      width: 768,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

export const AppoinmentSummaryView = (props) => {
  let appointmentId = props?.appointmentId?.id;
  const { loading, error, data } = useQuery(
    getAppointmentSummaryView(appointmentId)
  );
  let appointmentDetail = data?.allAppointments?.appointment[0];
  const classes = useStyles(props);
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  const goHome = () => {
    history.push(Routes.home);
  };

  return (
    <div>
      <div className={classes.header}>
        <Header
          showBackIcon
          showHomeIcon
          handleBack={goBack}
          handleHome={goHome}
        />
      </div>
      <div className={classes.root} noValidate>
        <Typography
          variant="body1"
          color={"textPrimary"}
          style={{ marginBottom: "16px" }}
        >
          Appointment Summary
        </Typography>
        {loading ? (
          <Grid>
            {[...Array(5)].map((e, i) => {
              return (
                <Grid item key={i} style={{ margin: "16px", marginLeft: 0 }}>
                  <Skeleton variant="rect" width={"100%"} height={50} />
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <Grid>
            <Grid item>
              <Cardheader
                profile_pic={
                  appointmentDetail?.userProfileByAssignedDoctor?.profilePic
                }
                title={`Dr. ${appointmentDetail?.userProfileByAssignedDoctor?.firstName}`}
                price={
                  appointmentDetail?.userProfileByAssignedDoctor
                    ?.consulationCharge
                }
                cardStyle
              />
              <CardDateTime
                date={moment(appointmentDetail?.appointmentDateTime).format(
                  "DD MMM, YYYY"
                )}
                time={moment(appointmentDetail?.appointmentDateTime).format(
                  "hh:mm A"
                )}
                cardStyle
              />
            </Grid>
            <Grid item>
              <Cardsection
                Header="Patient details"
                headericon="/images/icons8-user (1).svg"
                hideEdit
                primarytext={
                  appointmentDetail?.userProfileByBookingFor?.firstName
                }
                secondartext={
                  appointmentDetail?.userProfileByBookingFor?.mobile
                }
                label={`${appointmentDetail?.userProfileByBookingFor?.gender}${
                  appointmentDetail?.userProfileByBookingFor?.age
                    ? `, ${appointmentDetail?.userProfileByBookingFor?.age}Yrs`
                    : ""
                }`}
              />
            </Grid>
            <Grid item>
              <Cardsection
                Header="Health Issue"
                headericon="/images/icons8-heart-with-pulse (1).svg"
                hideEdit
                healthissue={appointmentDetail?.healthIssues}
              />
            </Grid>
            <Grid item>
              <Cardsection
                Header="Symptoms"
                headericon="/images/icons8-coughing (1).svg"
                hideEdit
                symptoms={
                  appointmentDetail?.appointmentSymptomsByAppointmentId
                    ?.symptoms
                }
              />
            </Grid>
            <Grid item>
              <Cardsection
                Header="Problems"
                headericon="/images/icons8-back-pain.svg"
                hideEdit
                symptoms={
                  appointmentDetail?.appointmentHealthProblemsByAppointmentId
                    ?.HealthProblems
                }
              />
            </Grid>
            <Grid item>
              <Cardsection
                Header="Allergies"
                headericon="/images/Allergies.svg"
                hideEdit
                symptoms={
                  appointmentDetail?.appointmentAllergiesByAppointmentId
                    ?.Allergies
                }
              />
            </Grid>
            <Grid item>
              <Cardsection
                Header="Active Medications"
                headericon="/images/icons8-pill.svg"
                hideEdit
                symptoms={
                  appointmentDetail?.userProfileByBookingFor
                    ?.patientActiveMedicationsByPatientId?.Medications
                }
              />
            </Grid>
            <Grid item>
              <Cardsection
                Header="Reports"
                headericon="/images/icons8-document (1).svg"
                hideEdit
                documents={
                  appointmentDetail?.appointmentUploadsByAppointmentId
                    ?.UploadDetails
                }
                // handleDownload={Download}
              />
            </Grid>
            <Grid item>
              <Cardsection
                Header="Vitals"
                headericon="/images/icons8-heart-monitor.svg"
                hideEdit
                vitals={{
                  height_in_cm:
                    appointmentDetail?.patientVitalByVitals?.heightInCm,
                  weight_in_cm:
                    appointmentDetail?.patientVitalByVitals?.weightInCm,
                  temp_in_f: appointmentDetail?.patientVitalByVitals?.tempInF,
                  puls_bpm: appointmentDetail?.patientVitalByVitals?.pulsBpm,
                  bp_sys: appointmentDetail?.patientVitalByVitals?.bpSys,
                  bp_dia: appointmentDetail?.patientVitalByVitals?.bpDia,
                  o2_level: appointmentDetail?.patientVitalByVitals?.o2Level,
                }}
                hidevitals
              />
            </Grid>
          </Grid>
        )}
        {error && (
          <Typography variant="body2" color="textPrimary">
            Something went wrong, Try Again!
          </Typography>
        )}
      </div>
    </div>
  );
};
