import React from "react";
import { makeStyles, Grid } from "@material-ui/core";
import { CustomTextadder, Header, FooterButton } from "../../components";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
import { AppointmentContext } from "../../contexts";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    height: "100%",
    overflow: "auto",
    margin: 0,
    backgroundColor: theme.palette.background.paper,
  },
  root: {
    padding: theme.spacing(3),
    paddingBottom: "95px",
    paddingTop: 80,
  },
  header: {
    position: "fixed",
    zIndex: 1000,
    [theme.breakpoints.up("md")]: {
      width: 768,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  footer: {
    bottom: 0,
    position: "fixed",
    [theme.breakpoints.up("md")]: {
      width: 768,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

export const AddHealthIssues = (props) => {
  const appointmentContext = React.useContext(AppointmentContext);
  const [text, setText] = React.useState("");
  const [problem, setProblem] = React.useState(
    appointmentContext.health_issues
  );
  const classes = useStyles(props);
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  const goHome = () => {
    history.push(Routes.home);
  };

  const goNext = () => {
    history.push(Routes.symptoms);
  };

  const handleChange = (e) => {
    setText(e.target.value);
  };

  const enterKeyPress = (event) => {
    if (event.which === 13 || event.keyCode === 13) {
      // for crossbrowser
      event.preventDefault(); // this code prevents other buttons trigger
      handleAdd();
    }
  };

  const handleAdd = () => {
    if (text) {
      setProblem((oldArray) => [...oldArray, text]);
      setText("");
    }
  };

  const handleDelete = (i) => {
    problem.splice(i, 1);
    setProblem([...problem]);
  };

  const handleClear = () => {
    setProblem([]);
  };

  React.useEffect(() => {
    appointmentContext.health_issues = problem;
    // eslint-disable-next-line
  }, [problem]);

  return (
    <>
      <div className={classes.rootContainer}>
        <div className={classes.header}>
          <Header
            showBackIcon
            showHomeIcon
            handleBack={goBack}
            handleHome={goHome}
          />
        </div>
        <div className={classes.root} noValidate>
          <Grid>
            <Grid item>
              <CustomTextadder
                text="Health Issues Added"
                value={text}
                add={handleAdd}
                problem={problem}
                delete={handleDelete}
                clear={handleClear}
                onChange={handleChange}
                enterKeyPress={enterKeyPress}
              />
            </Grid>
          </Grid>
        </div>
        <div className={classes.footer}>
          <FooterButton
            firstButton="Skip"
            handleClick={goNext}
            btnDisable={problem?.length > 0 ? false : true}
            secondButton="Continue"
            onClick={goNext}
          />
        </div>
      </div>
    </>
  );
};
