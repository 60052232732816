import React from "react";
import {
  makeStyles,
  Card,
  CardContent,
  Grid,
  Button,
  Typography,
} from "@material-ui/core";
import { Cardheader } from "../cardheader";
import { CardDateTime } from "../cardDateTime";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 24,
    marginBottom: 8,
    cursor: "pointer",
  },
  gridContainer: {
    borderTop: `1px solid ${theme.palette.border.light}`,
  },
  statusContainer: {
    borderTop: `1px dashed ${theme.palette.border.light}`,
    padding: 10,
  },
  gridBtn2: {
    borderLeft: `1px solid ${theme.palette.border.light}`,
    textAlign: "center",
  },
  content: {
    "&:last-child": {
      padding: 0,
    },
  },
  contentBtn: {
    padding: 0,
    "&:last-child": {
      padding: 0,
    },
  },
  subText: {
    fontSize: "0.7rem",
    textTransform: "lowercase",
  },
  appointmentStatus: {
    background: (props) =>
      props.appointmentStatus === 3 ? "#4CAF5014" : "#F4511E14",
    padding: "5px",
    borderRadius: "8px",
  },
}));

export const AppointmentListCard = (props) => {
  const classes = useStyles(props);

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <CardContent onClick={() => props.appointmetView()}>
          <Cardheader
            title={props.title}
            firstSubText={props.firstSubText}
            price={props.price}
            profile_pic={props.profile_pic}
          />
          <CardDateTime date={props.date} time={props.time} />
        </CardContent>
        <CardContent className={classes.contentBtn}>
          {props.statusText ? (
            <Grid
              container
              direction="row"
              alignItems="center"
              className={classes.statusContainer}
            >
              <Grid item xs={12} style={{ textAlign: "center" }}>
                {props.statusText === "Completed" ? (
                  <Typography
                    variant="body2"
                    color="primary"
                    className={classes.appointmentStatus}
                  >
                    Status : {props.statusText}
                  </Typography>
                ) : (
                  <Typography
                    variant="body2"
                    color="Secondary"
                    className={classes.appointmentStatus}
                  >
                    Status : {props.statusText}
                  </Typography>
                )}
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              direction="row"
              alignItems="center"
              className={classes.gridContainer}
            >
              {props.firstBtn && (
                <Grid item xs={6} style={{ textAlign: "center" }}>
                  <Button
                    variant="text"
                    color="primary"
                    size="small"
                    onClick={() => props.handleCancel()}
                  >
                    {props.firstBtn}
                  </Button>
                </Grid>
              )}
              {props.secondBtn && (
                <Grid item xs={6} className={classes.gridBtn2}>
                  <Button
                    variant="text"
                    color="primary"
                    size="small"
                    disabled={props.subText > 0 ? true : false}
                    onClick={() => props.handleClick()}
                  >
                    <Grid>
                      <Grid item>{props.secondBtn}</Grid>
                      {props.subText > 0 && (
                        <Grid item className={classes.subText}>
                          in&nbsp;
                          {props.subText > 24
                            ? `${Math.floor(props.subText / 24)} days`
                            : `${props.subText}  hrs`}
                        </Grid>
                      )}
                    </Grid>
                  </Button>
                </Grid>
              )}
            </Grid>
          )}
        </CardContent>
      </CardContent>
    </Card>
  );
};
