import React from "react";
import { makeStyles, Typography, Fab, Button, Grid } from "@material-ui/core";
import {
  Header,
  Textwithbutton,
  Textwithcount,
  TextwithTextcount,
  NotifyComponent,
} from "../../components";
import { Routes } from "../../router/routes";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { getHabitActivity } from "../../graphql/queries";
import { AuthContext } from "../../contexts";
import Moment from "moment";
import { extendMoment } from "moment-range";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    height: "100%",
    backgroundColor: theme.palette.background.paper,
    margin: 0,
  },
  root: {
    padding: theme.spacing(3),
    paddingTop: 80,
    paddingBottom: "95px",
  },
  header: {
    position: "fixed",
    zIndex: 1000,
    [theme.breakpoints.up("md")]: {
      width: 768,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  fabButton: {
    textTransform: "capitalize",
    position: "fixed",
    bottom: theme.spacing(14),
    right: theme.spacing(2),
    "& .MuiButton-root": {
      color: theme.palette.background.paper,
    },
  },
  footer: {
    borderTop: "2px dashed #00000014",
    boxShadow: "0px -5px 10px #00000014",
    textAlign: "center",
    background: theme.palette.background.paper,
    height: "88px",
    bottom: 0,
    position: "fixed",
    [theme.breakpoints.up("md")]: {
      width: 768,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  noData: {
    marginTop: "32px",
    height: "129px",
    textAlign: "center",
    background: "#0079650A",
    borderRadius: "8px",
  },
}));

export const GoalTracker = (props) => {
  const moment = extendMoment(Moment);
  const classes = useStyles();
  const history = useHistory();
  const auth = React.useContext(AuthContext);
  const { data, loading, error } = useQuery( getHabitActivity(auth?.user?.profile?.id), { fetchPolicy: "no-cache" });
  const [mrngActivity, setMrngActivity] = React.useState([]);
  const [aftrnunActivity, setAftrnunActivity] = React.useState([]);
  const [evngActivity, setEvngActivity] = React.useState([]);
  const [nightActivity, setNightActivity] = React.useState([]);
  const activity =
    mrngActivity?.length +
    aftrnunActivity?.length +
    evngActivity?.length +
    nightActivity?.length;

  const goHome = () => {
    history.push(Routes.home);
  };

  const addNewHabit = () => {
    history.push(Routes.newHabit);
  };

  const handleActivityupdate = (activityId) => {
    history.push({
      pathname: `${Routes.activityUpdateParent}/${activityId}`,
    });
  };

  const showStatistics = () => {
    history.push(Routes.weeklyStats);
  };

  React.useEffect(() => {
    let mrngActivity = [];
    let aftrnunActivity = [];
    let evngActivity = [];
    let nightActivity = [];
    if (!loading && data) {
      let activity = data?.allUserHabitActivities?.data;

      activity.map((item) => {
        var activityDate = moment(moment.utc(item?.dateTime)).format(
          "DD/MM/YYYY"
        );
        var todayDate = moment().format("DD/MM/YYYY");
        var todayActivity = activityDate === todayDate ? true : false;
        var activityTime = moment(
          moment.utc(item?.dateTime).format("HH:mm"),
          "HH:mm"
        );
        var mrngRange = moment().range(
          moment("05:00", "HH:mm"),
          moment("11:59", "HH:mm")
        );
        var mrngTime = mrngRange.contains(activityTime);
        var aftrnunRange = moment().range(
          moment("12:00", "HH:mm"),
          moment("16:59", "HH:mm")
        );
        var aftrnunTime = aftrnunRange.contains(activityTime);
        var evngRange = moment().range(
          moment("17:00", "HH:mm"),
          moment("19:59", "HH:mm")
        );
        var evngTime = evngRange.contains(activityTime);
        var nightRange = moment().range(
          moment("20:00", "HH:mm"),
          moment("23:59", "HH:mm")
        );
        var earlyMrng = moment().range(
          moment("00:00", "HH:mm"),
          moment("04:59", "HH:mm")
        );
        var nightTime = nightRange.contains(activityTime)
          ? nightRange.contains(activityTime)
          : earlyMrng.contains(activityTime)
          ? earlyMrng.contains(activityTime)
          : false;

        return todayActivity && mrngTime
          ? mrngActivity.push({
              id: item?.id,
              habit: item?.userHabits?.habit,
              habits: item?.userHabits?.habits,
              startDate: item?.userHabits?.startDate,
              endDate: item?.userHabits?.endDate,
              isCompleted: item?.isCompleted,
              time: moment.utc(item?.dateTime).format("hh:mm A"),
            })
          : todayActivity && aftrnunTime
          ? aftrnunActivity.push({
              id: item?.id,
              habit: item?.userHabits?.habit,
              habits: item?.userHabits?.habits,
              startDate: item?.userHabits?.startDate,
              endDate: item?.userHabits?.endDate,
              isCompleted: item?.isCompleted,
              time: moment.utc(item?.dateTime).format("hh:mm A"),
            })
          : todayActivity && evngTime
          ? evngActivity.push({
              id: item?.id,
              habit: item?.userHabits?.habit,
              habits: item?.userHabits?.habits,
              startDate: item?.userHabits?.startDate,
              endDate: item?.userHabits?.endDate,
              isCompleted: item?.isCompleted,
              time: moment.utc(item?.dateTime).format("hh:mm A"),
            })
          : todayActivity && nightTime
          ? nightActivity.push({
              id: item?.id,
              habit: item?.userHabits?.habit,
              habits: item?.userHabits?.habits,
              startDate: item?.userHabits?.startDate,
              endDate: item?.userHabits?.endDate,
              isCompleted: item?.isCompleted,
              time: moment.utc(item?.dateTime).format("hh:mm A"),
            })
          : [];
      });
      setMrngActivity(mrngActivity);
      setAftrnunActivity(aftrnunActivity);
      setEvngActivity(evngActivity);
      setNightActivity(nightActivity);
    }
    // eslint-disable-next-line
  }, [loading]);

  return (
    <div className={classes.rootContainer}>
      <div className={classes.header}>
        <Header
          showBackIcon
          showHomeIcon
          handleBack={goHome}
          handleHome={goHome}
        />
      </div>
      <div className={classes.root} noValidate>
        <div style={{ marginBottom: "8px" }}>
          {Notification.permission === "denied" && <NotifyComponent />}
        </div>
        {/* Today Activities */}
        <Textwithbutton
          actions={
            <div style={{ display: "flex" }}>
              <img
                src="/images/icons8-calendar (1).svg"
                alt="No"
                style={{ paddingRight: "8px" }}
              />
              <Typography variant="subtitle1" color="textPrimary">
                {moment().format("Do MMM, YYYY")}
              </Typography>
            </div>
          }
          cardText={
            <Textwithcount
              text="Activities Today"
              count={
                activity && activity < 1
                  ? 0
                  : activity < 10
                  ? `0${activity}`
                  : activity
              }
            />
          }
        />
        {/* Activities */}
        {loading ? (
          <Grid>
            {[...Array(5)].map((e, i) => {
              return (
                <Grid item key={i} style={{ margin: "16px 0px" }}>
                  <Skeleton variant="rect" width={"100%"} height={60} />
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <>
            {/* Morning Activity */}
            {mrngActivity?.length > 0 && (
              <>
                <TextwithTextcount
                  firstText="Morning"
                  textCount={mrngActivity?.length}
                />
                {mrngActivity?.map((item, i) => {
                  var habitName = item?.habit?.habitName
                    ? item?.habit?.habitName
                    : item?.habits?.habitName;
                  return (
                    <Textwithbutton
                      key={i}
                      onClick={() => handleActivityupdate(item?.id)}
                      actions={<img src="/images/arrow.svg" alt="No" />}
                      status={item?.isCompleted}
                      cardType={item?.isCompleted ? "" : "cardType"}
                      statusText={item?.isCompleted ? "Completed" : ""}
                      cardText={
                        <Typography variant="subtitle1" color={"textPrimary"}>
                          {habitName}
                        </Typography>
                      }
                      subText={
                        <Typography variant="body2" color="primary">
                          {item?.time}
                        </Typography>
                      }
                    />
                  );
                })}
              </>
            )}
            {/* Afternoon Activity */}
            {aftrnunActivity?.length > 0 && (
              <>
                <TextwithTextcount
                  firstText="Afternoon"
                  textCount={aftrnunActivity?.length}
                />
                {aftrnunActivity?.map((item, i) => {
                  var habitName = item?.habit?.habitName
                    ? item?.habit?.habitName
                    : item?.habits?.habitName;
                  return (
                    <Textwithbutton
                      key={i}
                      onClick={() =>
                        handleActivityupdate(
                          item?.id,
                          habitName,
                          item?.endDate,
                          item?.time
                        )
                      }
                      status={item?.isCompleted}
                      actions={<img src="/images/arrow.svg" alt="No" />}
                      cardType={item?.isCompleted ? "" : "cardType"}
                      statusText={item?.isCompleted ? "Completed" : ""}
                      cardText={
                        <Typography variant="subtitle1" color={"textPrimary"}>
                          {habitName}
                        </Typography>
                      }
                      subText={
                        <Typography variant="body2" color="primary">
                          {item?.time}
                        </Typography>
                      }
                    />
                  );
                })}
              </>
            )}
            {/* Evening Activity */}
            {evngActivity?.length > 0 && (
              <>
                <TextwithTextcount
                  firstText="Evening"
                  textCount={evngActivity?.length}
                />
                {evngActivity?.map((item, i) => {
                  var habitName = item?.habit?.habitName
                    ? item?.habit?.habitName
                    : item?.habits?.habitName;
                  return (
                    <Textwithbutton
                      key={i}
                      onClick={() =>
                        handleActivityupdate(
                          item?.id,
                          habitName,
                          item?.endDate,
                          item?.time
                        )
                      }
                      status={item?.isCompleted}
                      actions={<img src="/images/arrow.svg" alt="No" />}
                      cardType={item?.isCompleted ? "" : "cardType"}
                      statusText={item?.isCompleted ? "Completed" : ""}
                      cardText={
                        <Typography variant="subtitle1" color={"textPrimary"}>
                          {habitName}
                        </Typography>
                      }
                      subText={
                        <Typography variant="body2" color="primary">
                          {item?.time}
                        </Typography>
                      }
                    />
                  );
                })}
              </>
            )}
            {/* Night Activity */}
            {nightActivity?.length > 0 && (
              <>
                <TextwithTextcount
                  firstText="Night"
                  textCount={nightActivity?.length}
                />
                {nightActivity?.map((item, i) => {
                  var habitName = item?.habit?.habitName
                    ? item?.habit?.habitName
                    : item?.habits?.habitName;
                  return (
                    <Textwithbutton
                      key={i}
                      onClick={() =>
                        handleActivityupdate(
                          item?.id,
                          habitName,
                          item?.endDate,
                          item?.time
                        )
                      }
                      status={item?.isCompleted}
                      actions={<img src="/images/arrow.svg" alt="No" />}
                      cardType={item?.isCompleted ? "" : "cardType"}
                      statusText={item?.isCompleted ? "Completed" : ""}
                      cardText={
                        <Typography variant="subtitle1" color={"textPrimary"}>
                          {habitName}
                        </Typography>
                      }
                      subText={
                        <Typography variant="body2" color="primary">
                          {item?.time}
                        </Typography>
                      }
                    />
                  );
                })}
              </>
            )}
          </>
        )}
        {!loading &&
        mrngActivity?.length === 0 &&
        aftrnunActivity?.length === 0 &&
        evngActivity?.length === 0 &&
        nightActivity?.length === 0 ? (
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.noData}
          >
            <Grid item>
              <img src="/images/Group 5135.svg" alt="No" />
              <Typography variant="body2" color="textPrimary">
                {error
                  ? "Something went wrong, Try Again!"
                  : "No activities here at the moment!"}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          ""
        )}

        <Fab
          className={classes.fabButton}
          color="primary"
          aria-label="add"
          onClick={showStatistics}
        >
          <img src="/images/statistic-chart.svg" alt="NO" />
        </Fab>
      </div>
      <div className={classes.footer}>
        <Button
          color="primary"
          variant="contained"
          style={{ margin: "24px", width: "85%" }}
          onClick={addNewHabit}
        >
          ADD NEW HABIT
        </Button>
      </div>
    </div>
  );
};
