import React from "react";
import { Button, makeStyles, Typography } from "@material-ui/core";
import { Header, CardsectionCollapse } from "../../components";
import { Routes } from "../../router/routes";
import { useHistory } from "react-router-dom";
import {
  // getMasterMind,
  // getMasterBody,
  // getMasterFood,
  getMasterSmoking,
  getMasterPhysical,
  getMasterEatHealthy,
} from "../../graphql/queries";
import { useQuery } from "@apollo/client";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    backgroundColor: theme.palette.background.paper,
  },
  header: {
    position: "fixed",
    zIndex: 1000,
    [theme.breakpoints.up("md")]: {
      width: 768,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  root: {
    padding: theme.spacing(3),
    paddingTop: 80,
  },
  addButton: {
    marginTop: "16px",
    marginBottom: "8px",
    paddingTop: "10px",
    paddingBottom: "10px",
    borderTop: "1px dashed #C5C5C5",
    borderBottom: "1px dashed #C5C5C5",
    width: "100%",
    alignItems: "left",
    "& .MuiButton-text": {
      padding: "6px 0px",
    },
  },
}));

export const NewHabit = (props) => {
  const classes = useStyles();
  const history = useHistory();
  // const { data, loading, error } = useQuery(getMasterMind());
  // const {
  //   data: bodyData,
  //   loading: bodyLoading,
  //   error: bodyError,
  // } = useQuery(getMasterBody());
  // const {
  //   data: foodData,
  //   loading: foodLoading,
  //   error: foodError,
  // } = useQuery(getMasterFood());
  const {
    data: smokingData,
    loading: smokingLoading,
    error: smokingError,
  } = useQuery(getMasterSmoking());
  const {
    data: physicalData,
    loading: physicalLoading,
    error: physicalError,
  } = useQuery(getMasterPhysical());
  const {
    data: eatHealthyData,
    loading: eatHealthyLoading,
    error: eatHealthyError,
  } = useQuery(getMasterEatHealthy());

  const goBack = () => {
    history.goBack();
  };

  const goHome = () => {
    history.push(Routes.home);
  };

  const addCustomHabit = () => {
    history.push(Routes.addHabit);
  };

  const handleList = (name, category, habitId) => {
    history.push({
      pathname: Routes.addHabit,
      state: {
        name,
        habit_id: habitId,
        category: [
          {
            value: category,
            label: category,
          },
        ],
      },
    });
  };

  return (
    <div className={classes.rootContainer}>
      <div className={classes.header}>
        <Header
          showBackIcon
          showHomeIcon
          handleBack={goBack}
          handleHome={goHome}
        />
      </div>
      <div className={classes.root} noValidate>
        <Typography variant="body1" color="textPrimary">
          New Habit
        </Typography>
        <div className={classes.addButton}>
          <Button variant="text" color="primary" onClick={addCustomHabit}>
            <img
              src="/images/icons8-add.svg"
              alt="add"
              style={{ marginRight: "12px", width: "20px", height: "20px" }}
            />
            <Typography variant="subtitle1" color="primary">
              Custom Habit
            </Typography>
          </Button>
        </div>
        <div>
          {/* <CardsectionCollapse
            Header="Mind"
            headericon="/images/icons8-bright-mind.svg"
            habits={data?.allMasterHabits?.data}
            handleList={handleList}
            loading={loading}
            error={error}
          />
          <CardsectionCollapse
            Header="Body"
            headericon="/images/body.svg"
            habits={bodyData?.allMasterHabits?.data}
            handleList={handleList}
            loading={bodyLoading}
            error={bodyError}
          />
          <CardsectionCollapse
            Header="Food"
            headericon="/images/food.svg"
            habits={foodData?.allMasterHabits?.data}
            handleList={handleList}
            loading={foodLoading}
            error={foodError}
          /> */}
          <CardsectionCollapse
            Header="Stop Smoking"
            headericon="/images/food.svg"
            habits={smokingData?.allMasterHabits?.data}
            handleList={handleList}
            loading={smokingLoading}
            error={smokingError}
          />
          <CardsectionCollapse
            Header="Be Physically Active"
            headericon="/images/body.svg"
            habits={physicalData?.allMasterHabits?.data}
            handleList={handleList}
            loading={physicalLoading}
            error={physicalError}
          />
          <CardsectionCollapse
            Header="Eating Healthy"
            headericon="/images/food.svg"
            habits={eatHealthyData?.allMasterHabits?.data}
            handleList={handleList}
            loading={eatHealthyLoading}
            error={eatHealthyError}
          />
        </div>
      </div>
    </div>
  );
};
