import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import Slider from "react-slick";
import { Carouselcard } from "..";

const useStyles = makeStyles((theme) => ({
  header: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

export const Carouselcomponentcard = ({ carouseldata = [], text = "" }) => {
  const classes = useStyles();

  const settings = {
    infinite: true,
    //autoplay: true,
    autoplaySpeed: 3000,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    variableWidth: true,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Typography
        variant="body1"
        color={"textPrimary"}
        className={classes.header}
      >
        {text}
      </Typography>
      <Slider {...settings}>
        {carouseldata.map((val, i) => {
          return (
            <div className={classes.root} key={i}>
              <Carouselcard cardText={val.cardText} src={val.src} />
            </div>
          );
        })}
      </Slider>
    </div>
  );
};
