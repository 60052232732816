import React from "react";
import { withNavBars } from "../../HOCs";
import { withRouter } from "react-router-dom";
import { CompleteProfile } from "./completeProfile";
import { Routes } from '../../router/routes';
class CompleteProfileParent extends React.Component {

  componentDidMount() {
    if (!this.props.location?.state?.auth_token) {
      this.props.history.push(Routes.home)
    }
  }

  render() {
    const {
      mobile = "",
      mobile_code = "",
      auth_token = "",
      profile = {},
      user_id = ""
    } = this.props.location?.state ?? {};

    return <CompleteProfile mobile={mobile} mobile_code={mobile_code} auth_token={auth_token} profile={profile} user_id={user_id} />
  }
}

export default withRouter(withNavBars(CompleteProfileParent));
