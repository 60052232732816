import React from "react";
import { makeStyles, Grid, Slide } from "@material-ui/core";
import { MultiSelectDropdown, Header, FooterButton } from "../../components";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
import { AppointmentContext } from "../../contexts";
import { useQuery } from "@apollo/client";
import { getMasterHealthProblems } from "../../graphql/queries";
import { getUniqueArrayByValue } from "../../utils";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    height: "100%",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
    margin: 0,
  },
  root: {
    padding: theme.spacing(3),
    paddingBottom: "95px",
    paddingTop: 80,
  },
  header: {
    position: "fixed",
    zIndex: 1000,
    [theme.breakpoints.up("md")]: {
      width: 768,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  footer: {
    bottom: 0,
    position: "fixed",
    [theme.breakpoints.up("md")]: {
      width: 768,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

export const HealthProblems = (props) => {
  const appointmentContext = React.useContext(AppointmentContext);
  const classes = useStyles(props);
  const history = useHistory();

  const { data, loading, fetchMore } = useQuery(getMasterHealthProblems(), {
    variables: {
      limit: 10,
      offset: 0,
      search: "",
    },
  });

  const [state, setState] = React.useState({
    healthProblem: appointmentContext.health_problems,
  });

  const handleAdd = (newData) => {
    //Taking the old healthProblems
    let healthProblem = state.healthProblem;

    //If it a object then it is coming from select box
    if (newData.value && newData) {
      healthProblem.push(newData);
    }
    // Else it is coming from quick suggestions
    else {
      // Taking the Quick suggestion data
      let qucikSugData = data?.healthProblem?.data?.slice(0, 10);
      // Removing Quick Suggestion from healthProblems
      healthProblem = healthProblem.filter((_) => _.value > 10);
      // Adding the new healthProblem
      newData = qucikSugData.filter((_) => newData.indexOf(_.value) > -1);
      healthProblem.push(...newData);
    }
    healthProblem = getUniqueArrayByValue(healthProblem, "value");
    setState({ ...state, healthProblem: healthProblem });
  };

  const loadHealthproblems = async (search, loadedOptions) => {
    let offset = loadedOptions?.length ?? 0;

    const res = await fetchMore({
      variables: {
        limit: 10,
        offset,
        search: search,
      },
    });

    return {
      options: res?.data?.healthProblem?.data,
      hasMore: res?.data?.healthProblem?.pageInfo?.hasNextPage,
    };
  };

  const goBack = () => {
    history.goBack();
  };
  const goHome = () => {
    history.push(Routes.home);
  };
  const goNext = () => {
    history.push(Routes.allergies);
  };

  const handleDelete = (i) => {
    state.healthProblem.splice(i, 1);
    setState({ ...state });
  };

  const handleClear = () => {
    setState({
      ...state,
      healthProblem: [],
    });
  };

  React.useEffect(() => {
    appointmentContext.health_problems = state.healthProblem;
    // eslint-disable-next-line
  }, [state]);

  return (
    <div className={classes.rootContainer}>
      <div className={classes.header}>
        <Header
          showBackIcon
          showHomeIcon
          handleBack={goBack}
          handleHome={goHome}
        />
      </div>
      <Slide direction="left" in={true} mountOnEnter unmountOnExit>
        <div className={classes.root} noValidate>
          <Grid>
            <Grid item>
              <MultiSelectDropdown
                label="What problems do you have?"
                selectBoxPlaceholder="Eg. Ulcer"
                selectBoxIsPaginate={true}
                selectBoxOnChange={handleAdd}
                selectBoxLoadOptions={loadHealthproblems}
                selectBoxIsLoading={loading}
                selectBoxOptions={data?.healthProblem?.data}
                quickSugLabel="Quick Suggestions"
                quickSugValue={state?.healthProblem?.map((_) => _.value)}
                quickSugOptions={data?.healthProblem?.data?.slice(0, 10) ?? []}
                quickSugOnClick={handleAdd}
                displaySecTitle="Symptoms Added"
                displaySecValues={state?.healthProblem}
                displaySecOnDeleteClick={handleDelete}
                displaySecOnClearAllClick={handleClear}
              />
            </Grid>
          </Grid>
        </div>
      </Slide>
      <div className={classes.footer}>
        <FooterButton
          firstButton="Skip"
          handleClick={goNext}
          btnDisable={state?.healthProblem?.length > 0 ? false : true}
          secondButton="Continue"
          onClick={goNext}
        />
      </div>
    </div>
  );
};
