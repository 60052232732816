import React from "react";
import { Typography, Grid, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FooterButton } from "../footerButton";

const useStyles = makeStyles((theme) => ({
  slotCard: {
    border: `1px solid ${theme.palette.border.light}`,
    borderRadius: "24px 24px 0px 0px",
    backgroundColor: `${theme.palette.common.white}`,
    boxShadow: "0px -10px 10px #0000000A",
    textAlign: "center",
  },
  textField: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
  },
}));

export const AppointmentCancel = (props) => {
  const classes = useStyles(props);

  return (
    <div className={classes.slotCard}>
      <Grid>
        <Grid
          item
          align="center"
          style={{ marginTop: "24px", marginBottom: "16px" }}
        >
          <img src="/images/Group 5143.svg" alt="NO" />
        </Grid>
        <Grid item>
          {" "}
          <Typography
            variant="body1"
            color={"textPrimary"}
            align="center"
            gutterBottom
          >
            Are you sure you want to cancel this appointment?
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" color="Primary">
            Reason for cancellation (Optional)
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            disabled={props.disable}
            type={props.type}
            className={classes.textField}
            id={props.id}
            required={props.isrequired}
            variant={"outlined"}
            rows={5}
            rowsMax={10}
            value={props.value}
            placeholder="Type here.."
            multiline
            onChange={(e) => props.onChange(e.target.value)}
          />
        </Grid>
      </Grid>
      <FooterButton
        firstButton="go back"
        handleClick={props.goCancel}
        secondButton="Yes, cancel"
        onClick={props.yesCancel}
        updateLoading={props.updateLoading}
        btnDisable={props.btnDisable}
        loadingMessage={props.loadingMessage}
      />
    </div>
  );
};
