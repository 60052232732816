import React from "react";
import { makeStyles, Grid, Button, Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  footerbtn: {
    padding: 7,
    borderTop: "2px dashed #00000014",
    boxShadow: "0px -5px 10px #00000014",
    background: theme.palette.background.paper,
  },

  firstButton: {
    backgroundColor: theme.palette.common.white,
    boxShadow: "none",
    width: "100%",
    borderRadius: "8px",
    color: `${theme.palette.primary.main}`,
    border: `1px solid ${theme.palette.primary.main}`,
    fontSize: "14px",
    "&:hover": {
      backgroundColor: theme.palette.common.white,
      color: `${theme.palette.primary.main}`,
      boxShadow: "none",
    },
  },
  secondButton: {
    backgroundColor: `${theme.palette.primary.main}`,
    color: theme.palette.common.white,
    fontSize: "14px",
    boxShadow: "none",
    width: "100%",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: `${theme.palette.primary.main}`,
      color: theme.palette.common.white,
      boxShadow: "none",
    },
  },
  grid: {
    padding: "10px",
  },
}));
export const FooterButton = ({
  firstButton = "",
  secondButton = "",
  loadingMessage = "",
  callButton = "",
  btnDisable = false,
  isLoading = false,
  handleClick = () => false,
  onClick = () => false,
  phoneNo = "",
}) => {
  const classes = useStyles();

  return (
    <div>
      <Box className={classes.footerbtn}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          {firstButton && (
            <Grid item xs={6} className={classes.grid}>
              <Button
                disableRipple
                className={classes.firstButton}
                onClick={() => handleClick()}
                variant="contained"
              >
                {firstButton}
              </Button>
            </Grid>
          )}

          {callButton ? (
            <Grid item xs={6} className={classes.grid}>
              <a href={"tel:" + phoneNo} style={{ textDecoration: "none" }}>
                <Button
                  disabled={btnDisable}
                  className={classes.secondButton}
                  variant="contained"
                >
                  {callButton}
                </Button>
              </a>
            </Grid>
          ) : (
            <Grid item xs={6} className={classes.grid}>
              <Button
                disableRipple
                onClick={onClick}
                disabled={btnDisable}
                className={classes.secondButton}
                variant="contained"
              >
                {isLoading ? `${loadingMessage}` : `${secondButton}`}
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    </div>
  );
};
