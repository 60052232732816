import React from "react";
import { makeStyles, Button, Typography, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  rootMain: {
    background: `${theme.palette.primary.main}`,
    borderRadius: 0,
    padding: 8,
    cursor: "pointer"
  },
  root: {
    "& .MuiTypography-subtitle1": {
      color: theme.palette.background.paper,
    },
    backgroundImage: `url(${'"/images/Path 4201.svg"'}) !important`,
    backgroundRepeat: "no-repeat",
  },
  sosIcon: {
    marginTop: 8,
    paddingLeft: 5,
  },

  HelpButton: {
    paddingRight: "5px",
    "& .MuiButton-root": {
      textTransform: "capitalize",
      background: theme.palette.common.white,
    },
  },
}));
export const EmergencyHelper = (props) => {
  const classes = useStyles(props);

  return (
    <Grid container className={classes.rootMain}>
      <Grid
        container
        className={classes.root}
        direction="row"
        justify="space-between"
        alignItems="center"
        onClick={() => props.handleClick()}
      >
        <Grid item className={classes.sosIcon}>
          <img src="/images/icons8-sos.svg" alt="BannerImage" />
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">Is there an emergency?</Typography>
        </Grid>
        <Grid item className={classes.HelpButton}>
          <Button
            size={"small"}
            variant="outlined"
            color={"primary"}
            onClick={() => props.handleClick()}
          >
            Get Help
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
