import dotenv from "dotenv";
dotenv.config();

let config = {};

config.auth_service = `${process.env.REACT_APP_AUTH_API_PROTOCAL}${process.env.REACT_APP_AUTH_API_HOST}${process.env.REACT_APP_AUTH_API_PORT}${process.env.REACT_APP_AUTH_API_BASE_URL}`;
config.rest_api_service = `${process.env.REACT_APP_REST_API_PROTOCAL}${process.env.REACT_APP_REST_API_HOST}${process.env.REACT_APP_REST_API_PORT}${process.env.REACT_APP_REST_API_BASE_URL}`;
config.graphql_service = `${process.env.REACT_APP_GRAPHQL_PROTOCAL}${process.env.REACT_APP_GRAPHQL_HOST}${process.env.REACT_APP_GRAPHQL_PORT}${process.env.REACT_APP_GRAPHQL_BASE_URL}`;
config.graphql_web_socket_service = `${process.env.REACT_APP_GRAPHQL_WEB_SOCKET_PROTOCAL}${process.env.REACT_APP_GRAPHQL_WEB_SOCKET_HOST}${process.env.REACT_APP_GRAPHQL_WEB_SOCKET_PORT}${process.env.REACT_APP_GRAPHQL_WEB_SOCKET_BASE_URL}`;

export { config };
