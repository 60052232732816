import React from "react";
import Select from "react-select";
import { makeStyles, Typography } from "@material-ui/core";
import { AsyncPaginate } from "react-select-async-paginate";
import CreatableSelect from "react-select/creatable";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "16px",
  },
  createBtn: {
    display: "inline-flex",
    cursor: "pointer",
  },
}));

const customStyles = {
  control: (base) => ({
    ...base,
    borderRadius: 8,
    height: 48,
    fontSize: "16px",
    minHeight: 48,
    border: "1.5px solid #E2E2E2",
    boxShadow: "none",
    "&:hover": {
      border: "1.5px solid #007965",
    },
  }),
};

export const SelectBox = ({
  label = "",
  isRequired = false,
  isPaginate = false,
  createSelect = false,
  error = false,
  helperText = "",
  value = "",
  placeholder = "",
  options = [],
  loading = false,
  loadOptions = () => null,
  onChange = () => null,
}) => {
  const classes = useStyles();
  const formatCreateLabel = (inputValue) => (
    <span className={classes.createBtn}>
      <img
        src="/images/icons8-add.svg"
        alt="add"
        style={{ marginRight: "8px" }}
      />
      <Typography variant="body1" color="primary">
        {" "}
        Add {inputValue}
      </Typography>
    </span>
  );

  return (
    <div className={classes.root}>
      <div style={{ display: "flex" }}>
        {
          <Typography variant="body2" color="primary" align="left" gutterBottom>
            {label}
          </Typography>
        }
        {isRequired && (
          <Typography color="error" variant="caption">
            *
          </Typography>
        )}
      </div>

      {isPaginate ? (
        <AsyncPaginate
          isClearable
          isSearchable
          components={{
            IndicatorSeparator: () => null,
          }}
          value={value}
          placeholder={placeholder}
          loadOptions={loadOptions}
          onChange={onChange}
          options={options}
          isLoading={loading}
          defaultOptions={options}
          styles={customStyles}
        />
      ) : createSelect ? (
        <CreatableSelect
          isClearable={false}
          options={options}
          isLoading={loading}
          placeholder={placeholder}
          components={{
            IndicatorSeparator: () => null,
          }}
          value={value}
          onChange={onChange}
          styles={customStyles}
          formatCreateLabel={formatCreateLabel}
        />
      ) : (
        <Select
          isClearable={false}
          isSearchable
          components={{
            IndicatorSeparator: () => null,
          }}
          value={value}
          placeholder={placeholder}
          options={options}
          isLoading={loading}
          onChange={onChange}
          styles={customStyles}
        />
      )}
      {error && (
        <Typography variant="caption" color="error">
          {helperText}
        </Typography>
      )}
    </div>
  );
};
