import React from "react";
import {
  Button,
  Grid,
  makeStyles,
  IconButton,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import WarningAmberIcon from "@material-ui/icons/Warning";

const useStyles = makeStyles((theme) => ({
  notificationAlert: {
    background: "#fff !important",
    color: "black",
    border: "1px solid #c6c6c699",
    borderRadius: "8px",
    padding: "8px",
    boxShadow: "0px 10px 15px #00000014",
  },
}));

export const NotifyComponent = (props) => {
  const classes = useStyles();

  const [openShow, setOpenShow] = React.useState(true);
  const handleClosed = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenShow(false);
  };
  const openUrl = () => {
    let url = "https://support.google.com/chrome/answer/3220216/";
    window.open(url);
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClosed}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <>
      {openShow && (
        <div className={classes.notificationAlert}>
          <Grid container direction="row">
            <Grid item xs={1} md={1} style={{margin: "8px 0px"}}>
              <WarningAmberIcon color="error"  />
            </Grid>
            <Grid item xs={10} md={10} style={{padding: "0px 8px"}}>
              <Typography variant="body2" color="textPrimary">
                Looks like you haven't enabled push notifications, you won't
                receive reminders until that.
              </Typography>
              <div style={{ textAlign: "center" }}>
                <Button
                  onClick={openUrl}
                  variant="outlined"
                  color="primary"
                  style={{
                    fontSize: "12px",
                    marginTop: "4px",
                  }}
                >
                  Learn how to enable >
                </Button>
              </div>
            </Grid>
            <Grid item xs={1} md={1}>
              {action}
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};
