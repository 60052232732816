import React from "react";
import { makeStyles, Card, CardContent, Grid, Typography, Icon, Button } from "@material-ui/core";
import { Cardheader } from "../cardheader";
import { Calender } from "../../assets";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "0px 10px 15px #00000014",
    border: `1px solid ${theme.palette.border.light}`,
    borderRadius: "8px",
    opacity: "1",
    background: theme.palette.common.white,
  },
  content: {
    "&:last-child": {
      padding: 0,
    },
  },
  cardFooter: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2),
    borderTop: `1px dashed ${theme.palette.border.light}`,
  },
  cardFooterSuccessText: {
    color: theme.palette.text.success,
    fill: theme.palette.text.success,
    fontFamily: "healthcircles_medium",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center"
  },
  cardFooterErrorText: {
    color: theme.palette.text.error,
    fill: theme.palette.text.error,
    fontFamily: "healthcircles_medium"
  },
  bookNowBtn: {
    fontFamily: "healthcircles_medium",
    marginLeft: 8,
    "& .MuiButton-outlinedPrimary":{
      border: `1.5px solid ${theme.palette.primary.main}`
    }
  }
}));

export const AppointmentBookingCard = ({
  price = "",
  title = "",
  firstSubText = "",
  firstIcon = "",
  secondSubText = "",
  secondIcon = "",
  profile_pic = "",
  slots = null,
  bookSlot = () => false,
  bookedOn = "",
  goNext = () => false
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <Cardheader
          profile_pic={profile_pic}
          price={price}
          title={title}
          firstSubText={firstSubText}
          firstIcon={firstIcon}
          secondSubText={secondSubText}
          secondIcon={secondIcon}
        />

        <Grid
          className={classes.cardFooter}
          container
          direction="row"
          justify="space-between"
        >
          <Grid item style={{marginBottom: "5px"}}>
            <Typography
              variant="body2"
              className={
                slots ? classes.cardFooterSuccessText : classes.cardFooterErrorText
              }>
              <Icon>
                <Calender cls={slots ? classes.cardFooterSuccessText : classes.cardFooterErrorText} />
              </Icon>
              {slots ? bookedOn ? bookedOn : "Available Today" : "Unavailable Today"}
            </Typography>
          </Grid>

          <Grid item style={{ display: "flex" }}>
            <Button
              className={classes.bookNowBtn}
              variant={"outlined"}
              color={"primary"}
              size={"small"}
              onClick={bookSlot}
            >
             <Typography variant="subtitle1" color="primary">{bookedOn ? "Change" : "Book Now"}</Typography>
            </Button>
            {bookedOn && <Grid item>
              <Button
                className={classes.bookNowBtn}
                variant={"contained"}
                color={"primary"}
                size={"small"}
                onClick={goNext}
              >
                <Typography variant="subtitle1" color="inherit"> Next</Typography>
              </Button>
            </Grid>}
          </Grid>


        </Grid>
      </CardContent>
    </Card>
  );
};
