import React from "react";
import { withRouter } from "react-router-dom";
import { withNavBars } from "../../HOCs";
import { SelectPatient } from "./selectPatient";

class SelectPatientParent extends React.Component {
  render() {
    return <SelectPatient />;
  }
}

export default withRouter(withNavBars(SelectPatientParent));
