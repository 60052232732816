import React from 'react';
import { Typography, Grid, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        height: "100%"
    },
    loaderBackground: {
        backgroundImage: `url(${'"/images/Sending OTP.svg"'}) !important`,
        height: "100%",
        backgroundRepeat: "no-repeat"
    },
}))

export const OtpLoader = ({
    message = ""
}) => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container className={classes.loaderBackground} direction="column" alignItems="center" justify="center">
                <Grid item>
                    <CircularProgress color={"primary"} />
                </Grid>
                <Grid item>
                    <Typography variant="h6">{message}</Typography>
                </Grid>
            </Grid>
        </div>
    )
}
