import React from "react";
import { withNavBars } from "../../HOCs";
import { Appointments } from "./appointments";
class AppointmentsParent extends React.Component {
  render() {
    return <Appointments />;
  }
}

export default withNavBars(AppointmentsParent);
