import React from "react";
import { withRouter } from "react-router-dom";
import { withNavBars } from "../../HOCs";
import { AddHabit } from "./addHabit";

class AddHabitParent extends React.Component {
  render() {
    return <AddHabit habit={this.props.location?.state} />;
  }
}

export default withRouter(withNavBars(AddHabitParent));
