import React from "react";
import { withRouter } from "react-router-dom";
import { withNavBars } from "../../HOCs";
import { ActivityUpdate } from "./activityUpdate";

class ActivityUpdateParent extends React.Component {
  render() {
    return <ActivityUpdate habitUpdate={this.props.match.params} habit={this.props.location?.state}/>;
  }
}

export default withRouter(withNavBars(ActivityUpdateParent));
