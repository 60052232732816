import React from "react";
import moment from "moment";
import { TextField, makeStyles } from "@material-ui/core";
import { ToggleButtonComponent } from "../../components";

const useStyles = makeStyles((theme) => ({
    root: {
        height: 150,
        padding: theme.spacing(2)
    }
}))

export const DoctorFilter = ({
    filterArray = [],
    selectedFilter = 'today',
    onFilterChange = () => { },
    customDate = new Date(),
}) => {

    const classes = useStyles();

    const onChange = (newFormat, customDate) => {
        onFilterChange(newFormat, customDate)
    }

    return <div className={classes.root}>
        <ToggleButtonComponent
            toggleButtonData={filterArray}
            isSelected={selectedFilter}
            onSelect={onChange}
            exclusive={true}
        />
        {selectedFilter === 'custom' && <TextField
            id="date"
            label=""
            type="date"
            value={moment(customDate).format('YYYY-MM-DD').toString()}
            onChange={(e) => onChange('custom', e.target.value)}
            defaultValue={moment()}
            InputLabelProps={{
                shrink: true,
            }}
            inputProps={{
                "min": moment().format('YYYY-MM-DD')
            }}
        />}
    </div>
}