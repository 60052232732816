import React from "react";
import { Header, FooterButton, Profile } from "../../components";
import { ValidateEmail } from "../../utils";
import { Routes } from "../../router/routes";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { getMasterRelationship, getRelations } from "../../graphql/queries";
import { useQuery } from "@apollo/client";
import { AppointmentContext, AuthContext } from "../../contexts";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  profile: {
    paddingBottom: "95px",
    backgroundColor: theme.palette.background.paper,
    paddingTop: 64,
  },
  header: {
    position: "fixed",
    zIndex: 1000,
    [theme.breakpoints.up("md")]: {
      width: 768,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  footer: {
    bottom: 0,
    position: "fixed",
    [theme.breakpoints.up("md")]: {
      width: 768,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

export const PatientDetails = () => {
  const history = useHistory();
  const classes = useStyles();
  const auth = React.useContext(AuthContext);
  const appointmentContext = React.useContext(AppointmentContext);
  const { data, loading } = useQuery(getMasterRelationship());
  const { data: relationNames, loading: isLoading } = useQuery(
    getRelations(auth?.user?.profile?.id),
    { fetchPolicy: "no-cache" }
  );
  const [state, setState] = React.useState(appointmentContext.booking_for);
  const [relation, setRelation] = React.useState([]);

  const changeState = (key, value) => {
    if (value !== null) {
      state[key] = value;
      state.error[key] = false;
      appointmentContext.booking_for[key] = value;
      setState({ ...state });
    }
    if (value?.id) {
      appointmentContext.booking_for["id"] = value?.id;
      setState({
        ...state,
        gender: value?.gender,
        email: value?.emailId,
        releationship: {
          value: value?.relationValue,
          label: value?.relationLabel,
        },
        dob_age: {
          age: value?.age,
          dob: value?.dob,
        },
        mobile: {
          mobile: value?.mobileNo,
          mobile_code: value?.mobileNoCountryCode,
        },
      });
    }
  };
  const validation = () => {
    let emailvalid = ValidateEmail(state?.email) ? true : false;
    let namevalid = state?.name?.value?.length > 0 ? true : false;
    let dobvalid =
      state?.dob_age?.dob?.length > 0 || state?.dob_age?.age > 0 ? true : false;
    let gendervalid = state?.gender?.length > 0 ? true : false;
    let mobilevalid = state?.mobile?.mobile?.length > 0 ? true : false;
    let relationshipvalid =
      state?.releationship?.label?.length > 0 ? true : false;
    if (
      namevalid &&
      gendervalid &&
      dobvalid &&
      relationshipvalid &&
      mobilevalid
    ) {
      return true;
    } else {
      state.error = {
        email: emailvalid ? false : true,
        name: namevalid ? false : true,
        gender: gendervalid ? false : true,
        dob_age: dobvalid ? false : true,
        releationship: relationshipvalid ? false : true,
        mobile: mobilevalid ? false : true,
      };
      setState({ ...state });
    }
  };

  const saveForm = () => {
    const isValidated = validation();
    if (!isValidated) {
      return false;
    } else {
      history.push(Routes.consultationReason);
    }
  };

  const goBack = () => {
    history.goBack();
  };
  const goHome = () => {
    history.push(Routes.home);
  };

  React.useEffect(() => {
    appointmentContext.booking_for = state;
    // eslint-disable-next-line
  }, [state]);

  React.useEffect(() => {
    let relation = [];
    if (!isLoading && relationNames) {
      let data = relationNames?.allUserProfiles?.data;
      data.map((item) => {
        return relation.push({
          id: item?.id,
          value: item?.firstName,
          label: `${item?.firstName} (${item?.relations?.label})`,
          age: item?.age,
          dob: item?.dob,
          gender: item?.gender,
          relationValue: item?.relations?.value,
          relationLabel: item?.relations?.label,
          mobileNo: item?.mobileNo,
          mobileNoCountryCode: item?.mobileNoCountryCode,
          emailId: item?.emailId,
        });
      });
      setRelation(relation);
    }

    // eslint-disable-next-line
  }, [isLoading]);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Header
          showBackIcon
          showHomeIcon
          handleBack={goBack}
          handleHome={goHome}
        />
      </div>
      <div className={classes.profile}>
        <Profile
          {...state}
          changeState={changeState}
          profileText="Enter patient details"
          selectBoxOptions={data?.relationship?.data}
          selectBoxIsLoading={loading}
          relationOptions={relation}
          relationLoading={isLoading}
          hideMobileNo
          hideName
          hideMemberShip
        />
      </div>
      <div className={classes.footer}>
        <FooterButton
          firstButton="Cancel"
          secondButton="Save"
          handleClick={goBack}
          onClick={saveForm}
        />
      </div>
    </div>
  );
};
