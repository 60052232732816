/**
 * Object with role as key and value, which is used for
 * comparison of role in different place.
 */
export const UserRoles = {
  role: "role",
};

/**
 * Object which has the proper name of all the role
 * used in the application.
 */
export let UserRolesName = {
  role: "Role",
};

/**
 * Object which has the different themes used in
 * the application.
 */
export let Themes = {
  default: "default",
  dark: "dark",
};

/**
 * Object which has the different props for the Alert Component (/src/component/alert)
 * which is used via AlertContext (/src/contexts) and provided at /src/App.alert.js.
 */
export let AlertProps = {
  vertical: {
    top: "top",
    bottom: "bottom",
  },
  horizontal: {
    left: "left",
    right: "right",
    center: "center",
  },
  severity: {
    success: "success",
    error: "error",
    warning: "warning",
    info: "info",
  },
};

/**
 * Object which has the different props for the Drawer Component (/src/App.drawer.js)
 * which is used via DrawerContext (/src/contexts) and provided at /src/App.drawer.js.
 */
export const DrawerProps = {
  direction: {
    top: "top",
    bottom: "bottom",
    left: "left",
    right: "right",
  },
  variant: {
    permanent: "permanent",
    persistent: "persistent",
    temporary: "temporary",
  },
};

/**
 * Object has the key and value pair of all the keys which
 * are used to store some values in the local storage.
 */
export let LocalStorageKeys = {
  authToken: "auth_token",
  version: "version",
  deviceToken: "device_token"
};

/**
 * Object has the key and value pair of all the HTTP method
 * used for an network call.
 */
export let NetWorkCallMethods = {
  get: "GET",
  post: "POST",
  put: "PUT",
  delete: "DELETE",
  update: "UPDATE",
};

// Gender Data
export const genderData = [
  {
    value: "MALE",
    label: "Male",
  },
  {
    value: "FEMALE",
    label: "Female",
  },
  {
    value: "TRANSGENDER",
    label: "Transgender",
  },
];

// DOB and Age
export const dobAgeData = [
  {
    value: "1",
    label: "D.O.B",
  },
  {
    value: "2",
    label: "Years",
  },
];

// Carousel Cards
export const carouselList = [
  {
    cardText: "Use Mask",
    src: "/images/Use Mask.svg",
  },
  {
    cardText: "Avoid Contact",
    src: "/images/Avoid Contact.svg",
  },
  {
    cardText: "Wash Hands",
    src: "/images/Wash Hands.svg",
  },
  {
    cardText: "CleanEverything",
    src: "/images/Sanitize.svg",
  },
];

// Habit Categories
export const habitCategories = [
  {
    value: "Mind",
    label: "Mind",
  },
  {
    value: "Body",
    label: "Body",
  },
  {
    value: "Food",
    label: "Food",
  },
];

export const habitNewCategories = [
  {
    value: "Stop Smoking",
    label: "Stop Smoking",
  },
  {
    value: "Be Physically Active",
    label: "Be Physically Active",
  },
  {
    value: "Eating Healthy",
    label: "Eating Healthy",
  },
];