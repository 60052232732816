import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Typography,
  InputAdornment,
  Grid,
  Card,
  Button,
  CardHeader,
  IconButton,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { Textwithcount } from "../textWithCount/index";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    "& .MuiTypography-gutterBottom": {
      marginBottom: "8px",
    },
    "& .MuiTextField-root": {
      background: theme.palette.common.white,
    },
    "& .MuiOutlinedInput-input": {
      padding: "14px 14px",
    },
  },
  grid: {
    borderTop: "1px dashed #C5C5C5",
    marginTop: "16px",
    display: "inline-flex",
    justifyContent: "center",
  },
  clearall: {
    display: "flex",
    justifyContent: "flex-end",
  },
  textField: {
    marginTop: "16px",
    "& .MuiOutlinedInput-multiline ": {
      padding: 0,
    },
    "& .MuiInputAdornment-root": {
      cursor: "pointer",
      position: "absolute",
      right: 0,
      bottom: 24,
      "& .MuiIconButton-root": {
        padding: 3,
      },
    },
  },
  chip: {
    borderRadius: "5px",
    backgroundColor: "#F5F5F5",
    border: "1px solid #18445714",
    marginLeft: "10px",
    "& .MuiChip-label": {
      paddingLeft: "7px",
      paddingRight: "7px",
      fontSize: "12px",
    },
  },
  gridItem: {
    padding: 16,
    paddingLeft: 0,
  },
  Card: {
    backgroundColor: "#18445714",
    border: "1px solid #18445729",
    padding: "6px",
    paddingTop: 2,
    paddingBottom: 2,
    marginBottom: 12,
    boxShadow: "none",
    "& .MuiCardHeader-root": {
      padding: "6px",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "16px",
    },
    "& .MuiTypography-body1":{
      overflowWrap: "anywhere"
    }
  },
}));

export const CustomTextadder = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Typography variant="body1" color={"textPrimary"}>
        Describe your health issues
      </Typography>
      <TextField
        fullWidth
        disabled={props.disable}
        type={props.type}
        className={classes.textField}
        id={props.id}
        required={props.isrequired}
        variant={"outlined"}
        InputProps={{
          endAdornment: (
            <InputAdornment onClick={() => props.add()} position="start">
              <IconButton color="primary" disabled={!props.value}>
                {!props.value ? (
                  <img src="/images/icons8-add-disable.svg" alt="add" />
                ) : (
                  <img src="/images/icons8-add.svg" alt="add" />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
        rows={5}
        rowsMax={10}
        value={props.value}
        placeholder="Type here.."
        multiline
        onChange={props.onChange}
        onKeyPress={(e) => props.enterKeyPress ? props.enterKeyPress(e) : ""}
      />
      <Grid container direction="row" className={classes.grid}>
        <Grid item xs={8} className={classes.gridItem}>
          <Textwithcount
            text={props.text}
            count={
              props.problem && props.problem.length < 1
                ? 0
                : props.problem.length < 10
                ? `0${props.problem.length}`
                : props.problem.length
            }
          />
        </Grid>
        <Grid item xs={4} className={classes.clearall}>
          {props.problem?.length > 0 && (
            <Button onClick={() => props.clear()} variant="text" size={"small"}>
              <Typography color="error" variant="subtitle2">
                CLEAR ALL
              </Typography>
            </Button>
          )}
        </Grid>
      </Grid>
      {props.problem?.slice(0)?.reverse()?.map((item, i) => {
        return (
          <Card key={i} className={classes.Card}>
            <CardHeader
              subheader={
                <Typography variant="body1" color={"textPrimary"}>
                  {item}
                </Typography>
              }
              action={
                <IconButton
                  onClick={() => props.delete(i)}
                  aria-label="settings"
                >
                  <ClearIcon />
                </IconButton>
              }
            />
          </Card>
        );
      })}
    </div>
  );
};
CustomTextadder.propTypes = {
  value: PropTypes.string,
  id: PropTypes.string,
  multiline: PropTypes.bool,
  onChange: PropTypes.func,
  type: PropTypes.string,
  isReadonly: PropTypes.bool,
};
CustomTextadder.defaultProps = {
  multiline: false,
  type: "text",
};
