import "date-fns";
import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardTimePicker } from "@material-ui/pickers";
import { makeStyles, Typography } from "@material-ui/core";
import ScheduleIcon from "@material-ui/icons/Schedule";

const useStyles = makeStyles((theme) => ({
  activityInputField: {
    marginBottom: "16px",
    width: "100%",
    "& .MuiIconButton-label": {
      color: "#1844577A",
    },
    "& .MuiInput-root": {
      padding: "8px 14px",
      borderRadius: 8,
      border: `1.5px solid ${theme.palette.border.light}`,
      "&:focus": {
        border: `2px solid ${theme.palette.primary.main}`,
        outline: "none",
      },
    },
    "& .MuiInput-root.Mui-focused ": {
      border: "1.5px solid #007965",
      borderRadius: 8,
    },
  },
}));

function TimePicker(props) {
  const handleDateChange = (i, value) => {
    props.onChange(i, value);
  };
  const classes = useStyles();
  return (
    <div>
      <Typography variant="body2" color="primary" align="left" gutterBottom>
        {`Activity ${props?.itemId}`}&nbsp;
        <Typography color="error" variant="caption">
          *
        </Typography>
      </Typography>

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardTimePicker
          placeholder="Select"
          value={props?.selectedTime}
          className={classes.activityInputField}
          onChange={(value) => handleDateChange(props?.itemId, value)}
          InputProps={{
            disableUnderline: true,
          }}
          keyboardIcon={<ScheduleIcon />}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
}

export default TimePicker;
