import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { Header, FooterButton } from "../../components";
import { Routes } from "../../router/routes";
import { useHistory } from "react-router-dom";
import { HabitInputs } from "./habitInputs";
import { config } from "../../config";
import { AlertProps, LocalStorageKeys, DrawerProps } from "../../utils";
import { CreateGoalAlert } from "./createGoalAlert";
import {
  AuthContext,
  FullScreenContext,
  AlertContext,
  DrawerContext,
} from "../../contexts";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    backgroundColor: theme.palette.background.paper,
  },
  header: {
    position: "fixed",
    [theme.breakpoints.up("md")]: {
      width: 768,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  root: {
    padding: theme.spacing(3),
    paddingTop: 80,
    paddingBottom: "95px",
  },
  footer: {
    bottom: 0,
    position: "fixed",
    [theme.breakpoints.up("md")]: {
      width: 768,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

export const AddHabit = (props) => {
  const habit = props.habit;
  const classes = useStyles();
  const history = useHistory();
  const auth = React.useContext(AuthContext);
  const drawerContext = React.useContext(DrawerContext);
  const fullScreenLoader = React.useContext(FullScreenContext);
  const alert = React.useContext(AlertContext);
  const [state, setState] = React.useState({
    custom_habit_name: habit?.name ? habit?.name : "",
    custom_habit_type: habit?.category ? habit?.category[0]?.value : "",
    habit_id: habit?.habit_id,
    is_custom_habit: habit ? false : true,
    start_date: "",
    end_date: "",
    activity_per_day: "",
    activities: [],
    selectedTime: [],
    remind_before: "dont_remind",
    dont_remind: true,
    motivation_message: "",
    attitude: "",
    subjective_norm: "",
    perceived_behavioral_norm: "",
    error: {
      custom_habit_name: false,
      custom_habit_type: false,
      start_date: false,
      end_date: false,
      activity_per_day: false,
      remind_before: false,
    },
  });

  const [activityInputs, setActivityInputs] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const changeActivity = (i, value) => {
    var Index = i - 1;
    state?.selectedTime.splice(Index, 1, value);
    setState({ ...state });
    state?.activities.splice(Index, 1, moment(value).format("HH:mm"));
    setState({ ...state });
  };

  const changeState = (key, value) => {
    if (value !== null) {
      state[key] = value;
      state.error[key] = false;
      setState({ ...state });
    }
    if (value === "dont_remind") {
      state["dont_remind"] = true;
      setState({ ...state });
    }
    if (value === "15 Mins" || value === "30 Mins") {
      state["dont_remind"] = false;
      state[key] = value;
      setState({ ...state });
    }
    if (key === "activity_per_day") {
      if (value?.length > 0) {
        let arr = [];
        for (let i = 1; i <= value; i++) {
          arr.push({ id: i, value: "" });
        }
        setActivityInputs(arr);
      } else {
        setActivityInputs([]);
      }
    }
  };

  const validation = () => {
    let namevalid = state?.custom_habit_name?.length > 0 ? true : false;
    let categoryvalid = state?.custom_habit_type?.length > 0 ? true : false;
    let startDatevalid = state?.start_date?.length > 0 ? true : false;
    let endDatevalid = state?.end_date?.length > 0 ? true : false;
    let remindervalid = state?.remind_before?.length > 0 ? true : false;
    let activity_per_dayvalid =
      state?.activity_per_day?.length > 0 ? true : false;
    if (
      namevalid &&
      categoryvalid &&
      startDatevalid &&
      endDatevalid &&
      activity_per_dayvalid &&
      remindervalid
    ) {
      return true;
    } else {
      state.error = {
        custom_habit_name: namevalid ? false : true,
        custom_habit_type: categoryvalid ? false : true,
        start_date: startDatevalid ? false : true,
        end_date: endDatevalid ? false : true,
        activity_per_day: activity_per_dayvalid ? false : true,
        remind_before: remindervalid ? false : true,
      };
      setState({ ...state });
    }
  };

  // Create New Habit
  const createNewHabit = () => {
    history.push(Routes.newHabit);
    drawerContext.setDrawer({
      ...drawerContext,
      open: false,
    });
  };

  // Cancel create new habit
  const goCancelCreation = () => {
    history.push(Routes.home);
    drawerContext.setDrawer({
      ...drawerContext,
      open: false,
    });
  };

  const saveForm = () => {
    const isValidated = validation();
    if (!isValidated) {
      return false;
    } else {
      hitAPI();
      drawerContext.setDrawer({
        ...drawerContext,
        open: true,
        direction: DrawerProps.direction.bottom,
        variant: DrawerProps.variant.temporary,
        component: (
          <CreateGoalAlert
            goBack={goCancelCreation}
            createNewHabit={createNewHabit}
          />
        ),
        onClose: () => onDrawerClosed(),
      });
    }
  };
  const onDrawerClosed = () => {};

  const hitAPI = async () => {
    setIsLoading(true);
    fullScreenLoader.setLoader({
      ...fullScreenLoader,
      open: true,
      message: "Saving Habit...",
    });

    let bodyData = {
      user_id: auth?.user?.profile?.id,
      custom_habit_name: state?.custom_habit_name,
      custom_habit_type: state?.custom_habit_type,
      is_custom_habit: state?.is_custom_habit,
      habit_id: state?.habit_id,
      start_date:
        moment(state?.start_date).format("YYYY-MM-DD") + "T00:00:00.000Z",
      end_date: moment(state?.end_date).format("YYYY-MM-DD") + "T00:00:00.000Z",
      activity_per_day: Number(state?.activity_per_day),
      activities: state?.activities,
      remind_before: state?.dont_remind ? "" : state?.remind_before,
      dont_remind: state?.dont_remind,
      motivation_message: state?.motivation_message ?? "",
      attitude: state?.attitude ?? "",
      subjective_norm: state?.subjective_norm ?? "",
      perceived_behaviour_norm: state?.perceived_behavioral_norm ?? "",
      time_zone: "Asia/Kolkata",
    };

    // Api call
    fetch(config.rest_api_service + "/habit/upsert", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      Authorization: `Bearer ${localStorage.getItem(
        LocalStorageKeys.authToken
      )}`,
      body: JSON.stringify(bodyData),
    })
      .then((response) => response.json())
      .then((res) => {
        // Stoping the Loaders
        setIsLoading(false);
        fullScreenLoader.setLoader({
          ...fullScreenLoader,
          open: false,
          message: "",
        });

        // Navigating to Confirmation page
        if (res?.type === "Success") {
          history.push(Routes.goalTracker);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            msg: "Habit Activty Added",
          });
        } else {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            msg: res?.message ?? "Something went wrong, Try Again!",
          });
        }
      })
      .catch((error) => {
        console.log("Error while saving Habit: ", error);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          msg: "Something went wrong, Try Again!",
        });
      });
  };

  const goBack = () => {
    history.goBack();
  };

  const goHome = () => {
    history.push(Routes.home);
  };

  const goCancel = () => {
    history.push(Routes.newHabit);
  };

  return (
    <div className={classes.rootContainer}>
      <div className={classes.header} style={{ zIndex: isLoading ? "" : 1000 }}>
        <Header
          showBackIcon
          showHomeIcon
          handleBack={goBack}
          handleHome={goHome}
        />
      </div>
      <div className={classes.root} noValidate>
        <Typography
          variant="body1"
          color="textPrimary"
          style={{ marginBottom: "16px" }}
        >
          Create Goal
        </Typography>
        <HabitInputs
          {...state}
          changeState={changeState}
          activityInputs={activityInputs}
          habitCategory={habit?.category}
          habitName={habit?.name}
          changeActivity={changeActivity}
        />
      </div>
      <div className={classes.footer}>
        <FooterButton
          firstButton="CANCEL"
          handleClick={goCancel}
          secondButton="SAVE & COMPLETE"
          onClick={saveForm}
        />
      </div>
    </div>
  );
};
