import React from "react";
import {
  Typography,
  Grid,
  Avatar,
  Fab,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FooterButton } from "../footerButton";

const useStyles = makeStyles((theme) => ({
  slotCard: {
    border: `1px solid ${theme.palette.border.light}`,
    borderRadius: "24px 24px 0px 0px",
    backgroundColor: `${theme.palette.common.white}`,
    boxShadow: "0px -10px 10px #0000000A",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
  },
  eventDialogDelete: {
    // position: "relative",
    "& .MuiCircularProgress-root": {
      position: "relative",
    },
    "& .MuiFab-label": {},
  },
  fab: {
    marginTop: theme.spacing(4),
    width: "100px",
    height: "100px",
  },
  large: {
    width: "100px",
    height: "100px",
  },
  loader: {
    marginTop: theme.spacing(12),
    marginBottom: theme.spacing(12),
  },
}));

export const GetHelp = ({
  doctorData = [],
  isLoading = false,
  goCancel = () => false,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.slotCard}>
      <div style={{ marginTop: "24px" }}>
        <Typography variant="subtitle1" color="textPrimary">
          Stay calm! We'll connect you with a doctor.
        </Typography>
      </div>
      {isLoading ? (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      ) : doctorData?.length > 0 ? (
        <Grid>
          <Grid item>
            <div className={classes.eventDialogDelete}>
              <Fab aria-label="delete" className={classes.fab}>
                <Avatar
                  alt="pic"
                  src={doctorData[0]?.profilePic}
                  className={classes.large}
                />
              </Fab>
            </div>
          </Grid>
          <Grid item style={{ marginTop: "16px", marginBottom: "40px" }}>
            <Typography variant="body1" color="textPrimary" gutterBottom>
              {doctorData[0]?.name}
            </Typography>
            <Typography variant="body2" color="textPrimary" gutterBottom>
              {doctorData[0]?.mobile}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.loader}
        >
          <Grid item>
            <img src="/images/Group 5135.svg" alt="No" />
          </Grid>
          <Grid item>
            <Typography variant="body2" color="textPrimary">
              {"Sorry! No Doctor available at the moment"}
            </Typography>
          </Grid>
        </Grid>
      )}

      <FooterButton
        firstButton="Cancel"
        handleClick={() => goCancel()}
        callButton="Call Now"
        phoneNo={doctorData[0]?.mobile}
      />
    </div>
  );
};
