import React from "react";
import { Typography, makeStyles, Grid } from "@material-ui/core";
import { ToggleButtonComponent } from "../toggleButton";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  subLabel: {
    textTransform: "uppercase",
  },
}));
const TabSelect = (props) => {
  const classes = useStyles();
  let toggleButtonArray = [];

  props.items &&
    props.items.map((item) => {
      if (item.value) {
        if (item.label) {
          return toggleButtonArray.push(item);
        }
      } else {
        return toggleButtonArray.push({
          value: item,
          label: item,
        });
      }
      return item;
    });

  return (
    <div style={{ marginBottom: "8px" }}>
      <div style={{ display: "flex" }}>
        {props.label && (
          <Typography variant="body2" color="primary" align="left" gutterBottom>
            {props.label}
          </Typography>
        )}
        {props.subLabel && (
          <Typography
            variant="subtitle1"
            className={classes.subLabel}
            color="textSecondary"
            align="left"
            gutterBottom
          >
            {props.subLabel}
          </Typography>
        )}
        {props.isrequired && (
          <Typography variant="caption" style={{ color: "red" }}>
            *
          </Typography>
        )}
      </div>
      <div>
        {props.quicksugLoading ? (
          <Grid container spacing={1}>
            {[...Array(4)].map((e, i) => {
              return (
                <Grid item key={i}>
                  <Skeleton width={"61px"} height={"60px"}/>
                </Grid>
              );
            })}
          </Grid>
        ) : toggleButtonArray?.length > 0 && props.multiple ? (
          //multiple select
          <ToggleButtonComponent
            disabled={props.isReadyOnly || props?.disabled}
            onSelect={props.onClick}
            toggleButtonData={toggleButtonArray}
            isSelected={props.value}
            labelColor={props.labelColor}
          />
        ) : (
          //single select
          <ToggleButtonComponent
            showTimeIcon={props.showTimeIcon}
            labelColor={props.labelColor}
            disabled={props.isReadyOnly || props?.disabled}
            error={props.error}
            helperText={props.helperText}
            onSelect={props.onClick}
            toggleButtonData={toggleButtonArray}
            isSelected={props.toggleValue}
            fieldValue={props.value}
            onChange={props.onChange}
            dobInput={props.dobInput}
            exclusive
          />
        )}
      </div>
    </div>
  );
};

export default TabSelect;
