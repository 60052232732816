import React from "react";
import { makeStyles, Drawer } from "@material-ui/core";
import { Routes } from "../../router/routes";
import { NetWorkCallMethods } from "../../utils";
import { useHistory } from "react-router-dom";
import { EmergencyHelper, GetHelp } from "../../components";
import moment from "moment";
import momentTimeZone from "moment-timezone";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";

const useStyles = makeStyles((theme) => ({
  MuiDrawer: {
    borderRadius: "24px 24px 0px 0px",
  },
}));

export const HomeEmergencyHelper = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [doctorData, setDoctorData] = React.useState([]);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const goCancel = () => {
    setDrawerOpen(false);
    history.push(Routes.home);
  };

  const getDoctorsForAvailability = async () => {
    // Doctor availability api call
    setIsLoading(true);
    setDrawerOpen(true);
    let startDateTime = moment.utc();
    let endDateTime = moment.utc(moment().endOf('day'));
    let isAuthorized = true,
      headers,
      body = {
        dates: [
          startDateTime.format('YYYY-MM-DD').toString(),
          endDateTime.format('YYYY-MM-DD').toString()
        ],
        startDateTime: startDateTime.toISOString(),
        endDateTime: endDateTime.toISOString(),
        startTime: startDateTime.format('HH:mm:ss').toString(),
        endTime: endDateTime.format('HH:mm:ss').toString(),
        clientTimeZone: momentTimeZone.tz.guess(),
        clientTimeZoneOffset: new Date().getTimezoneOffset(),
      };

    await NetworkCall(
      config.rest_api_service + "/appointment/doctors/availability",
      NetWorkCallMethods.post,
      body,
      headers,
      isAuthorized
    ).then((res) => {
      setIsLoading(false);
      setDoctorData(res?.data?.data);
    });
  };

  return (
    <div className={classes.root}>
      <EmergencyHelper handleClick={getDoctorsForAvailability} />
      <Drawer
        classes={{ paper: classes.MuiDrawer }}
        anchor="bottom"
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false);
        }}
      >
        <GetHelp
          doctorData={doctorData}
          goCancel={goCancel}
          isLoading={isLoading}
        />
      </Drawer>
    </div>
  );
};
