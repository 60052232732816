import React from "react";
import {
  makeStyles,
  CardContent,
  Typography,
  Grid,
  Button,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "8px",
    boxShadow: "none",
    backgroundColor: (props) => props?.backgroundColor,
    padding: 5,
    marginBottom: "24px",
    cursor: "pointer"
  },
  content: {
    "&:last-child": {
      paddingBottom: "16px",
    },
  },
  chipcontent: {
    "&:last-child": {
      paddingBottom: "16px",
    },
  },
  BannerButton: {
    marginTop: "15px",
    "& .MuiButton-root": {
      backgroundColor: theme.palette.text.primary,
    },
    "& .MuiButton-label": {
      color: theme.palette.common.white,
      textTransform: "capitalize",
    },
  },
}));
export const BannerCard = (props) => {
  const classes = useStyles(props);

  return (
    <Card className={classes.root} onClick={props.onClick}>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item md={3} sm={3} xs={5}>
          <CardContent className={classes.content}>
            <img src={props.bannerImage} alt="BannerImage" />
          </CardContent>
        </Grid>
        <Grid item md={9} sm={9} xs={7}>
          <CardContent className={classes.chipcontent}>
            <div>
              <Typography variant="body1" color={"textPrimary"}>
                {props.bannerText}
              </Typography>
              <div className={classes.BannerButton}>
                <Button variant="outlined" size={"small"}  onClick={props.onClick}>{props.buttonText}</Button>
              </div>
            </div>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
};

