import React from "react";
import { LinearProgress } from "@material-ui/core";
import { NetworkCall } from "./networkcall";
import {
  LocalStorageKeys,
  NetWorkCallMethods,
  refreshCacheAndReload,
  semverGreaterThan,
} from "./utils";
import { config } from "./config";
import { AuthContext } from "./contexts";

class AppAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      user: {},
    };
  }

  componentDidMount() {
    this.checkForLatestBuild();
    this.refreshAPI();
  }

  refreshAPI = async () => {
    let oldToken = localStorage.getItem(LocalStorageKeys.authToken);

    if (!oldToken) {
      this.setState({ loading: false });
      return false;
    }

    try {
      const response = await NetworkCall(
        config.auth_service + "/refresh",
        NetWorkCallMethods.post,
        {},
        null,
        true
      );
      if (response?.data?.data?.auth_token) {
        localStorage.setItem(
          LocalStorageKeys.authToken,
          response?.data?.data?.auth_token
        );
      }
      this.setState({ loading: false, user: response?.data?.data });

    } catch (err) {
      console.log("Error while getting Refresh :", err);
      localStorage.removeItem(LocalStorageKeys.authToken);
      this.setState({ loading: false });
    }
  };

  checkForLatestBuild = () => {
    //Getting the latest meta.json
    NetworkCall(
      `${window.location.protocol}//${window.location.hostname}${window.location.port ? ":" + window.location.port : ""
      }/meta.json`,
      NetWorkCallMethods.get,
      null,
      null,
      false,
      true
    )
      .then((_) => {
        // Checking Version Updation
        const isVersion = semverGreaterThan(
          _.data.version,
          localStorage.getItem(LocalStorageKeys.version)
        );

        // Saving the version in local storage
        localStorage.setItem(LocalStorageKeys.version, _.data.version);

        // Refresh Cache and Reload if version is outdated
        if (isVersion) {
          refreshCacheAndReload();
        }
      })
      .catch((err) => {
        console.log("Error while checking Version:", err);
      });
  };

  set = (props) => {
    this.setState({ ...props });
  };

  render() {
    let { loading } = this.state;

    return (
      <AuthContext.Provider
        value={{
          ...this.state,
          setAuth: this.set,
          reload: this.refreshAPI,
        }}
      >
        {loading ? <LinearProgress /> : this.props.children}
      </AuthContext.Provider>
    );
  }
}

export default AppAuth;
