import React from "react";
import { withRouter, Redirect } from "react-router-dom";
import { withNavBars } from "../../HOCs";
import { FindDoctor } from "./findDoctor";
import { NetworkCall } from "../../networkcall";
import { NetWorkCallMethods, initBeforeUnLoad } from "../../utils";
import { config } from "../../config";
import { AppointmentContext } from "../../contexts";
import moment from "moment";
import momentTimeZone from "moment-timezone";

const availabilityFilter = [
  {
    value: 'today',
    label: "Today"
  },
  {
    value: "tomorrow",
    label: "Tomorrow"
  },
  {
    value: "next_7_days",
    label: "Next 7 Days"
  },
  {
    value: "custom",
    label: "Custom"
  }
]
class FindDoctorParent extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      readyToRedirect: false,
      search: ""
    };
  }

  searchDoctor = (newData) => {
    this.setState({search: newData})
    this.getDoctorsForAvailability();
   
  };

  setDateFilter = (newData, customDate = null) => {
    this.setState({
      dateFilter: newData,
      customDate: customDate
    })
    this.context.dateFilter = newData;
    this.context.customDate = customDate;

    if (newData === 'custom' && customDate) {
      this.getDoctorsForAvailability();
    } else if (newData !== 'custom') {
      this.getDoctorsForAvailability();
    }
  }

  static contextType = AppointmentContext;

  async componentDidMount() {
    // check for booking status
    const appointmentContext = this.context;

    if (appointmentContext.is_self_booking === null) {
      this.setState({
        readyToRedirect: true,
      });
    }

    initBeforeUnLoad();
    this.getDoctorsForAvailability();
  }

  giveMeAvailabilityAPI = () => {

    let startDateTime = moment.utc();
    let endDateTime = moment.utc(moment().endOf('day'));

    switch (this.context.dateFilter) {

      case 'today':
        return {
          dates: [
            startDateTime.format('YYYY-MM-DD').toString(),
            endDateTime.format('YYYY-MM-DD').toString()
          ],
          startDateTime: startDateTime.toISOString(),
          endDateTime: endDateTime.toISOString(),
          startTime: startDateTime.format('HH:mm:ss').toString(),
          endTime: endDateTime.format('HH:mm:ss').toString(),
        };

      case 'tomorrow':
        startDateTime = moment.utc(moment().add(1, 'day').startOf('day'));
        endDateTime = moment.utc(moment().add(1, 'day').endOf('day'));

        return {
          dates: [
            startDateTime.format('YYYY-MM-DD').toString(),
            endDateTime.format('YYYY-MM-DD').toString()
          ],
          startDateTime: startDateTime.toISOString(),
          endDateTime: endDateTime.toISOString(),
          startTime: startDateTime.format('HH:mm:ss').toString(),
          endTime: endDateTime.format('HH:mm:ss').toString(),
        };

      case 'next_7_days':

        startDateTime = moment.utc(moment().add(1, 'day').startOf('day'));
        endDateTime = moment.utc(moment().add(7, 'day').endOf('day'));

        return {
          dates: [
            startDateTime.format('YYYY-MM-DD').toString(),
            moment.utc(moment().add(1, 'day').endOf('day')).format('YYYY-MM-DD').toString(),
            moment.utc(moment().add(2, 'day').startOf('day')).format('YYYY-MM-DD').toString(),
            moment.utc(moment().add(2, 'day').endOf('day')).format('YYYY-MM-DD').toString(),
            moment.utc(moment().add(3, 'day').startOf('day')).format('YYYY-MM-DD').toString(),
            moment.utc(moment().add(3, 'day').endOf('day')).format('YYYY-MM-DD').toString(),
            moment.utc(moment().add(4, 'day').startOf('day')).format('YYYY-MM-DD').toString(),
            moment.utc(moment().add(4, 'day').endOf('day')).format('YYYY-MM-DD').toString(),
            moment.utc(moment().add(5, 'day').startOf('day')).format('YYYY-MM-DD').toString(),
            moment.utc(moment().add(5, 'day').endOf('day')).format('YYYY-MM-DD').toString(),
            moment.utc(moment().add(6, 'day').startOf('day')).format('YYYY-MM-DD').toString(),
            moment.utc(moment().add(6, 'day').endOf('day')).format('YYYY-MM-DD').toString(),
            moment.utc(moment().add(7, 'day').startOf('day')).format('YYYY-MM-DD').toString(),
            endDateTime.format('YYYY-MM-DD').toString()
          ],
          startDateTime: startDateTime.toISOString(),
          endDateTime: endDateTime.toISOString(),
          startTime: startDateTime.format('HH:mm:ss').toString(),
          endTime: endDateTime.format('HH:mm:ss').toString(),
        };

      case 'custom':

        startDateTime = moment.utc(moment(this.context.customDate).startOf('day'));
        endDateTime = moment.utc(moment(this.context.customDate).endOf('day'));

        return {
          dates: [
            startDateTime.format('YYYY-MM-DD').toString(),
            endDateTime.format('YYYY-MM-DD').toString()
          ],
          startDateTime: startDateTime.toISOString(),
          endDateTime: endDateTime.toISOString(),
          startTime: startDateTime.format('HH:mm:ss').toString(),
          endTime: endDateTime.format('HH:mm:ss').toString(),
        };

      default:
        return {
          dates: [
            startDateTime.format('YYYY-MM-DD').toString(),
            endDateTime.format('YYYY-MM-DD').toString()
          ],
          startDateTime: startDateTime.toISOString(),
          endDateTime: endDateTime.toISOString(),
          startTime: startDateTime.format('HH:mm:ss').toString(),
          endTime: endDateTime.format('HH:mm:ss').toString(),
        };
    };
  }

  getDoctorsForAvailability = async () => {
    // Doctor availability api call
    this.setState({ isLoading: true });

    let isAuthorized = true,
      headers,
      body = {
        ...this.giveMeAvailabilityAPI(),
        clientTimeZone: momentTimeZone.tz.guess(),
        clientTimeZoneOffset: new Date().getTimezoneOffset(),
        search: this.state?.search
      };

    await NetworkCall(
      config.rest_api_service + "/appointment/doctors/availability",
      NetWorkCallMethods.post,
      body,
      headers,
      isAuthorized
    ).then((res) => {
      this.context.data = res?.data?.data;
      this.setState({
        isLoading: false,
        data: res?.data?.data ?? []
      });
    });
  }

  render() {
    if (this.state.readyToRedirect) return <Redirect to="/select_patient" />;
    return (
      <FindDoctor
        doctorList={this.context.data}
        isLoading={this.state.isLoading}
        availabilityFilter={availabilityFilter}
        dateFilter={this.context.dateFilter}
        customDate={this.context.customDate}
        setDateFilter={this.setDateFilter}
        search={this.state.search}
        handleChange={this.searchDoctor}
      />
    );
  }
}

export default withRouter(withNavBars(FindDoctorParent));
