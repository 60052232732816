import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    padding: "0px 8px",
  },
  appBar: {
    backgroundColor: theme.palette.background.paper,
    color: "black",
    borderBottom: `1px solid ${theme.palette.border.light}`,
    boxShadow: "none",
    "& .MuiToolbar-gutters": {
      padding: "0px",
    },
  },
  titleContainer: {
    padding: "12px",
    paddingTop: "15px"
  },
}));

export const Header = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.grow}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <div>
            {props.profileText && (
              <Typography variant={"body1"} className={classes.titleContainer}>
                {props.profileText}
              </Typography>
            )}
            {props.showBackIcon && (
              <div style={{display: "flex" }}>
                <IconButton
                  aria-label="back button"
                  aria-controls={"back_button"}
                  aria-haspopup="true"
                  onClick={props.handleBack}
                  color="inherit"
                >
                  <img src="/images/back.svg" alt="back" />
                </IconButton>
                <Typography variant="body1" color="textPrimary" className={classes.titleContainer}>
                  {props.secondaryText}
                </Typography>
              </div>
            )}
          </div>
          <div className={classes.grow} />
          {props.showPersonIcon && (
            <img
              src="/images/icons8-customer.svg"
              alt="customer"
              className={classes.titleContainer}
            />
          )}
          {props.showHomeIcon && (
            <IconButton
              aria-label="logout button"
              aria-controls={"logout_button"}
              aria-haspopup="true"
              onClick={props.handleHome}
              color="inherit"
              style={{ marginRight: "5px" }}
            >
              <img src="/images/icons8-home.svg" alt="home" />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};
Header.propTypes = {
  profileText: PropTypes.string,
};
