import React from "react";
import { Typography, makeStyles, Button, Grid, Link } from "@material-ui/core";
import { MobileNumber } from "../../components";
import { sendOTPForLogin } from "../../utils";
import { FullScreenContext } from "../../contexts";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  gridContainer: {
    // height: "100%",
    width: 290,
  },
  mobileNumber: {
    marginTop: 32,
  },
  sendOTPBtn: {
    marginTop: 16,
    width: "100%",
  },
  termsAndCondition: {
    marginTop: 16,
    width: "100%",
  },
  imageParent: {
    textAlign: "center",
  },
}));

export const Login = (props) => {
  const classes = useStyles(props);
  const fullScreenLoader = React.useContext(FullScreenContext);
  const history = useHistory();

  const [state, setState] = React.useState({
    mobile: {
      mobile: "",
      mobile_code: "+91",
    },
    error: false,
    error_message: "",
  });

  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

const enterKeyPress = (event) => {
  if(event.which === 13 || event.keyCode === 13) // for crossbrowser
  {
    event.preventDefault(); // this code prevents other buttons trigger
    sendOTP();
  }
}

  const sendOTP = async () => {
    let requestBody = {
      mobile_no: state.mobile.mobile,
      mobile_no_country_code: state.mobile.mobile_code,
    };

    fullScreenLoader.setLoader({
      ...fullScreenLoader,
      open: true,
      message: "Sending OTP",
    });

    await sendOTPForLogin(requestBody)
      .then((res) => {
        if (parseInt(res.data?.statusCode ?? 0) === 200) {
          history.push({
            pathname: Routes.otpVerifier,
            state: { ...state?.mobile },
          });
        } else {
          changeState("error_message", res.data.message);
        }
      })
      .catch((err) => {
        console.log("Error While Loggin In:", err);
        changeState("error_message", "Something Went Wrong, Try Again Later!");
      });

    fullScreenLoader.setLoader({
      ...fullScreenLoader,
      open: false,
      message: "",
    });
  };

  return (
    <div className={classes.root} noValidate>
      <Grid className={classes.gridContainer}>
        <Grid item container justify={"center"} alignItems={"center"}>
          <Grid item xs={12} className={classes.imageParent}>
            <img src="/images/icons8-login.svg" alt="login" />
          </Grid>

          <Grid item>
            <Typography variant="h6" color={"textPrimary"}>
              Get Started!
            </Typography>
          </Grid>

          <Grid item>
            <Typography
              variant={"subtitle1"}
              color={"textSecondary"}
              gutterBottom
            >
              Please provide your mobile number
            </Typography>
          </Grid>

          <Grid item className={classes.mobileNumber}>
            <MobileNumber
              type={"number"}
              label={"Mobile Number"}
              value={state?.mobile}
              autoFocus
              onErrorOccured={(error) => changeState("error", error)}
              isRequired={true}
              handleChange={(value) => changeState("mobile", value)}
              errorValidation={{
                error: state?.error_message,
                errorMessage: state?.error_message,
              }}
              enterKeyPress={enterKeyPress}
            />
          </Grid>

          <Grid item className={classes.sendOTPBtn}>
            <Button
              variant={"contained"}
              color={"primary"}
              fullWidth
              disabled={state.error}
              onClick={sendOTP}
              style={{ borderRadius: "8px" }}
            >
              {"Send OTP"}
            </Button>
          </Grid>

          <Grid item className={classes.termsAndCondition}>
            <Typography variant={"body2"} align={"center"}>
              {"By continuing you agree to the "}
              <Link style={{ fontFamily: "healthcircles_medium"}}>{"Terms & Conditions & "}</Link>
              <Link style={{ fontFamily: "healthcircles_medium"}}>{" Privacy Policy"}</Link>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
