import React from "react";
import {
  makeStyles,
  List as MUList,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  muList: {
    "& .MuiListItem-gutters": {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  divider: {
    borderTop: `1px dotted ${theme.palette.border.light}`,
    width: "90%",
    margin: "auto",
  },
}));

export const ListWithCheckbox = (props) => {
  const classes = useStyles();
  const changeLabel = (data) => {
    props.onChange(data);
  };
  return (
    <MUList dense className={classes.muList}>
      {props?.data &&
        props?.data.map((_, i) => {
          return (
            <>
              <ListItem
                key={_.value}
                dense
                button
                onClick={() => {
                  changeLabel(_);
                }}
              >
                <ListItemText primary={_.label} />
                {props?.selectedValue?.filter((v) => v === _.value).length >
                0 ? (
                  <ListItemIcon
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <img src="/images/icons8-ok.svg" alt="tick" />
                  </ListItemIcon>
                ) : (
                  <ListItemIcon
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div
                      style={{
                        width: "16px",
                        height: "16px",
                        border: "1px solid #E2E2E2",
                        borderRadius: "50%",
                      }}
                    ></div>
                  </ListItemIcon>
                )}
              </ListItem>
              <Divider className={classes.divider} />
            </>
          );
        })}
    </MUList>
  );
};
