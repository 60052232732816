import React from "react";
import { withRouter } from "react-router-dom";
import { withNavBars } from "../../HOCs";
import { AppoinmentSummaryView } from "./appointmentSummaryView";

class AppoinmentSummaryViewParent extends React.Component {
  render() {
    return <AppoinmentSummaryView appointmentId={this.props.match.params}/>;
  }
}

export default withRouter(withNavBars(AppoinmentSummaryViewParent));
