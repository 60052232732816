export const Routes = {
  home: "/",
  login: "/login",
  otpVerifier: "/otp_verify",
  componentList: "/component_list",
  completeProfile: "/complete_profile",
  selectPatient: "/select_patient",
  consultationReason: "/consultation_reason",
  addHealthIssues: "/add_health_issues",
  appointments: "/appointments",
  caseSummary: "/case_summary",
  symptoms: "/symptoms",
  healthProblems: "/health_problems",
  allergies: "/allergies",
  medications: "/medications",
  findDoctor: "/find_doctor",
  uploadReports: "/upload_reports",
  vitals: "/vitals",
  appointmentSummary: "/appointment_summary",
  appointmentConfirmation: "/appointment_confirmation",
  patientDetails: "/patient_details",
  profileDetails: "/profile_details",
  appointmentSummaryViewParent: "/appointment_summary/view/",
  appointmentSummaryView: "/appointment_summary/view/:id",
  goalTracker: "/goal_tracker",
  newHabit: "/new_habit",
  addHabit: "/add_habit",
  activityUpdateParent: "/activity_update",
  activityUpdate: "/activity_update/:activityId",
  weeklyStats: "/weekly_stats"
};
