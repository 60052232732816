import React from "react";
import { makeStyles, Typography, Grid } from "@material-ui/core";
import { Header, Textwithbutton, CalendarRoller } from "../../components";
import { Routes } from "../../router/routes";
import { useHistory } from "react-router-dom";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { NetWorkCallMethods, AlertProps } from "../../utils";
import { AuthContext, AlertContext } from "../../contexts";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    backgroundColor: theme.palette.background.paper,
    height: "inherit"
  },
  header: {
    position: "fixed",
    zIndex: 1000,
    [theme.breakpoints.up("md")]: {
      width: 768,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  root: {
    padding: theme.spacing(2),
    paddingTop: 80,
  },
  statusContainer:{
    padding: "16px 0px",
    borderBottom: `2px dashed ${theme.palette.border.light}`,
  },
  activityStatus:{
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    padding: "10px",
    paddingTop: "12px",
    marginRight: "3%",
    background: "#F5F5F5",
    borderRadius: "8px",
    minWidth: "35px",
    minHeight: "35px",
  },
  footer:{
    bottom: 0,
    position: "fixed",
    [theme.breakpoints.up("md")]: {
      width: 768,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    padding: 7,
    borderTop: "2px dashed #00000014",
    boxShadow: "0px -5px 10px #00000014",
    background: theme.palette.background.paper,
  }
}));

export const WeeklyStats = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const auth = React.useContext(AuthContext);
  const alert = React.useContext(AlertContext);
  const [week, setWeek] = React.useState([])
  const [stats, setStats] = React.useState([]);
  const [bestDay, setBestDay] = React.useState([]);
  const [overall, setOverall] = React.useState({});
  const[date, setDate] = React.useState("");
  const goBack = () => {
    history.goBack();
  };

  const goHome = () => {
    history.push(Routes.home);
  };

  // Get Current Week
  const getCurrentWeek = () => {
    var currentDate = moment();
    var days = [];
    let startDate =  currentDate.clone().startOf('isoWeek')
   setDate(startDate)
    for (var i = 0; i <= 6; i++) {
      days.push({
        date: moment(startDate).add(i, "days").format("YYYY-MM-DD"),
        day_of_month: moment(startDate).add(i, "days").format("DD"),
        day_of_week: moment(startDate).add(i, "days").format("ddd"),
      });
    }
    setWeek(days)
    apiCall(days[0]?.date, days[6]?.date);
  }

    // Get Future Week
  const getNextWeek = () => {
    var startDate = moment(date).add(7, 'days');
    setDate(startDate)
    var days = [];
    for (var i = 0; i <= 6; i++) {
      days.push({
        date: moment(startDate).add(i, "days").format("YYYY-MM-DD"),
        day_of_month: moment(startDate).add(i, "days").format("DD"),
        day_of_week: moment(startDate).add(i, "days").format("ddd"),
      });
    }
    setWeek(days)
    apiCall(days[0]?.date, days[6]?.date);
  }

  // Get Past Week
  const getPreviousWeek = () => {
    var startDate = moment(date).add(-7, 'days');
    setDate(startDate)
    var days = [];
    for (var i = 0; i <= 6; i++) {
      days.push({
        date: moment(startDate).add(i, "days").format("YYYY-MM-DD"),
        day_of_month: moment(startDate).add(i, "days").format("DD"),
        day_of_week: moment(startDate).add(i, "days").format("ddd"),
      });
    }
    setWeek(days)
    apiCall(days[0]?.date, days[6]?.date);
  }

  const apiCall = async (startDate, endDate) => {
    try{
      let isAuthorized = true,
      headers,
      body = {
        user_id: auth?.user?.profile?.id,
        from_date: moment(startDate).format('YYYY-MM-DD') + "T00:00:00.000Z",
        to_date: moment(endDate).format('YYYY-MM-DD') + "T00:00:00.000Z"
      }
      const res = await NetworkCall(
        config.rest_api_service + "/habit/stats",
        NetWorkCallMethods.post,
        body,
        headers,
        isAuthorized
      );
     
      if (parseInt(res?.status) === 200) {
        setStats(res?.data?.data?.activities)
        setBestDay(res?.data?.data?.bestDays)
        setOverall({
          bestDayCount: res?.data?.data?.bestDaysCount,
          met:res?.data?.data?.met,
          total: res?.data?.data?.total,
          totalDone: res?.data?.data?.totalDone
        })
      } else {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          msg: "Something went wrong, Try Again!",
        });
      }
    } catch (error) {
    console.log("Error while saving Habit: ", error);
    alert.setSnack({
      ...alert,
      open: true,
      severity: AlertProps.severity.error,
      vertical: AlertProps.vertical.top,
      horizontal: AlertProps.horizontal.center,
      msg: "Something went wrong, Try Again!",
    });
  }
  }

  React.useEffect(() => {
    getCurrentWeek();
 
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.rootContainer}>
      <div className={classes.header}>
        <Header
          showBackIcon
          showHomeIcon
          handleBack={goBack}
          handleHome={goHome}
        />
      </div>
      <div className={classes.root} noValidate>
        <Textwithbutton
          actions={
            <div style={{ display: "flex" }}>
              <img src="/images/icons8-calendar (1).svg" alt="No" style={{ paddingRight: "8px" }}/>
              <Typography variant="subtitle1" color="textPrimary">
                {moment(week[0]?.date).format("MMM, YYYY") ===
                moment(week[6]?.date).format("MMM, YYYY")
                  ? `${moment(week[0]?.date).format("MMM, YYYY")}`
                  : `${moment(week[0]?.date).format("MMM, YYYY")} - ${moment(
                      week[6]?.date
                    ).format("MMM, YYYY")}`}
              </Typography>
            </div>
          }
          cardText={<Typography variant="body1" color="textPrimary">Weekly Stats</Typography>}
        />
      </div>
        {/*  week  */}
        <CalendarRoller
          date={week}
          handleScrollNext={getNextWeek}
          handleScrollPrevious={getPreviousWeek}
          noBorder
        />
      <div style={{padding: "16px", paddingBottom: "95px"}}>
        {/* Activity  Status */}
        <Grid container direction="row" className={classes.statusContainer}>
          <Grid item xs={4} style={{display: "flex", justifyContent: "center"}}>
            <img src="/images/icons8-ok.svg" alt="tick" width= "14px"/>
            <Typography variant="subtitle1" color="textSecondary" style={{marginLeft: "8px"}}>Completed</Typography>
          </Grid>
          <Grid item xs={4} style={{display: "flex", justifyContent: "center"}}>
            <img src="/images/partial.svg" alt="tick" />
            <Typography variant="subtitle1" color="textSecondary" style={{marginLeft: "8px"}}>Partial</Typography>
          </Grid>
          <Grid item xs={4} style={{display: "flex", justifyContent: "center"}}>
            <img src="/images/cross.svg" alt="tick" />
            <Typography variant="subtitle1" color="textSecondary" style={{marginLeft: "8px"}}>Incomplete</Typography>
          </Grid>
        </Grid>
          {stats?.map((item, i) => {
            return(
            <Grid container direction="row" style={{marginTop: "24px", marginBottom: "24px"}} key={i}>
              <Grid item xs={12}>
                <Typography variant="body1" color="primary">
                  {item?.activityName}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{display: "flex", justifyContent: "center", marginTop: "8px"}} >
                {item?.status?.map((ele, i) => {
                return (
                  <span className={classes.activityStatus} key={i}> 
                  {ele?.completed_status === "Completed" ? <img src="/images/icons8-ok.svg" alt="tick" width= "14px"/> :
                  ele?.completed_status === "Partial" ?<img src="/images/partial.svg" alt="no" width="16px" height="16px"/> : 
                  ele?.completed_status === "Incomplete" ? <img src="/images/cross.svg" alt="tick" /> : ""}
                  </span>
                )})}
              </Grid>
            </Grid>
          )})}

          {bestDay?.length > 0 && 
          <Grid container direction="row" style={{marginTop: "24px", marginBottom: "24px"}}>
            <Grid item xs={12}>
              <Typography variant="body1" color="primary">
                Best Day (Overall)
              </Typography>
            </Grid>
            <Grid item xs={12} style={{display: "flex", justifyContent: "center", marginTop: "8px"}} >
              {bestDay?.map((ele, i) => {
                return (
                <span className={classes.activityStatus} key={i}> 
                  {ele?.isBestDay ? <img src="/images/bestday.svg" alt="tick" width= "14px"/> : ""}
                </span>
              )})}
            </Grid>
          </Grid>}
      </div>
      <div className={classes.footer}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={{padding: "16px 0px"}}
        >
          <Grid item xs={4} style={{ textAlign: "center"}}>
            <Typography variant="h5" color="primary">{overall?.met ? `${overall?.met}%` : "0%"}</Typography>
            <Typography variant="body2" color="textSecondary">Met</Typography>
          </Grid>
          <Grid item xs={4} style={{textAlign: "center"}}>
            <Typography variant="h5" color="primary">
              {overall?.bestDayCount && overall?.bestDayCount < 1 ? 0 : overall?.bestDayCount < 10 ? `0${overall?.bestDayCount}` : overall?.bestDayCount}
            </Typography>
            <Typography variant="body2" color="textSecondary">Best Day</Typography>
          </Grid>
          <Grid item xs={4} style={{textAlign: "center"}}>
            <Typography variant="h5" color="primary">
              {overall?.totalDone ? overall?.totalDone < 1 ? 0 : `${overall?.totalDone}` < 10 ? `0${overall?.totalDone}/${overall?.total}` : `${overall?.totalDone}/${overall?.total}` : ""}
            </Typography>
            <Typography variant="body2" color="textSecondary">Total Done</Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
