import React from "react";
import { makeStyles, Paper, Typography } from "@material-ui/core";
import {
  MobileNumber,
  OtpLoader,
  EnterOtp,
  Header,
  Input,
  TabSelect,
  HealthCircle,
  BannerCard,
  Textwithcount,
  FooterButton,
  EmergencyHelper,
  Actioncard,
  Togglecard,
  Carouselcard,
  SelectBox,
  Cardsection,
  SearchFilter,
  TimeSlotToggle,
  MultiSelectDropdown,
  CustomTextadder,
  CustomizeTabs,
  CalendarRoller,
  Cardheader,
  Carouselcomponentcard,
  CardDateTime,
  AppointmentListCard,
} from "../../components";
import { genderData, dobAgeData } from "../../utils/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "3%",
    padding: "2%",
  },
  mainContent: {
    height: "100vh",
    background: theme.palette.common.white,
    overflow: "auto",
    width: 375,
  },
  completed: {
    color: "green",
  },
  notCompleted: {
    color: "red",
  },
}));

export const ComponentList = (props) => {
  const [btnDisabled] = React.useState(true);
  const [state, setState] = React.useState({});
  const changeState = (key, value) => {
    state[key] = value;
    setState({
      ...state,
    });
  };
  const classes = useStyles();

const timeSlotData = [
    {
      value: "09:00 AM",
      label: "09:00 AM",
    },
    {
      value: "09:15 AM",
      label: "09:15 AM",
    },
    {
      value: "09:30 AM",
      label: "09:30 AM",
    },
    {
      value: "09:45 AM",
      label: "09:45 AM",
    },
    {
      value: "10:00 AM",
      label: "10:00 AM",
    },
    {
      value: "10:15 AM",
      label: "10:15 AM",
    },
  ];

  const symptomData = [
    {
      value: "1",
      label: "Fever",
    },
    {
      value: "2",
      label: "Headche",
    },
    {
      value: "3",
      label: "Cough",
    },
    {
      value: "4",
      label: "Sneezing",
    },
    {
      value: "5",
      label: "Cold",
    },
    {
      value: "6",
      label: "Running Nose",
    },
  ];

  const adList = [
    {
      cardText: "My Self",
      src: "/images/Avoid Contact.svg",
    },
    {
      cardText: "My Self",
      src: "/images/Avoid Contact.svg",
    },
    {
      cardText: "My Self",
      src: "/images/Avoid Contact.svg",
    },
    {
      cardText: "My Self",
      src: "/images/Avoid Contact.svg",
    },
  ];
  return (
    <div className={classes.mainContent}>
      <Paper className={classes.root}>
        <Typography variant="h5" className={classes.completed}>
          Mobile Number
        </Typography>
        <MobileNumber
          value={state?.mobile}
          label={""}
          handleChange={(value) => changeState("mobile", value)}
          type={"number"}
        />
      </Paper>
      <Paper className={classes.root}>
        <Typography variant="h5" className={classes.completed}>
          SendingOTP Loader
        </Typography>
        <OtpLoader message={"Sending OTP..."} />
      </Paper>
      <Paper className={classes.root}>
        <Typography variant="h5" className={classes.completed}>
          OTP
        </Typography>
        <EnterOtp />
      </Paper>
      <Paper className={classes.root}>
        <Typography variant="h5" className={classes.completed}>
          Header1
        </Typography>
        <Header profileText={"Complete your profile"} showPersonIcon />
      </Paper>
      <Paper className={classes.root}>
        <Typography variant="h5" className={classes.completed}>
          Header2
        </Typography>
        <Header showBackIcon showHomeIcon />
      </Paper>
      <Paper className={classes.root}>
        <Typography variant="h5" className={classes.completed}>
          Text field1
        </Typography>
        <Input label={"Name"} isRequired fontSize={16} type={"text"} />
        <SelectBox label="Name" isrequired />
      </Paper>
      <Paper className={classes.root}>
        <Typography variant="h5" className={classes.completed}>
          Text field2
        </Typography>
        <Input label={"Mobile Number"} isRequired type={"number"} />
      </Paper>
      <Paper className={classes.root}>
        <Typography variant="h5" className={classes.completed}>
          Text field3
        </Typography>
        <Input
          label={"Mobile Number"}
          isRequired
          disable={btnDisabled}
          icon={<img src="/images/icons8-ok.svg" alt="tick" />}
          value={"+91 9840426348"}
        />
      </Paper>
      <Paper className={classes.root}>
        <Typography variant="h5" className={classes.notCompleted}>
          Text field4 (Email validation not completed)
        </Typography>
        <Input label={"Email"} />
      </Paper>
      <Paper className={classes.root}>
        <Typography variant="h5" className={classes.completed}>
          Text field5
        </Typography>
        <Input
          label={"Membership Code (For corporate account holders)"}
          showInfoIcon
        />
      </Paper>
      <Paper className={classes.root}>
        <Typography variant="h5" className={classes.completed}>
          Toggle Button
        </Typography>
        <TabSelect
          label={"Gender"}
          isrequired
          value={state?.gender}
          items={genderData}
          onClick={(value) => changeState("gender", value)}
          isReadyOnly={false}
          errorValidation={true ?? {}}
          disabled={false ?? false}
        />
      </Paper>
      <Paper className={classes.root}>
        <Typography variant="h5" className={classes.completed}>
          Header2
        </Typography>
        <HealthCircle showHealthCircleIcon showNotificationIcon showUserIcon />
      </Paper>
      <Paper className={classes.root}>
        <Typography variant="h5" className={classes.completed}>
          BannerCard
        </Typography>
        <BannerCard
          bannerText={"Want to consult a doctor?"}
          backgroundColor={"#FFC7A2"}
          bannerImage={"/images/BannerImage.svg"}
          buttonText={"Book Appointment"}
        />
      </Paper>
      <Paper className={classes.root}>
        <Typography variant="h5" className={classes.completed}>
          Text with count
        </Typography>
        <Textwithcount text="Appointment" count="03" />
      </Paper>
      <Paper className={classes.root}>
        <Typography variant="h5" className={classes.completed}>
          Footer Button
        </Typography>
        <FooterButton firstButton="Cancel" secondButton="Save" />
      </Paper>
      <Paper className={classes.root}>
        <Typography variant="h5" className={classes.completed}>
          Emergency Helper
        </Typography>
        <EmergencyHelper />
      </Paper>
      <Paper className={classes.root}>
        <Typography variant="h5" className={classes.completed}>
          DOB & AGE
        </Typography>
        <TabSelect
          label="Age"
          dobInput
          isrequired
          value={"1"}
          items={dobAgeData}
          onClick={(value) => changeState("dob_age", value)}
          isReadyOnly={false}
          errorValidation={true ?? {}}
          disabled={false ?? false}
        />
        {/* <DOBToggleButton
          label="Age"
          isrequired
          items={dobAgeData}
          value={state?.dob_age}
          onChange={(value) => changeState("dob_age", value)}
        /> */}
      </Paper>
      <Paper className={classes.root}>
        <Typography>Actioncard</Typography>
        <Actioncard
          date
          doctorname="Dr. Mahesh Gokulnath"
          time="09:15 AM"
          btnLabel="Join Now"
          notification="Sarveshwara (You) has an appointment today."
        />
      </Paper>
      <Paper className={classes.root}>
        <Typography variant="h5" className={classes.completed}>
          Toogle card
        </Typography>
        <Togglecard
          label="My Self"
          backgroundColor="#FFD8BE"
          src="/images/Group 4590.svg"
        />
      </Paper>
      <Paper className={classes.root}>
        <SelectBox label="Relationship" isrequired />
      </Paper>
      <Paper className={classes.root}>
        <Typography variant="h5" className={classes.completed}>
          carousel card
        </Typography>
        <Carouselcard cardText={"My self"} src={"/images/Avoid Contact.svg"} />
      </Paper>
      <Paper className={classes.root}>
        <Typography variant="h5" className={classes.completed}>
          carousel card component
        </Typography>
        <Carouselcomponentcard carouseldata={adList} />
      </Paper>
      <Paper className={classes.root}>
        <Typography variant="h5" className={classes.completed}>
          Card section with primary, secondar text
        </Typography>
        <Cardsection
          Header="Patient details"
          headericon="/images/icons8-user (1).svg"
          primarytext="Sarveshwara Narayanan"
          secondartext="+91 9840426348"
          label="Male, 28 Yrs"
        />
      </Paper>
      <Paper className={classes.root}>
        <Typography variant="h5" className={classes.completed}>
          Card section primarytext[ ]
        </Typography>
        <Cardsection
          Header="Health issue"
          headericon="/images/icons8-heart-with-pulse (1).svg"
          healthissue={[
            {
              issue:
                "Having stomach pain for more than 3  days. Not feeling hungry.",
            },
            {
              issue:
                "Having stomach pain for more than 3  days. Not feeling hungry.",
            },
          ]}
        />
      </Paper>
      <Paper className={classes.root}>
        <Typography variant="h5" className={classes.completed}>
          Card section primarytabs[ ]
        </Typography>
        <Cardsection
          Header="Symptoms"
          headericon="/images/icons8-coughing (1).svg"
          symptoms={[
            {
              symptom: "Cough",
            },
            {
              symptom: "fever",
            },
            {
              symptom: "Tierdness",
            },
          ]}
        />
      </Paper>
      <Paper className={classes.root}>
        <Typography variant="h5" className={classes.completed}>
          Card section Documet[ ]
        </Typography>
        <Cardsection
          Header="Reports"
          headericon="/images/icons8-document (1).svg"
          documents={[
            {
              doc: "Consultation Reports",
            },
            {
              doc: "fever",
            },
            {
              doc: "Tierdness",
            },
          ]}
        />
      </Paper>
      <Paper className={classes.root}>
        <Typography variant="h5" className={classes.completed}>
          Select Button
        </Typography>
        <SelectBox label="Relationship" isrequired />
      </Paper>
      <Paper className={classes.root}>
        <Typography variant="h5" className={classes.completed}>
          Search Filter
        </Typography>
        <SearchFilter label="Relationship" isrequired />
      </Paper>
      <Paper className={classes.root}>
        <Typography variant="h5" className={classes.completed}>
          Time Slots
        </Typography>
        <TimeSlotToggle
          disabled={false ?? false}
          value={state?.gender}
          onSelected={(value) => changeState("gender", value)}
          toggleButtonData={timeSlotData}
          //   isSelected={"09:00 AM"}
          showTimeIcon
          date="7th Jun, 2021"
        />
      </Paper>
      <Paper className={classes.root}>
        <Typography variant="h5" className={classes.completed}>
          MultiSelectDropdown Symptoms
        </Typography>
        <MultiSelectDropdown
          value={state?.suggestion}
          symptoms={symptomData}
          onClick={(value) => changeState("suggestion", value)}
          title="Quick Suggestions"
        />
      </Paper>
      <Paper className={classes.root}>
        <Typography>CustomTextadder</Typography>
        <CustomTextadder />
      </Paper>
      {/* <Paper className={classes.root}>
        <Typography variant="h5" className={classes.completed}>
          ListWithCheckbox
        </Typography>
        <ListWithCheckbox
          data={[
            { label: "Ulcer", value: "1" },
            { label: "Diabetics", value: "2" },
            { label: "Nutrition Deficiency", value: "3" },
          ]}
          selectedValue={["3"]}
        />
      </Paper> */}
      <Paper className={classes.root}>
        <Typography variant="h5" className={classes.completed}>
          CustomizeTabs
        </Typography>
        <CustomizeTabs />
      </Paper>
      <Paper className={classes.root}>
        <Typography variant="h5" className={classes.completed}>
          CalendarRoller
        </Typography>
        <CalendarRoller
          Header="June, 2021"
          date={[
            { available: true, value: "07 Tue" },
            { available: false, value: "07 Tue" },
            { available: false, value: "07 Tue" },
            { available: true, value: "07 Tue" },
            { available: false, value: "07 Tue" },
            { available: false, value: "07 Tue" },
            { available: false, value: "07 Tue" },
            { available: true, value: "07 Tue" },
            { available: false, value: "07 Tue" },
            { available: true, value: "07 Tue" },
          ]}
        />
      </Paper>
      <Paper className={classes.root}>
        <Typography variant="h5" className={classes.completed}>
          Card Header
        </Typography>
        <Cardheader
          title="Dr. Mahesh Gokulnath"
          firstSubText="MBBS"
          firstIcon={"/images/icons8-certificate.svg"}
          secondSubText="KGDH Hospital"
          secondIcon={"/images/icons8-hospital.svg"}
        />
      </Paper>
      <Paper className={classes.root}>
        <Typography variant="h5" className={classes.completed}>
          Card Date and Time
        </Typography>
        <CardDateTime />
      </Paper>
      <Paper className={classes.root}>
        <Typography variant="h5" className={classes.completed}>
          AppointmentListCard
        </Typography>
        <AppointmentListCard
          title="Dr. Mahesh Gokulnath"
          firstSubText="For Sarvesh (You)"
          subText="in 5 hrs"
        />
      </Paper>
    </div>
  );
};
