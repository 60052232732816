import React from "react";
import { makeStyles, Grid, Typography, Button } from "@material-ui/core";
import { Textwithcount, Textwithbutton } from "../../components";
import Skeleton from "@material-ui/lab/Skeleton";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { useQuery } from "@apollo/client";
import { getHabitActivity } from "../../graphql/queries";
import moment from "moment";
import { AuthContext } from "../../contexts";

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "none",
    "& .MuiCardHeader-root": {
      padding: 0,
    },
  },
  textCount: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  noData: {
    textAlign: "center",
    background: "#0079650A",
    borderRadius: "8px",
  },
}));

export const TodaysGoals = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const auth = React.useContext(AuthContext);
  const { data, loading, error } = useQuery( getHabitActivity(auth?.user?.profile?.id), { fetchPolicy: "no-cache" });
  const [showAll, setShowAll] = React.useState(false);
  const [activity, setActivity] = React.useState([]);
  const goNext = () => {
    history.push(Routes.goalTracker);
  };

  const createNewHabit = () => {
    history.push(Routes.newHabit);
  };

  const handleView = () => {
    setShowAll(!showAll);
  };

  const handleActivityupdate = (activityId, home) => {
    history.push({
      pathname: `${Routes.activityUpdateParent}/${activityId}`,
      state: { home },
    });
  };

  React.useEffect(() => {
    let activityData = [];
    if (!loading && data) {
      let activity = data?.allUserHabitActivities?.data;
      activity.map((item) => {
        var activityDate = moment(moment.utc(item?.dateTime)).format("DD/MM/YYYY");
        var todayDate = moment().format("DD/MM/YYYY");
        var todayActivity = activityDate === todayDate ? true : false;
        return todayActivity
          ? activityData.push({
              id: item?.id,
              habit: item?.userHabits?.habit,
              habits: item?.userHabits?.habits,
              startDate: item?.userHabits?.startDate,
              endDate: item?.userHabits?.endDate,
              isCompleted: item?.isCompleted,
              time: moment.utc(item?.dateTime).format("hh:mm A"),
            })
          : [];
      });
    }
    setActivity(activityData);
    // eslint-disable-next-line
  }, [loading]);

  return (
    <div style={{ marginTop: "24px" }}>
      <Textwithbutton
        actions={
          <Button variant="text" color="primary" onClick={goNext}>
            Goals Tracker &nbsp; <ArrowForwardIosIcon style={{ fontSize: "0.5rem" }} />
          </Button>
        }
        cardText={
          <Textwithcount
            text="Today's Goals"
            count={ activity && activity.length < 1 ? 0 : activity.length < 10 ? `0${activity.length}` : activity.length}
          />
        }
      />
      {loading ? (
        <Grid>
          {[...Array(2)].map((e, i) => {
            return (
              <Grid item key={i} style={{ marginBottom: "16px" }}>
                <Skeleton variant="rect" width={"100%"} height={128} />
              </Grid>
            );
          })}
        </Grid>
      ) : activity?.length > 0 ? (
        <Grid container>
          <Button
            variant="outlined"
            color="primary"
            style={{ marginBottom: "16px", width: "100%", borderRadius: "8px" }}
            onClick={createNewHabit}
          >
            Create New Goal
          </Button>
          {!showAll &&
            activity?.slice(0, 2)?.map((item, i) => {
              var habitName = item?.habit?.habitName ? item?.habit?.habitName : item?.habits?.habitName;
              return (
                <Grid item xs={12}>
                  <Textwithbutton
                    key={i}
                    onClick={() => handleActivityupdate(item?.id, "home")}
                    actions={<img src="/images/arrow.svg" alt="No" />}
                    status={item?.isCompleted}
                    cardType={item?.isCompleted ? "" : "cardType"}
                    statusText={item?.isCompleted ? "Completed" : ""}
                    cardText={<Typography variant="subtitle1" color={"textPrimary"}>{habitName}</Typography>}
                    subText={<Typography variant="body2" color="primary">{item?.time}</Typography>}
                  />
                </Grid>
              );
            })}
          {!showAll && (
            <Grid item xs={12} style={{ textAlign: "center", margin: "8px" }}>
              <Button variant="text" color="primary" onClick={() => handleView()}>VIEW ALL</Button>
            </Grid>
          )}
          {showAll &&
            activity?.map((item, i) => {
              var habitName = item?.habit?.habitName ? item?.habit?.habitName : item?.habits?.habitName;
              return (
                <Grid item xs={12}>
                  <Textwithbutton
                    key={i}
                    onClick={() => handleActivityupdate(item?.id, "home")}
                    actions={<img src="/images/arrow.svg" alt="No" />}
                    status={item?.isCompleted}
                    cardType={item?.isCompleted ? "" : "cardType"}
                    statusText={item?.isCompleted ? "Completed" : ""}
                    cardText={<Typography variant="subtitle1" color={"textPrimary"}>{habitName}</Typography>}
                    subText={<Typography variant="body2" color="primary">{item?.time}</Typography>}
                  />
                </Grid>
              );
            })}
          {showAll && (
            <Grid item xs={12} style={{ textAlign: "center", margin: "8px" }}>
              <Button variant="text" color="primary" onClick={() => handleView()}>HIDE ALL</Button>
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid container justify="center" alignItems="center" className={classes.noData}>
          <Grid item>
            <img src="/images/Group 5135.svg" alt="No" style={{ margin: "16px" }}/>
            <Typography variant="body2" color="textPrimary">
              {error ? "Something went wrong, Try Again!" : "No goals here at the moment!"}
            </Typography>
            <Button variant="outlined" color="primary" style={{ margin: "16px" }} onClick={createNewHabit}>Create New Goal</Button>
          </Grid>
        </Grid>
      )}
    </div>
  );
};
