import React from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import { Header, Input, FooterButton } from "../../components";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
import { AppointmentContext } from "../../contexts";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    height: "100%",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
    margin: 0,
  },
  root: {
    padding: theme.spacing(3),
    paddingTop: 80,
    paddingBottom: "95px",
  },
  header: {
    position: "fixed",
    zIndex: 1000,
    [theme.breakpoints.up("md")]: {
      width: 768,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  footer: {
    bottom: 0,
    position: "fixed",
    [theme.breakpoints.up("md")]: {
      width: 768,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  grid: {
    width: "48%",
    [theme.breakpoints.up("sm")]: {
      width: "48%",
    },
  },
  calculation: {
    display: "inline-flex",
    background: "#F5863414",
    border: "1px solid #F58634",
    borderRadius: "4px",
    opacity: "1",
    textAlign: "center",
    padding: 10,
    marginBottom: theme.spacing(2),
  },
}));

export const Vitals = (props) => {
  const appointmentContext = React.useContext(AppointmentContext);
  const classes = useStyles(props);
  const history = useHistory();
  const [state, setState] = React.useState(appointmentContext.vitals);

  const changeState = (key, value) => {
    if (value !== null) {
      state[key] = value;
      appointmentContext.vitals[key] = value;
      setState({
        ...state,
      });
    }
    if (state.height_in_cm && state.weight_in_cm) {
      let heightForBMI = appointmentContext?.vitals?.height_in_cm / 100;
      let weightForBMI = appointmentContext?.vitals?.weight_in_cm;
      let BMI = (weightForBMI / (heightForBMI * heightForBMI)).toFixed(2);
      appointmentContext.vitals["bmi_in_kg_m2"] = BMI;
      setState({ ...state, bmi_in_kg_m2: BMI });
    } else {
      appointmentContext.vitals["bmi_in_kg_m2"] = "";
      setState({ ...state, bmi_in_kg_m2: "" });
    }
  };

  const goBack = () => {
    history.goBack();
  };
  const goHome = () => {
    history.push(Routes.home);
  };
  const goNext = () => {
    history.push(Routes.appointmentSummary);
  };

  return (
    <div className={classes.rootContainer}>
      <div className={classes.header}>
        <Header
          showBackIcon
          showHomeIcon
          handleBack={goBack}
          handleHome={goHome}
        />
      </div>
      <div className={classes.root} noValidate>
        <Typography
          variant="body1"
          color={"textPrimary"}
          style={{marginBottom: "24px"}}
        >
          Enter Your Vitals (Optional)
        </Typography>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item className={classes.grid}>
            <Input
              label={"Height (cm)"}
              value={state?.height_in_cm}
              type="number"
              onChange={(value) => changeState("height_in_cm", value)}
            />
          </Grid>
          <Grid item className={classes.grid}>
            <Input
              label={"Weight (kg)"}
              value={state?.weight_in_cm}
              type="number"
              onChange={(value) => changeState("weight_in_cm", value)}
            />
          </Grid>
          {state?.bmi_in_kg_m2?.length > 0 && (
            <Grid container className={classes.calculation}>
              <Grid item>
                <img src="/images/icons8-body-camera.svg" alt="No" />
                &nbsp;
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  color="primary"
                  align="left"
                  gutterBottom
                >
                  &nbsp;Your BMI is
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  color={"textPrimary"}
                  align="left"
                  gutterBottom
                  style={{fontWeight: "bold"}}
                >
                  &nbsp;{state?.bmi_in_kg_m2} kg/m2
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid item className={classes.grid}>
            <Input
              label={"Temp (F)"}
              value={state?.temp_in_f}
              type="number"
              onChange={(value) => changeState("temp_in_f", value)}
            />
          </Grid>
          <Grid item className={classes.grid}>
            <Input
              label={"Pulse (bpm)"}
              value={state?.puls_bpm}
              type="number"
              onChange={(value) => changeState("puls_bpm", value)}
            />
          </Grid>
          <Grid item className={classes.grid}>
            <Input
              label={"BP-sys (mmHg)"}
              onChange={(value) => changeState("bp_sys", value)}
              type="number"
              value={state?.bp_sys}
            />
          </Grid>
          <Grid item className={classes.grid}>
            <Input
              label={"BP-dia (mmHg)"}
              value={state?.bp_dia}
              type="number"
              onChange={(value) => changeState("bp_dia", value)}
            />
          </Grid>
          <Grid item className={classes.grid}>
            <Input
              label={"SPO2 (%)"}
              type="number"
              value={state?.o2_level}
              onChange={(value) => changeState("o2_level", value)}
            />
          </Grid>
        </Grid>
      </div>
      <div className={classes.footer}>
        <FooterButton
          firstButton="Skip"
          handleClick={goNext}
          btnDisable={
            state.height_in_cm ||
            state.weight_in_cm ||
            state.temp_in_f ||
            state.puls_bpm ||
            state.bp_dia ||
            state.bp_sys ||
            state.o2_level
              ? false
              : true
          }
          secondButton="Continue"
          onClick={goNext}
        />
      </div>
    </div>
  );
};
