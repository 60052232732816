import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

export const DocumentViewer = ({url=""}) =>  {
  const docs = [
    {
      uri: url,
    },
  ];
  return (
    <DocViewer
      documents={docs}
      pluginRenderers={DocViewerRenderers}
      style={{ width: "100%" , height: "100%"}}
    />
  );
}
