import React from "react";
import { Box, makeStyles, TextField, Button, Tooltip } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Badge from '@material-ui/core/Badge';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  filterBtn: {
    marginLeft: 12,
    "& .MuiButton-root": {
      padding: 0,
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    marginLeft: 0,
    width: "100%",
    display: "inline-flex",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    color: theme.palette.text.primary,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    height: "40px",
    color: "#C5C5C5",
    border: `1.5px solid ${theme.palette.border.light}`,
    fontFamily: "crayond_medium !important",
    borderRadius: 5,
    width: "100%",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  // filterApplied: {
  //   borderColor: theme.palette.secondary.main,
  //   color: theme.palette.secondary.main
  // }
}));

export const SearchFilter = ({
  handleChange = () => false,
  value = "",
  showFilter = false,
  onFilterButtonClicked = () => false,
  isFilterApplied = false
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box>
        <div className={classes.search}>
          <TextField
            InputProps={{
              startAdornment: <SearchIcon style={{ margin: 8 }} />,
            }}
            placeholder="Search"
            onChange={(e) => handleChange(e.target.value)}
            value={value}
            variant={"outlined"}
            size={"small"}
            fullWidth
          />
     
        {showFilter && (
          <Tooltip title={isFilterApplied ? "Filter Applied" : ""}>
              <Button
                variant="outlined"
                className={`${classes.filterBtn} ${
                  isFilterApplied ? classes.filterApplied : ``
                }`}
                style={{ minWidth: "40px", minHeight: "40px" }}
                onClick={onFilterButtonClicked}
              >
                <Badge color="primary" variant="dot" invisible={!isFilterApplied}>
                  {/* {isFilterApplied ? (
                    <img src="/images/icons8-filter-secondary.svg" alt="filter" />
                  ) : ( */}
                    <img src="/images/icons8-filter.svg" alt="filter" />
                  {/* )} */}
                </Badge>
              </Button>
          </Tooltip>
        )}
           </div>
      </Box>
    </div>
  );
};
