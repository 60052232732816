import React from "react";
import { withNavBars } from "../../HOCs";
import { withRouter } from "react-router-dom";
import { ProfileDetails } from "./profileDetails";
class ProfileDetailsParent extends React.Component {
  render() {
    return <ProfileDetails />;
  }
}

export default withRouter(withNavBars(ProfileDetailsParent));
