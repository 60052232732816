import React from "react";
import { makeStyles, Typography, Grid, Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  statusCard: {
    border: `1px solid ${theme.palette.border.light}`,
    borderRadius: "24px 24px 0px 0px",
    backgroundColor: `${theme.palette.common.white}`,
    boxShadow: "0px -10px 10px #0000000A",
    textAlign: "center",
    justifyContent: "center",
    padding: theme.spacing(3)
  },
  completed: {
    borderRadius: "8px",
    width: "100%",
    border: `1.5px solid ${theme.palette.primary.main}`,
    textTransform: "capitalize",
    justifyContent: "left",
    [theme.breakpoints.up("xs")]: {padding: "11px 24px"},
    [theme.breakpoints.down("xs")]: {padding: "18px 24px"},
  },
  thumbup:{
    border: `1.5px dotted ${theme.palette.primary.main}`,
    borderRadius: "50%",
    padding: "5px",
    marginRight: "8px",
    paddingTop: "10px",
    width: "40px",
    height: "40px",
  },
}));

export const StatusUpdate = ({updateStatus = () => false}) => {
  const classes = useStyles();

  return (
    <>
      <Grid container direction="row" className={classes.statusCard} >
        <Grid item xs={12} style={{ marginBottom: "16px" }}>
          <img src="/images/icons8-checkmark.svg" alt="NO" />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" color={"textPrimary"} align="center" style={{ marginBottom: "24px" }}>
            You completed the activity on your own or after the notification reminder?
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button variant="outlined" className={classes.completed} onClick={() => updateStatus("bySelf")}>
            <span className={classes.thumbup}><img src="/images/thumbup.svg" alt="No" /></span>
            <Typography variant="subtitle1" color="primary">I completed on my own.</Typography>
          </Button>
        </Grid>
        <Grid item xs={12} style={{marginTop: "16px"}}>
          <Button variant="outlined" className={classes.completed}  onClick={() => updateStatus("byReminder")}>
            <span className={classes.thumbup}><img src="/images/icons8-alarm.svg" alt="No" /></span>
            <Typography variant="subtitle1" color="primary">I completed when reminded.</Typography>
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
