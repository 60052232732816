import React from "react";
import {
  makeStyles,
  Card,
  CardHeader,
  Avatar,
  Typography,
} from "@material-ui/core";

// import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottomLeftRadius: (props) => (props?.cardStyle ? "15px" : "none"),
    borderBottomRightRadius: (props) => (props?.cardStyle ? "15px" : "none"),
    border: (props) => (props?.cardStyle ? "1px dashed #C5C5C5" : "none"),
    boxShadow: "none",
    "& .MuiCardHeader-root": {
      padding: "10px",
      paddingLeft: "16px",
      paddingRight: "16px",
    },
    "& .MuiCardHeader-avatar": {
      marginRight: "12px",
    },
    "& .MuiCardHeader-action": {
      margin: 0,
    },
    "& .MuiTypography-body1": {
      fontSize: "14px",
    },
  },
  cardSubheader: {
    display: "inline-flex",
  },
  subheaderText: {
    color: "#0F0B117A",
    texTransform: "capitalize",
    fontSize: "12px",
    paddingTop: "2px",
    fontFamily: "healthcircles_medium",
  },
  avatarbg: {
    color: "#0F0B11",
    backgroundColor: "#F5F5F5",
    width: "40px",
    height: "40px",
  },
}));

const getlabel = (props) => {
  if (props?.price) {
    return <p className="card-price">&#8377; {props.price} &nbsp;</p>;
  }
  if (props?.new) {
    return (
      <Typography
        variant="subtitle2"
        color="secondary"
        style={{
          border: "1px solid #F5863414",
          backgroundColor: "#F5863414",
          fontWeight: "bold",
          padding: "3px",
          borderRadius: "4px"
        }}
      >
        {"New!"}
      </Typography>
    );
  }
};

export const Cardheader = (props) => {
  const classes = useStyles(props);

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatarbg}>
            {props?.profile_pic && (
              <img
                src={props?.profile_pic}
                alt="No"
                width="40px"
                height="40px"
              />
            )}
            {props.report_img && <img src={props.report_img} alt="No" />}
          </Avatar>
        }
        action={getlabel(props)}
        title={
          <Typography variant="body1" color={"textPrimary"}>
            {props.title}
          </Typography>
        }
        subheader={
          props.firstSubText && (
            <>
              <div>
                <div className={classes.cardSubheader}>
                  {props.firstIcon && (
                    <span style={{marginTop: "4px"}}>
                      <img src={props.firstIcon} alt="No" />
                      &nbsp;&nbsp;
                    </span>
                  )}
                  {props.firstSubText && (
                    <span className={classes.subheaderText}>
                      {props.firstSubText}
                    </span>
                  )}
                </div>
                <div style={{marginTop: "2px"}}>
                  {props.secondIcon && (
                    <span>
                      <img src={props.secondIcon} alt="No" />
                      &nbsp;&nbsp;
                    </span>
                  )}
                  {props.secondSubText && (
                    <span className={classes.subheaderText}>
                      {props.secondSubText}
                    </span>
                  )}
                </div>
              </div>
            </>
          )
        }
      />
    </Card>
  );
};
