import React from "react";
import {
  makeStyles,
  Dialog,
  ListItem,
  List,
  AppBar,
  Toolbar,
  IconButton,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    color: `${theme.palette.common.black}`,
    background:`${theme.palette.common.white}`,
    boxShadow: "none",
    "& .MuiAppBar-colorPrimary":{
        color: `${theme.palette.common.black}`,
        background:`${theme.palette.common.white}`,
    }
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  downloadIcon: {
    display: "flex",
  },
  grow: {
    flexGrow: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const SimpleModal = (props) => {
  const classes = useStyles();

  const handleClose = () => {
    props.onClose();
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={props.open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <div>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <div className={classes.grow} />
              <div className={classes.downloadIcon}>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="close"
                  onClick={() => window.open(props.download)}
                >
                  <img src="/images/icons8-download.svg" alt="no" />
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
        </div>
        <List>
          <ListItem>{props.component}</ListItem>
        </List>
      </Dialog>
    </div>
  );
};
