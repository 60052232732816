import React from "react";
import { makeStyles, Card, CardContent } from "@material-ui/core";
import { Cardheader } from "../cardheader";
import { CardDateTimeChild } from "../datePatientChild";

const useStyles = makeStyles((theme) => ({
  root:{
    cursor: "pointer"
  },
  gridBtn1: {
    borderTop: `1px solid ${theme.palette.border.light}`,
    borderRight: `1px solid ${theme.palette.border.light}`,
    textAlign: "center",
  },
  gridBtn2: {
    textAlign: "center",
    borderTop: `1px solid ${theme.palette.border.light}`,
    borderLeft: "none",
  },
  content: {
    "&:last-child": {
      padding: 0,
    },
  },
  contentBtn: {
    padding: 0,
    "&:last-child": {
      padding: 0,
    },
  },
  subText: {
    fontSize: "12px",
    textTransform: "lowercase",
    fontFamily: "crayond_medium !important",
  },
}));

export const DatePatient = (props) => {
  const classes = useStyles(props);

  return (
    <Card className={classes.root} onClick={props.handleReport}>
      <CardContent className={classes.content}>
        <Cardheader title={props.title} firstSubText={props.firstSubText} new={props.new} report_img={props.report_img}/>
        <CardDateTimeChild date={props.date} patient={props.patient} />
      </CardContent>
    </Card>
  );
};
