import React from "react";
import { makeStyles, Typography, Grid } from "@material-ui/core";
import clsx from "clsx";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    "& .MuiListItemIcon-root": {
      minWidth: "25px",
      marginRight: "5px",
    },
    "& .MuiListItem-gutters": {
      paddingLeft: "0px",
    },
  },
  secondaryText: {
    "& .MuiListItem-root": {
      padding: 0,
      paddingBottom: "8px",
      cursor: "pointer",
    },
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

export const CardsectionCollapse = ({
  Header = "",
  headericon = "",
  habits = [],
  handleList = () => false,
  loading = false,
  error = false,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      <List
        component="div"
        aria-labelledby="nested-list-subheader"
        className={classes.root}
      >
        <ListItem>
          {headericon && (
            <ListItemIcon>
              <img src={headericon} alt="edit" />
            </ListItemIcon>
          )}
          <ListItemText
            primary={
              <Typography variant="body1" color="textPrimary">
                {Header}
              </Typography>
            }
          />

          <ListItemIcon
            style={{ cursor: "pointer" }}
            className={clsx(classes.expand, {
              [classes.expandOpen]: open,
            })}
            onClick={handleClick}
          >
            <img src="/images/Group 5083.svg" alt="arrow" />
          </ListItemIcon>
        </ListItem>
        {/* {loading ? (
          <Skeleton variant="rect" width={"100%"} height={20} />
        ) : (
          habits?.length > 0 && (
            <List
              component="div"
              disablePadding
              className={classes.secondaryText}
            >
              <ListItem
                onClick={() =>
                  handleList(habits[0]?.habitName, Header, habits[0]?.id)
                    ? handleList(habits[0]?.habitName, Header, habits[0]?.id)
                    : ""
                }
              >
                <ListItemText
                  primary={
                    habits ? (
                      <Typography variant="subtitle1" color="primary">
                        {habits[0]?.habitName}
                      </Typography>
                    ) : (
                      ""
                    )
                  }
                />
              </ListItem>
            </List>
          )
        )} */}
        <Collapse in={open} timeout="auto" unmountOnExit>
          {loading ? (
            <Grid>
              {[...Array(5)].map((e, i) => {
                return (
                  <Grid item key={i} style={{ margin: "16px 0px" }}>
                    <Skeleton variant="rect" width={"100%"} height={20} />
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            habits?.length > 0 && (
              <List
                component="div"
                disablePadding
                className={classes.secondaryText}
              >
                {habits?.map((v, i) => {
                  return (
                    <ListItem
                      key={i}
                      onClick={() =>
                        handleList(v?.habitName, Header, v?.id)
                          ? handleList(v?.habitName, Header, v?.id)
                          : ""
                      }
                    >
                      <ListItemText
                        primary={
                          <Typography variant="subtitle1" color="primary">
                            {" "}
                            {v?.habitName}{" "}
                          </Typography>
                        }
                      />
                    </ListItem>
                  );
                })}
              </List>
            )
          )}
        </Collapse>
      </List>
      {error && (
        <Typography variant="body2" color="textPrimary">
          Something went wrong, Try Again!
        </Typography>
      )}
    </>
  );
};
