import React from "react";
import { Typography, makeStyles, Button, Grid } from "@material-ui/core";
import { EnterOtp } from "../../components";
import { useHistory, useLocation } from "react-router-dom";
import { AlertContext, FullScreenContext, AuthContext } from "../../contexts";
import {
  AlertProps,
  LocalStorageKeys,
  NetWorkCallMethods,
  sendOTPForLogin,
} from "../../utils";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { Routes } from "../../router/routes";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  gridContainer: {
    width: 290,
  },
  otpSection: {
    marginTop: 32,
  },
  footer: {
    marginTop: 16,
    width: "100%",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
  },
  imageParent: {
    textAlign: "center",
  },
}));

export const OTPVerifier = (props) => {
  const classes = useStyles();
  const [value, setvalue] = React.useState("");
  const history = useHistory();
  const location = useLocation();
  const fullScreenLoader = React.useContext(FullScreenContext);
  const alert = React.useContext(AlertContext);
  const auth = React.useContext(AuthContext);

  const handleChange = (otp) => {
    setvalue(otp);
    if (otp?.length === 4) {
      verifyOTP(otp);
    }
  };

  const verifyOTP = async (otp) => {
    let requestBody = {
      mobile: props.mobile,
      country_code: props.mobile_code,
      otp,
    };

    fullScreenLoader.setLoader({
      ...fullScreenLoader,
      open: true,
      message: "Verifing OTP",
    });

    await NetworkCall(
      config.auth_service + "/login/otp/verify",
      NetWorkCallMethods.post,
      requestBody
    )
      .then((res) => {
        if (parseInt(res.data?.statusCode ?? 0) === 200) {
          if (res?.data?.data?.is_new_user) {
            //If he is a new user navigate to complete profile route.
            history.push({
              pathname: Routes.completeProfile,
              state: {
                ...props,
                user_id: res?.data?.data?.id,
                auth_token: res?.data?.data?.auth_token,
                profile: res?.data?.data?.profile,
              },
            });
          } else {
            // Storing the Auth Token in Session
            localStorage.setItem(
              LocalStorageKeys.authToken,
              res?.data?.data?.auth_token
            );

            // Update User Device Token 
            let user_device_token = localStorage.getItem(LocalStorageKeys.deviceToken);
            let auth_token = res?.data?.data?.auth_token;
            let user_id = res?.data?.data?.profile?.id;
            deviceToken(user_device_token, auth_token, user_id);

            //If the he user is a existing user, then navigate to home.
            auth.reload();
            history.push(location?.state?.from?.pathname ?? Routes.home);

            // updateUserToken(
            //   res?.data?.data?.profile?.id,
            //   res?.data?.data?.auth_token
            // );
          }
        } else {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            msg: res.data.message,
          });
        }
      })
      .catch((err) => {
        console.log("Error While Verifiing OTP:", err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          msg: "Something went wrong, Try Again!",
        });
      });

    fullScreenLoader.setLoader({
      ...fullScreenLoader,
      open: false,
      message: "",
    });
  };

  // Update Device Token
  const deviceToken = async (user_device_token, auth_token, user_id) => {
    const response = await NetworkCall(
      config.rest_api_service + "/users/token/upsert",
      NetWorkCallMethods.post,
      { user_device_token, user_id },
      { Authorization: "Bearer " + auth_token },
      false
    );
    console.log("User Device Token Updated Response : ", response);
  }

  const resendOTP = async () => {
    let requestBody = {
      mobile_no: props.mobile,
      mobile_no_country_code: props.mobile_code,
    };

    fullScreenLoader.setLoader({
      ...fullScreenLoader,
      open: true,
      message: "Sending OTP",
    });

    await sendOTPForLogin(requestBody)
      .then((res) => {
        if (parseInt(res.data?.statusCode ?? 0) === 200) {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            msg: "We've resent the OTP, please check.",
          });
        } else {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.warning,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            msg: res.data.message,
          });
        }
      })
      .catch((err) => {
        console.log("Error While Loggin In:", err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          msg: "Something Went Wrong, Try Again Later!",
        });
      });

    fullScreenLoader.setLoader({
      ...fullScreenLoader,
      open: false,
      message: "",
    });
  };

  const changeMobileNo = () => {
    history.goBack();
  };

  return (
    <div className={classes.root} noValidate>
      <Grid className={classes.gridContainer}>
        <Grid item container justify={"center"} alignItems={"center"}>
          <Grid item xs={12} className={classes.imageParent}>
            <img src="/images/icons8-key.svg" alt="login" />
          </Grid>

          <Grid item>
            <Typography variant={"h6"} color={"textPrimary"}>
              You're Closer!
            </Typography>
          </Grid>

          <Grid item container justify={"center"} alignItems={"center"}>
            <Typography
              variant={"subtitle1"}
              color={"textSecondary"}
              gutterBottom
            >
              {"We've sent OTP to "}&nbsp;
            </Typography>
            <Typography variant={"subtitle1"} color={"primary"} gutterBottom>
              {props.mobile_code}&nbsp;
              {props.mobile}
            </Typography>
          </Grid>

          <Grid item>
            <Button
              size={"small"}
              onClick={changeMobileNo}
              variant="outlined"
              color="primary"
            >
              Change
            </Button>
          </Grid>

          <Grid item className={classes.otpSection}>
            <EnterOtp value={value} handleChange={handleChange} />
          </Grid>

          <Grid item className={classes.footer}>
            <Typography
              variant={"subtitle1"}
              align={"center"}
              color={"textSecondary"}
            >
              {"Didn't receive the OTP? "}
            </Typography>
            <Button
              variant="text"
              color={"primary"}
              onClick={resendOTP}
              style={{ fontFamily: "healthcircles_medium" }}
            >
              {"Resend"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
