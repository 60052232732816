import React from "react";
import { makeStyles, Grid, Button, Typography, Slide } from "@material-ui/core";
import { Cardheader, CardDateTime } from "../../components";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
import { AppointmentContext, AuthContext } from "../../contexts";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    position: "relative",
    minHeight: "100%",
    backgroundColor: theme.palette.background.paper,
    margin: 0,
  },
  root: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(2),
  },
  cardContainer: {
    marginTop: "40px",
    marginBottom: "40px",
  },
  footer: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    borderTop: "2px dashed #00000014",
    boxShadow: "0px -5px 10px #00000014",
    textAlign: "center",
    padding: 20,
  },
  header: {
    marginTop: "18px",
    marginBottom: "18px",
  },
  footerContainer: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "40%",
    },
  },
}));

export const AppoinmentConfrimation = (props) => {
  const classes = useStyles(props);
  const history = useHistory();
  const appointmentContext = React.useContext(AppointmentContext);
  const auth = React.useContext(AuthContext);

  const goHome = () => {
    history.push(Routes.home);
  };

  React.useEffect(() => {
    appointmentContext.is_self_booking = null;
    appointmentContext.booking_for = {
      id: null,
      releation_id: null,
      name: {},
      gender: null,
      dob_age: {},
      releationship: {},
      mobile: {},
      email: null,
      error: {
        name: false,
        gender: false,
        dob_age: false,
        mobile: false,
        email: false,
        releationship: false,
      },
    };
    appointmentContext.reason_for_consultation = null;
    appointmentContext.health_issues = [];
    appointmentContext.symptoms = [];
    appointmentContext.health_problems = [];
    appointmentContext.allergies = [];
    appointmentContext.drugs = [];
    appointmentContext.appointed_doctor_details = {};
    appointmentContext.reports = [];
    appointmentContext.vitals = {};

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className={classes.rootContainer}>
        <Slide direction="down" in={true} mountOnEnter unmountOnExit>
          <div className={classes.root} noValidate>
            <Grid>
              <Grid item align="center" className={classes.header}>
                <img src="/images/icons8-checkmark.svg" alt="NO" />
              </Grid>
              <Grid item>
                <Typography
                  variant="h6"
                  color={"textPrimary"}
                  align="center"
                  gutterBottom
                >
                  Appointment Confirmed!
                </Typography>
              </Grid>
              <Grid item className={classes.cardContainer}>
                <Cardheader
                  profile_pic={
                    appointmentContext?.appointed_doctor_details
                      ?.doctor_profile_pic
                  }
                  title={
                    appointmentContext?.appointed_doctor_details?.doctor_name
                  }
                  firstSubText={
                    appointmentContext.is_self_booking
                      ? `For ${auth?.user?.profile?.name} (You)`
                      : appointmentContext?.booking_for?.name?.label
                  }
                  price={
                    appointmentContext?.appointed_doctor_details?.doctor_fee
                  }
                  cardStyle
                />
                <CardDateTime
                  date={moment(
                    appointmentContext?.appointed_doctor_details
                      ?.appointment_client_start_time
                  ).format("DD MMM, YYYY")}
                  time={moment(
                    appointmentContext?.appointed_doctor_details
                      ?.appointment_client_start_time
                  ).format("hh:mm A")}
                  cardStyle
                />
              </Grid>
              <Grid item>
                <Typography
                  variant={"subtitle1"}
                  color={"textPrimary"}
                  align="center"
                  gutterBottom
                >
                  {" Updates will be sent over SMS to"}&nbsp;
                </Typography>
                <Typography
                  variant={"subtitle1"}
                  color={"textPrimary"}
                  align="center"
                  gutterBottom
                >
                  {appointmentContext.is_self_booking
                    ? `${auth?.user?.profile?.mobile_code} ${auth?.user?.profile?.mobile}`
                    : `${appointmentContext?.booking_for?.mobile?.mobile_code} ${appointmentContext?.booking_for?.mobile?.mobile} & ${auth?.user?.profile?.mobile_code} ${auth?.user?.profile?.mobile}`}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Slide>
        <div className={classes.footer}>
          <Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={goHome}
                className={classes.footerContainer}
              >
                Back to home
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};
