import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Redirect,
} from "react-router-dom";
import { Routes } from "./routes";
import PrivateRouter from "./privateRouter";

import {
  NotFound,
  Home,
  Login,
  // ComponentList,
  OTPVerifier,
  CompleteProfile,
  SelectPatient,
  ConsultationReason,
  AddHealthIssues,
  Appointments,
  CaseSummary,
  Symptoms,
  HealthProblems,
  Allergies,
  Medications,
  FindDoctor,
  UploadReports,
  Vitals,
  AppoinmentSummary,
  AppoinmentConfrimation,
  PatientDetails,
  ProfileDetails,
  AppointmentSummaryView,
  GoalTracker,
  NewHabit,
  AddHabit,
  ActivityUpdate,
  WeeklyStats,
} from "./../screens";
const RouterApp = (props) => {
  return (
    <Router>
      <Switch>
        {/* 
        
        This a Development Route ComponentList Route 
        <Route exact path={Routes.componentList}>
          <ComponentList />
        </Route>
        
        */}

        {/* Mobile Login Route */}
        <Route exact path={Routes.login}>
          <Login />
        </Route>

        {/* OTP Verifier Route */}
        <Route exact path={Routes.otpVerifier}>
          <OTPVerifier />
        </Route>

        {/* Complete Profile Route */}
        <Route exact path={Routes.completeProfile}>
          <CompleteProfile />
        </Route>

        {/* Home Route */}
        <PrivateRouter exact path={Routes.home}>
          <Home />
        </PrivateRouter>

        {/* Appointments Route */}
        <PrivateRouter exact path={Routes.appointments}>
          <Appointments />
        </PrivateRouter>

        {/* CaseSummary Route */}
        <PrivateRouter exact path={Routes.caseSummary}>
          <CaseSummary />
        </PrivateRouter>

        {/* SelectPatient Route */}
        <PrivateRouter exact path={Routes.selectPatient}>
          <SelectPatient />
        </PrivateRouter>

        {/* ConsultationReason Route */}
        <PrivateRouter exact path={Routes.consultationReason}>
          <ConsultationReason />
        </PrivateRouter>

        {/* AddHealthIssues Route */}
        <PrivateRouter exact path={Routes.addHealthIssues}>
          <AddHealthIssues />
        </PrivateRouter>

        {/* Symptoms Route */}
        <PrivateRouter exact path={Routes.symptoms}>
          <Symptoms />
        </PrivateRouter>

        {/* HealthProblems Route */}
        <PrivateRouter exact path={Routes.healthProblems}>
          <HealthProblems />
        </PrivateRouter>

        {/* HealthIssues Route */}
        <PrivateRouter exact path={Routes.allergies}>
          <Allergies />
        </PrivateRouter>

        {/* HealthIssues Route */}
        <PrivateRouter exact path={Routes.medications}>
          <Medications />
        </PrivateRouter>

        {/* FindDoctor Route */}
        <PrivateRouter exact path={Routes.findDoctor}>
          <FindDoctor />
        </PrivateRouter>

        {/* UploadReports Route */}
        <PrivateRouter exact path={Routes.uploadReports}>
          <UploadReports />
        </PrivateRouter>

        {/* Enter Vitals Route */}
        <PrivateRouter exact path={Routes.vitals}>
          <Vitals />
        </PrivateRouter>

        {/* AppoinmentSummary Route */}
        <PrivateRouter exact path={Routes.appointmentSummary}>
          <AppoinmentSummary />
        </PrivateRouter>

        {/* AppoinmentConfrimation Route */}
        <PrivateRouter exact path={Routes.appointmentConfirmation}>
          <AppoinmentConfrimation />
        </PrivateRouter>

        {/* Patient Detail Route */}
        <PrivateRouter exact path={Routes.patientDetails}>
          <PatientDetails />
        </PrivateRouter>

        {/* Profile Detail Route */}
        <PrivateRouter exact path={Routes.profileDetails}>
          <ProfileDetails />
        </PrivateRouter>

        {/* Home Route */}
        <PrivateRouter exact path={Routes.home}>
          <Home />
        </PrivateRouter>

        {/* AppoinmentSummary Route */}
        <PrivateRouter exact path={Routes.appointmentSummary}>
          <AppoinmentSummary />
        </PrivateRouter>

        {/* AppoinmentSummary View Route */}
        <PrivateRouter exact path={Routes.appointmentSummaryView}>
          <AppointmentSummaryView />
        </PrivateRouter>

        {/* Goal Tracker Route */}
        <PrivateRouter exact path={Routes.goalTracker}>
          <GoalTracker />
        </PrivateRouter>

        {/* New Habit Route */}
        <PrivateRouter exact path={Routes.newHabit}>
          <NewHabit />
        </PrivateRouter>

        {/* Custom Habit Route */}
        <PrivateRouter exact path={Routes.addHabit}>
          <AddHabit />
        </PrivateRouter>

        {/* Activity Update Route */}
        <PrivateRouter exact path={Routes.activityUpdate}>
          <ActivityUpdate />
        </PrivateRouter>

        {/* Weekly Stats Route */}
        <PrivateRouter exact path={Routes.weeklyStats}>
          <WeeklyStats />
        </PrivateRouter>

        {/* For unknow/non-defined path */}
        <Route exact path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default RouterApp;
