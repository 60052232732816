import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ paddingTop: "24px", paddingBottom: "24px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`,
//   };
// }

const StyledTabs = withStyles((theme) => ({
  root: {
    minHeight: "39px",
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    //minHeight: "40px",
    "& > span": {
      maxWidth: 0,
      width: "100%",
      backgroundColor: theme.palette.primary.main,
    },
    "&$selected": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}))((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    background: "#F5F5F5",
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    minHeight: "38px",
    minWidth: "50%",
    borderRadius: "20px",
    "&:focus": {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    fontSize: "14px",
    fontFamily: "crayond_medium !important",
    "& .MuiAppBar-colorPrimary ": {
      backgroundColor: "#F5F5F5",
      border: `1px solid ${theme.palette.border.light}`,
      boxShadow: "none",
      borderRadius: "20px",
    },
    "& .Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
}));

export const CustomizeTabs = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const {
    showTab1 = false,
    showTab2 = false,
    showSearchFilter = false,
    showSearchFilter2 = false,
    tab1heading = "Tab1",
    tab2heading = "Tab2",
  } = props;

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          indicatorColor="primary"
          centered
        >
          <StyledTab label={tab1heading} />
          <StyledTab label={tab2heading} />
        </StyledTabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        {showSearchFilter && <div>{props?.searchFilter}</div>}

        {showTab1 && <div>{props?.tab1}</div>}
      </TabPanel>

      <TabPanel value={value} index={1}>
        {showSearchFilter2 && <div>{props?.searchFilter2}</div>}

        {showTab2 && <div>{props?.tab2}</div>}
      </TabPanel>
    </div>
  );
};

CustomizeTabs.propTypes = {
  showTab1: PropTypes.bool,
  showTab2: PropTypes.bool,
  showSearchFilter: PropTypes.bool,
  showSearchFilter2: PropTypes.bool,
  tab1heading: PropTypes.string,
  tab2heading: PropTypes.string,
};
