import React from "react";
import { makeStyles, Tooltip } from "@material-ui/core";
import { TextField, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginBottom: "16px",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      border: `1.5px solid ${theme.palette.border.light}`,
      borderRadius: 8,
    },
    "& .MuiOutlinedInput-input": {
      padding: "14px 14px",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1.5px solid #007965",
      borderRadius: 8,
    },
    "& .MuiInputBase-input": {
      fontSize: "16px",
    },
    "& .MuiInputBase-input.Mui-disabled": {
      color: "#0F0B117A",
      borderRadius: 8,
      backgroundColor: (props) => props.disableBackground ? theme.palette.border.light : ""
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "16px",
    },
    "& .MuiOutlinedInput-multiline": {
      padding: 14
    }
  },
}));

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.text.primary,
  },
  tooltip: {
    padding: "10px",
    width: "250px",
    backgroundColor: theme.palette.text.primary,
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

export const Input = (props) => {
  const classes = useStyles(props);
  return (
    <div className={classes.wrapper}>
      {props.label && (
        <Typography variant="body2" color="primary" align="left" gutterBottom>
          {props.label}&nbsp;
          {props.showInfoIcon && (
            <span>
              <BootstrapTooltip
                title="You'd have received this code via Email and SMS if you're registered on HealthCircles by your organization."
                placement="top-end"
              >
                <img src="/images/icons-info.svg" alt="info" />
              </BootstrapTooltip>
            </span>
          )}
          {props.isRequired && (
            <Typography color="error" variant="caption">
              *
            </Typography>
          )}
        </Typography>
      )}

      <TextField
        fullWidth
        disabled={props.disable}
        type={props.type}
        className={classes.root}
        id={props.id}
        required={props.isrequired}
        inputProps={{
          min: props.min
        }}
        variant={"outlined"}
        InputProps={{
          endAdornment: props.icon,
        }}
        value={props.value}
        placeholder={props.placeholder}
        onChange={(e) => props.onChange(e.target.value)}
        helperText={props.infoText}
        multiline={props.multiline ? true : false}
      />
      {props.error && (
        <Typography variant="caption" color="error">
          {props.helperText}
        </Typography>
      )}
    </div>
  );
};

export default Input;
