import React from "react";
import { makeStyles, Grid, Slide } from "@material-ui/core";
import { MultiSelectDropdown, Header, FooterButton } from "../../components";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
import { AppointmentContext } from "../../contexts";
import { useQuery } from "@apollo/client";
import { getMasterDrugs } from "../../graphql/queries";
import { getUniqueArrayByValue } from "../../utils";
const useStyles = makeStyles((theme) => ({
  rootContainer: {
    height: "100%",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
    margin: 0,
  },
  root: {
    padding: theme.spacing(3),
    paddingBottom: "95px",
    paddingTop: 80,
  },
  header: {
    position: "fixed",
    zIndex: 1000,
    [theme.breakpoints.up("md")]: {
      width: 768,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  footer: {
    bottom: 0,
    position: "fixed",
    [theme.breakpoints.up("md")]: {
      width: 768,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

export const Medications = (props) => {
  const appointmentContext = React.useContext(AppointmentContext);
  const classes = useStyles(props);
  const history = useHistory();

  const { data, loading, fetchMore } = useQuery(getMasterDrugs(), {
    variables: {
      limit: 10,
      offset: 0,
      search: "",
    },
  });

  const [state, setState] = React.useState({
    drugs: appointmentContext.drugs,
  });

  const goBack = () => {
    history.goBack();
  };
  const goHome = () => {
    history.push(Routes.home);
  };
  const goNext = () => {
    history.push(Routes.findDoctor);
  };

  const loadDrugs = async (search, loadedOptions) => {
    let offset = loadedOptions?.length ?? 0;

    const res = await fetchMore({
      variables: {
        limit: 10,
        offset,
        search: search,
      },
    });

    return {
      options: res?.data?.drugs?.data,
      hasMore: res?.data?.drugs?.pageInfo?.hasNextPage,
    };
  };

  const handleAdd = (newData) => {
    //Taking the old drugss
    let drugs = state.drugs;

    //If it a object then it is coming from select box
    if (newData.value && newData) {
      drugs.push(newData);
    }
    drugs = getUniqueArrayByValue(drugs, "value");
    setState({ ...state, drugs: drugs });
  };

  const handleDelete = (i) => {
    state.drugs.splice(i, 1);
    setState({ ...state });
  };

  const handleClear = () => {
    setState({
      ...state,
      drugs: [],
    });
  };

  React.useEffect(() => {
    appointmentContext.drugs = state.drugs;
    // eslint-disable-next-line
  }, [state]);

  return (
    <div className={classes.rootContainer}>
      <div className={classes.header}>
        <Header
          showBackIcon
          showHomeIcon
          handleBack={goBack}
          handleHome={goHome}
        />
      </div>
      <Slide direction="left" in={true} mountOnEnter unmountOnExit>
        <div className={classes.root} noValidate>
          <Grid>
            <Grid item>
              <MultiSelectDropdown
                label="Are you under medications currently?"
                selectBoxPlaceholder="Eg. Azithromycin (500 mg)"
                selectBoxIsPaginate={true}
                selectBoxOnChange={handleAdd}
                selectBoxLoadOptions={loadDrugs}
                selectBoxIsLoading={loading}
                selectBoxOptions={data?.drugs?.data}
                displaySecTitle="Medications Added"
                displaySecValues={state?.drugs}
                displaySecOnDeleteClick={handleDelete}
                displaySecOnClearAllClick={handleClear}
              />
            </Grid>
          </Grid>
        </div>
      </Slide>
      <div className={classes.footer}>
        <FooterButton
          firstButton="Skip"
          handleClick={goNext}
          btnDisable={state?.drugs?.length > 0 ? false : true}
          secondButton="Continue"
          onClick={goNext}
        />
      </div>
    </div>
  );
};
