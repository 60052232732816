import React from "react";
import { ComponentList } from "./componentList";

class ComponentListParent extends React.Component {
  render() {
    return <ComponentList />;
  }
}

export default ComponentListParent;
