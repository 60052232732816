import React from 'react'
import { makeStyles, SvgIcon } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
    cls1: (props) => ({
        fill: props?.color ? props.color : theme.palette.text.primary
    })
}))

export function Calender({
    color = null,
    cls = '',
}) {
    const classes = useStyles({ color });
    return (
        <SvgIcon viewBox="0 0 16 16" style={{ height: 16 }} fontSize="small">
            <path
                className={`${classes.cls1} ${cls}`}
                d="M8.889,6A2.9,2.9,0,0,0,6,8.889V19.111A2.9,2.9,0,0,0,8.889,22H19.111A2.9,2.9,0,0,0,22,19.111V8.889A2.9,2.9,0,0,0,19.111,6Zm0,1.333H19.111a1.546,1.546,0,0,1,1.556,1.556v.667H7.333V8.889A1.546,1.546,0,0,1,8.889,7.333ZM7.333,10.889H20.667v8.222a1.546,1.546,0,0,1-1.556,1.556H8.889a1.546,1.546,0,0,1-1.556-1.556Zm2.889,1.778a1.111,1.111,0,1,0,1.111,1.111,1.111,1.111,0,0,0-1.111-1.111Zm3.778,0a1.111,1.111,0,1,0,1.111,1.111A1.111,1.111,0,0,0,14,12.667Zm3.778,0a1.111,1.111,0,1,0,1.111,1.111A1.111,1.111,0,0,0,17.778,12.667Zm-7.556,4a1.111,1.111,0,1,0,1.111,1.111A1.111,1.111,0,0,0,10.222,16.667Zm3.778,0a1.111,1.111,0,1,0,1.111,1.111A1.111,1.111,0,0,0,14,16.667Z"
                transform="translate(-6 -6)"
            />
        </SvgIcon>
    )
}

Calender.propTypes = {
    color: PropTypes.string
}
