import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: theme.palette.common.white,
    boxShadow: "none",
    "& .MuiToolbar-gutters": {
      padding: "0px",
    },
  },
  titleContainer: {
    padding: "24px",
  },
  profileIcon: {
    marginRight: "12px",
  },
}));

export const HealthCircle = ({
  onClick = () => false,
  renderProfile = {},
  showInHc = false,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.grow}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <div className={classes.titleContainer}>
            <img src="/images/Healthcircle.svg" alt="HealthCircle" />
          </div>

          <div className={classes.grow} />
          {showInHc && (
            <IconButton aria-label="show 17 new notifications" color="inherit">
              <img src="/images/notification.svg" alt="Notification" />
            </IconButton>
          )}
          <div className={classes.profileIcon}>
            <IconButton
              aria-label="profile button"
              aria-controls={"profile_button"}
              aria-haspopup="true"
              onClick={onClick}
              color="inherit"
            >
              <img src="/images/user.svg" alt="userImage" />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderProfile}
    </div>
  );
};
