import React from "react";

import { TextField, Typography, makeStyles } from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  dobTextfield: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      border: `1.5px solid ${theme.palette.border.light}`,
      borderRadius: 8,
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      paddingTop: "14px",
      paddingBottom: "14px",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1.5px solid #007965",
      borderRadius: 8,
    },
  },
}));

const DOB = (props) => {
  const classes = useStyles();
  const getLabel = (props) => {
    return (
      <Typography variant="body1" style={{ fontSize: 16 }} gutterBottom>
        {props.label}{" "}
        {props.isrequired && (
          <Typography variant="caption" style={{ color: "red" }}>
            *
          </Typography>
        )}
      </Typography>
    );
  };

  const maximumDate = () => {
    return moment(new Date()).format("YYYY-MM-DD");
  };

  return (
    <div id={props.id ?? ""}>
      {getLabel(props)}

      {props.isSelected === "1" && (
        <TextField
          fullWidth
          type={"date"}
          value={props?.value?.dob}
          inputProps={{
            readOnly: props.isReadonly ?? false,
            max: maximumDate(),
          }}
          size="small"
          onChange={(e) =>
            props.onChange({
              dob: e.target.value,
            })
          }
          disabled={props?.disabled ?? false}
          variant={"outlined"}
          className={classes.dobTextfield}
        />
      )}

      {props.isSelected === "2" && (
        <TextField
          fullWidth
          type={"number"}
          required={props.isrequired}
          variant={"outlined"}
          size="small"
          InputProps={{
            endAdornment: (
              <Typography variant="body1" style={{ color: "#0F0B117A" }}>
                Yrs
              </Typography>
            ),
          }}
          className={classes.dobTextfield}
          value={props?.value?.age}
          onChange={(e) =>
            props.onChange({
              age: e.target.value,
            })
          }
        />
      )}
    </div>
  );
};

export default DOB;
