import React from "react";
import { makeStyles, Typography, Grid } from "@material-ui/core";
import { Togglecard, Header } from "../../components";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
import { AppointmentContext, AuthContext } from "../../contexts";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    paddingTop: 80,
  },
  header: {
    position: "fixed",
    zIndex: 1000,
    [theme.breakpoints.up("md")]: {
      width: 768,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

export const ConsultationReason = (props) => {
  const classes = useStyles(props);
  const history = useHistory();
  const appointmentContext = React.useContext(AppointmentContext);
  const auth = React.useContext(AuthContext);
  const goBack = () => {
    history.goBack();
  };

  const goHome = () => {
    history.push(Routes.home);
  };

  const handleHealthIssues = (issue) => {
    appointmentContext.reason_for_consultation = issue;
    appointmentContext.bookedBy = auth?.user?.profile?.id;
    history.push(Routes.addHealthIssues);
  };

  return (
    <>
      <div className={classes.header}>
        <Header
          showBackIcon
          showHomeIcon
          handleBack={goBack}
          handleHome={goHome}
        />
      </div>
      <div className={classes.root} noValidate>
        <Typography variant="body1" color={"textPrimary"}>
          What is the reason for consultation?
        </Typography>
        <Grid>
          <Grid item>
            <Togglecard
              label="Health Issues"
              backgroundColor="#A3E1DC"
              src="/images/Group 4600.svg"
              handleCard={() => handleHealthIssues("Health Issues")}
            />
          </Grid>
          <Grid item>
            <Togglecard
              label="Health Queries"
              backgroundColor="#ECD6E3"
              src="/images/Group 4597.svg"
              handleCard={() => handleHealthIssues("Health Queries")}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
