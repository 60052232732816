import React from "react";
import { withRouter } from "react-router-dom";
import { withNavBars } from "../../HOCs";
import { WeeklyStats } from "./weeklyStats";

class WeeklyStatsParent extends React.Component {
  render() {
    return <WeeklyStats />;
  }
}

export default withRouter(withNavBars(WeeklyStatsParent));
