import React from "react";
import { withRouter } from "react-router-dom";
import { withNavBars } from "../../HOCs";
import { NewHabit } from "./newHabit";

class NewHabitParent extends React.Component {
  render() {
    return <NewHabit />;
  }
}

export default withRouter(withNavBars(NewHabitParent));
