import React from "react";
import {
  makeStyles,
  Grid,
  CardHeader,
  Button,
  Typography,
} from "@material-ui/core";

import Card from "@material-ui/core/Card";

const useStyles = makeStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.border.light}`,
    boxShadow: "0px 10px 15px #00000014",
    borderRadius: "8px",
    padding: 6,
    marginBottom: 8,
    "& .MuiCardHeader-root": {
      padding: "10px",
    },
  },
  root2: {
    boxShadow: "none",
    marginTop: 2,
  },
  header1: {
    borderBottom: `1px dotted ${theme.palette.border.light}`,
    paddingTop: 7,
    paddingBottom: 7,
  },
  bookdiv: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "7px",
  },
  button: {
    width: "102px",
    borderRadius: "8px",
  },
  subText: {
    fontSize: "12px",
    textTransform: "lowercase",
  },
}));
export const Actioncard = ({
  date = "",
  notification = "",
  doctorname = "",
  time = "",
  btnLabel = "",
  subText = "",
  actionCardStatus = null,
  consultation = "",
  consultDoctor="",
  handleClick = () => false,
  handleReport = () => false,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Card className={classes.root}>
        <CardHeader
          className={classes.header1}
          avatar={
            date ? (
              actionCardStatus === 3 || actionCardStatus === 4 ? (
                <img src="/images/icons8-flip-chart.svg" alt="chart" />
              ) : (
                <img src="/images/icons8-date-to.svg" alt="Notify" />
              )
            ) : (
              <img src="/images/icons8-flip-chart.svg" alt="chart" />
            )
          }
          title={
            actionCardStatus === 3 || actionCardStatus === 4 ? (
              <Typography variant="body2" color={"textPrimary"}>
                {consultation}
              </Typography>
            ) : (
              <Typography variant="body2" color={"textPrimary"}>
                {notification}
              </Typography>
            )
          }
        />

        <Card className={classes.root2}>
          <Grid container direction="row">
            <Grid item xs={12}>
              <Grid container direction="row" alignItems="center">
                <Grid item xs={8}>
                  <CardHeader
                    className={classes.header}
                    title={
                      actionCardStatus === 3 || actionCardStatus === 4 ? (
                        <Typography variant="subtitle1" color={"textPrimary"}>
                          Consultation Report
                        </Typography>
                      ) : (
                        <Typography variant="subtitle1" color={"textPrimary"}>
                          {doctorname}
                        </Typography>
                      )
                    }
                    subheader={
                      actionCardStatus === 3 || actionCardStatus === 4 ? (
                        <Typography variant="body2">{consultDoctor}</Typography>
                      ) : (
                        <Typography variant="body2">{time}</Typography>
                      )
                    }
                  />
                </Grid>
                <Grid item xs={4} className={classes.bookdiv}>
                  <Button
                    size="small"
                    variant="outlined"
                    disabled={(actionCardStatus === 1 && subText > 0 ) ? true : false}
                    color="primary"
                    className={classes.button}
                    onClick={() =>
                      actionCardStatus === 3 || actionCardStatus === 4 ? handleReport() : handleClick()
                    }
                  >
                    <div>
                      <div>
                        {actionCardStatus === 3 || actionCardStatus === 4 ? (
                          <div>VIEW</div>
                        ) : (
                          <div>{btnLabel}</div>
                        )}
                      </div>
                      {actionCardStatus === 1 && subText > 0 && (
                        <div className={classes.subText}>in {subText} hrs</div>
                      )}
                    </div>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Card>
    </div>
  );
};
