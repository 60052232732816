import React from "react";
import { withRouter } from "react-router-dom";
import { withNavBars } from "../../HOCs";
import { Routes } from "../../router/routes";
import { LocalStorageKeys } from "../../utils";
import { Login } from "./login";
class LoginParent extends React.Component {

  componentDidMount() {
    if (localStorage.getItem(LocalStorageKeys.authToken)) {
      this.props.history.push(Routes.home)
    }
  }

  render() {
    return <Login />;
  }
}

export default withRouter(withNavBars(LoginParent));
