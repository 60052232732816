import React from "react";
import { makeStyles, Grid, Typography, IconButton } from "@material-ui/core";
import {
  Header,
  FooterButton,
  Upload,
  Textwithcount,
  SimpleModal,
  DocumentViewer,
} from "../../components";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
import {
  AlertContext,
  AppointmentContext,
  FullScreenContext,
} from "../../contexts";
import { AlertProps } from "../../utils";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    height: "100%",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
    margin: 0,
  },
  root: {
    padding: theme.spacing(3),
    paddingTop: 80,
    paddingBottom: "95px",
  },
  header: {
    position: "fixed",
    [theme.breakpoints.up("md")]: {
      width: 768,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  footer: {
    bottom: 0,
    position: "fixed",
    [theme.breakpoints.up("md")]: {
      width: 768,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  content: {
    display: "inline-flex",
    width: "100%",
  },
  cardContainer: {
    cursor: "pointer",
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(2),
    boxShadow: "0px 10px 15px #00000014",
    border: `2px solid ${theme.palette.border.light}`,
    borderRadius: "8px",
  },
  circularLoader: {
    paddingRight: "8px",
    position: "relative",
    "& .MuiCircularProgress-root": {
      position: "relative",
    },
  },
  loadCenter: {
    position: "absolute",
    left: 14,
  },
  uploadText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

export const UploadReports = (props) => {
  const appointmentContext = React.useContext(AppointmentContext);
  const fullScreenLoader = React.useContext(FullScreenContext);
  const alert = React.useContext(AlertContext);

  const classes = useStyles(props);
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [view, setView] = React.useState("");
  const [isLoading, setIsLoading ] = React.useState(false);
  const [uploaddocc, setuploaddocc] = React.useState(
    appointmentContext.reports
  );
  const goBack = () => {
    history.goBack();
  };

  const goHome = () => {
    history.push(Routes.home);
  };

  const goNext = () => {
    history.push(Routes.vitals);
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    fullScreenLoader.setLoader({
      ...fullScreenLoader,
      open: true,
      message: "Uploading report(s)...",
    });

    var formdata = new FormData();
    const files = e.target.files;

    for (let i = 0; i < files.length; i++) {
      formdata.append(`files`, files[i]);
    }

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    let uploads = await fetch(
      "https://storage.healthcircles.care/docs/v1/file/multiple/upload",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => result)
      .catch((error) => {
        console.log("error", error);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          msg: "File Size is Too Large it must be less than 1MB",
        });
      });

    if (uploads?.length) {
      appointmentContext.reports.push(...uploads);
    }

    if (uploaddocc?.length === 10) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        msg: "Exceeded maximum number of files",
      });
    }

    setIsLoading(false);
    fullScreenLoader.setLoader({
      ...fullScreenLoader,
      open: false,
      message: "",
    });
  };

  const handleDelete = (i) => {
    uploaddocc.splice(i, 1);
    setuploaddocc([...uploaddocc]);
  };

  const handleOpen = (i) => {
    setOpen(true);
    setView(i);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.rootContainer}>
      <div className={classes.header} style={{zIndex: isLoading ? "" : 1000}}>
        <Header
          showBackIcon
          showHomeIcon
          handleBack={goBack}
          handleHome={goHome}
        />
      </div>
      <div className={classes.root} noValidate>
        <Typography variant="body1" color={"textPrimary"}>
          Upload reports (If any)
        </Typography>
        <Grid>
          <Grid item>
            <Upload
              disabled={uploaddocc?.length === 10 ? true : false}
              handleUpload={handleUpload}
            />
          </Grid>
          {uploaddocc && uploaddocc.length > 0 && (
            <Grid item>
              <Textwithcount
                text="Uploads"
                count={
                  uploaddocc && uploaddocc.length < 10
                    ? `0${uploaddocc.length}`
                    : uploaddocc.length
                }
              />
            </Grid>
          )}
          {uploaddocc &&
            uploaddocc.length > 0 &&
            uploaddocc.map((item, i) => {
              return (
                <>
                  <Grid
                    container
                    key={i}
                    direction="row"
                    alignItems="center"
                    className={classes.cardContainer}
                  >
                    <Grid item xs={2} style={{ padding: "16px" }}>
                      <img src="/images/icons8-document.svg" alt="NO" />
                    </Grid>
                    <Grid
                      item
                      xs={8}
                      className={classes.uploadText}
                      onClick={() => handleOpen(item?.downloadUrl)}
                    >
                      {/* <a href={item.downloadUrl} target="_blank" rel="noreferrer" style={{ textDecoration: "none" }}> */}
                      <Typography variant="subtitle2" color="textPrimary">
                        {item?.filename}
                      </Typography>
                      <Typography variant="body2" color="textPrimary">
                        {item?.size}
                      </Typography>
                      {/* </a> */}
                    </Grid>
                    <Grid item xs={2} style={{ padding: "8px" }}>
                      <IconButton
                        onClick={() => handleDelete()}
                        aria-label="settings"
                      >
                        <img src="/images/icons8-trash.svg" alt="NO" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </>
              );
            })}
        </Grid>
      </div>
      <SimpleModal
        open={open}
        download={view}
        onClose={handleClose}
        component={<DocumentViewer url={view} />}
      />
      <div className={classes.footer}>
        <FooterButton
          firstButton="Skip"
          handleClick={goNext}
          btnDisable={uploaddocc.length > 0 ? false : true}
          secondButton="Continue"
          onClick={goNext}
        />
      </div>
    </div>
  );
};
