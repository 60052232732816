import React from "react";
import { Header, FooterButton, Profile } from "../../components";
import { ValidateEmail } from "../../utils";
import { Routes } from "../../router/routes";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { AuthContext } from "../../contexts";
import { Updateprofile } from "../../graphql/mutations";
import { useMutation } from "@apollo/client";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  profile: {
    paddingBottom: "95px",
    backgroundColor: theme.palette.background.paper,
    paddingTop: 64,
  },
  header: {
    position: "fixed",
    zIndex: 1000,
    [theme.breakpoints.up("md")]: {
      width: 768,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  footer: {
    bottom: 0,
    position: "fixed",
    [theme.breakpoints.up("md")]: {
      width: 768,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

export const ProfileDetails = () => {
  const history = useHistory();
  const classes = useStyles();
  const auth = React.useContext(AuthContext);
  const [updateProfile, { loading }] = useMutation(Updateprofile);
  const [showInHc] = React.useState(false);
  const [state, setState] = React.useState({
    name: auth?.user?.profile?.name,
    gender: auth?.user?.profile?.gender,
    dob_toogle: "1",
    dob_age: {
      dob: auth?.user?.profile?.dob,
      age: auth?.user?.profile?.age,
    },
    mobile: `${auth?.user?.profile?.mobile_code} ${auth?.user?.profile?.mobile}`,
    mobile_no: auth?.user?.profile?.mobile,
    mobile_code: auth?.user?.profile?.mobile_code,
    email: auth?.user?.profile?.email,
    membership: auth?.user?.profile?.membership_code,
    role_id: 2,
    user_id: "",
    error: {
      name: false,
      gender: false,
      dob_age: false,
      mobile: false,
      email: false,
      membership: false,
    },
  });

  const changeState = (key, value) => {
    if (value !== null) {
      state[key] = value;
      state.error[key] = false;
      setState({
        ...state,
      });
    }
  };

  const validation = () => {
    let emailvalid = ValidateEmail(state?.email) ? true : false;
    let namevalid = state?.name?.length > 0 ? true : false;
    let gendervalid = state?.gender?.length > 0 ? true : false;
    let dobvalid =
      state?.dob_age?.dob?.length > 0 || state?.dob_age?.age > 0 ? true : false;
    if (emailvalid && namevalid && gendervalid && dobvalid) {
      return true;
    } else {
      state.error = {
        email: emailvalid ? false : true,
        name: namevalid ? false : true,
        gender: gendervalid ? false : true,
        dob_age: dobvalid ? false : true,
      };
      setState({ ...state });
    }
  };

  const saveForm = async () => {
    const isValidated = validation();
    if (!isValidated) {
      return false;
    } else {
      updateProfileDetails();
    }
  };

  const updateProfileDetails = () => {
    updateProfile({
      variables: {
        id: auth?.user?.profile?.id,
        firstName: state?.name,
        gender: `${state?.gender}`,
        dob: state?.dob_age?.dob ? state?.dob_age?.dob : null,
        age: state?.dob_age?.age ? Number(state?.dob_age?.age) : null,
        emailId: state?.email,
        membershipCode: state?.membership,
        updatedAt: new Date().toISOString(),
        updatedBy: auth?.user?.profile?.id,
      },
    });
  };

  const goBack = () => {
    history.goBack();
  };
  const goHome = () => {
    history.push(Routes.home);
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Header
          showBackIcon
          showHomeIcon
          handleBack={goBack}
          handleHome={goHome}
        />
      </div>
      <div className={classes.profile}>
        <Profile
          {...state}
          changeState={changeState}
          profileText="Profile"
          hideRelationshipName
          hideRelationship
          hideEnterMobileNo
          showInHc={showInHc}
        />
      </div>
      <div className={classes.footer}>
        <FooterButton
          firstButton="Cancel"
          secondButton="Save"
          handleClick={goBack}
          btnDisable={loading}
          isLoading={loading}
          loadingMessage={"Saving..."}
          onClick={saveForm}
        />
      </div>
    </div>
  );
};
