import gql from "graphql-tag";
import moment from "moment";

export const getAllMasterAllergies = () => {
  return gql`
    query allMasterAllergies($limit: Int, $offset: Int, $search: String) {
      allergies: allMasterAllergies(
        condition: { isActive: true }
        first: $limit
        offset: $offset
        filter: { allergy: { startsWithInsensitive: $search } }
      ) {
        data: nodes {
          value: id
          label: allergy
        }
        pageInfo {
          hasNextPage
        }
      }
    }
  `;
};

export const getMasterSymptoms = () => {
  return gql`
    query allMasterSymptoms($limit: Int, $offset: Int, $search: String) {
      symptoms: allMasterSymptoms(
        condition: { isActive: true }
        first: $limit
        offset: $offset
        filter: { symptoms: { startsWithInsensitive: $search } }
      ) {
        data: nodes {
          value: id
          label: symptoms
        }
        pageInfo {
          hasNextPage
        }
      }
    }
  `;
};

export const getMasterHealthProblems = () => {
  return gql`
    query allMasterHealthProblems($limit: Int, $offset: Int, $search: String) {
      healthProblem: allMasterHealthProblems(
        condition: { isActive: true }
        first: $limit
        offset: $offset
        filter: { healthProblem: { startsWithInsensitive: $search } }
      ) {
        data: nodes {
          value: id
          label: healthProblem
        }
        pageInfo {
          hasNextPage
        }
      }
    }
  `;
};

export const getMasterDrugs = () => {
  return gql`
    query allMasterDrugs($limit: Int, $offset: Int, $search: String) {
      drugs: allMasterDrugs(
        condition: { isActive: true }
        first: $limit
        offset: $offset
        filter: { drugName: { startsWithInsensitive: $search } }
      ) {
        data: nodes {
          value: id
          label: drugName
        }
        pageInfo {
          hasNextPage
        }
      }
    }
  `;
};

export const getMasterRelationship = () => {
  return gql`
    query allMasterRelationships {
      relationship: allMasterRelationships(condition: { isActive: true }) {
        data: nodes {
          value: id
          label: releationship
        }
      }
    }
  `;
};

export const getTodayAppointments = (profile_id) => {
  return gql`
  query todayAppointments {
    allAppointments(
      condition: {
        isActive: true,
        appointmentDate:"${moment.utc().format("YYYY-MM-DD")}"
      }
      filter: {and: {appointmentStatus: {in: [1, 3, 4]}},
        or: [
          {
            bookedBy: { equalTo: "${profile_id}" }
          },
          {
            bookingFor: { equalTo: "${profile_id}" }
          }
        ]
      }
    ) {
      appointments:nodes {
        id
        appointmentDateTime
        meetUrl
        isSelfBooking
        doctorDetail:userProfileByAssignedDoctor{
          name:firstName
        }
        bookingFor:userProfileByBookingFor{
          name:firstName
        }      
        myDetail:userProfileByCreatedBy{
          name:firstName
        }
        visitSummariesByAppointmentId {
          Report: nodes {
            visitReportUrl
          }
        }
        appointmentDate
        appointmentTimeSlot
        appointmentStatus
        createdAt
        updatedAt
      }
    }
  }
  
  `;
};

export const getUpcomingAppointments = (profile_id, search) => {
  return gql`
  query upcomingAppointments {
    allAppointments(
      condition: {
        isActive: true
        appointmentStatus: 1
      }
      filter: {
        or: [
          {
            bookedBy: { equalTo: "${profile_id}" }
          },
          {
            bookingFor: { equalTo: "${profile_id}" }
          }
        ],
        appointmentDateTime: {greaterThanOrEqualTo: "${moment
          .utc()
          .format("YYYY-MM-DD HH:mm:ss")}"}
, userProfileByAssignedDoctor: {firstName: {startsWithInsensitive: "${search}"}}}
orderBy: APPOINTMENT_DATE_TIME_DESC
    ) {
      appointments:nodes {
        id
        isSelfBooking
        appointmentDateTime
        meetUrl
        eventId
        doctorDetail:userProfileByAssignedDoctor{
          id
          name:firstName
          profilePic: profilePic
          consulationCharge
        }
        bookingFor:userProfileByBookingFor{
          name:firstName
        }      
        myDetail:userProfileByCreatedBy{
          name:firstName
        }
        appointmentDate
        appointmentTimeSlot
        createdAt
        updatedAt
      }
    }
  }
  
  `;
};

export const getPastAppointments = (profile_id, search) => {
  return gql`
  query pastAppointments {
    allAppointments(
      condition: {
        isActive: true
      }
      filter: {
        or: [
          {
            bookedBy: { equalTo: "${profile_id}" }
          },
          {
            bookingFor: { equalTo: "${profile_id}" }
          }
        ],
        appointmentDateTime: {lessThan: "${moment
          .utc()
          .format(
            "YYYY-MM-DD HH:mm:ss"
          )}"}, userProfileByAssignedDoctor: {firstName: {startsWithInsensitive: "${search}"}}
      }
      orderBy: APPOINTMENT_DATE_TIME_DESC
    ) {
      appointments:nodes {
        id
        appointmentDateTime
        isSelfBooking
        status:masterAppointmentStatusByAppointmentStatus {
          appointmentStatus
        }
        doctorDetail:userProfileByAssignedDoctor{
          name:firstName
          profilePic: profilePic
          consulationCharge
        }
        bookingFor:userProfileByBookingFor{
          name:firstName
        }      
        myDetail:userProfileByCreatedBy{
          name:firstName
        }
        appointmentDate
        appointmentTimeSlot
        appointmentStatus
        createdAt
        updatedAt
      }
    }
  }
  
  `;
};

export const getCaseSummary = (profile_id, search) => {
  return gql`
  query myCaseSummary {
    allAppointments(condition: {isActive: true}
      filter: {
        or: [
          {
            bookedBy: { equalTo: "${profile_id}" }
          },
          {
            bookingFor: { equalTo: "${profile_id}" }
          }
        ],
        and: {appointmentStatus: {in: [3, 4]}},
        ${
          search
            ? `userProfileByAssignedDoctor: {firstName: {startsWithInsensitive: "${search}"}}`
            : ``
        }
      }
      orderBy: APPOINTMENT_DATE_DESC
      ) {
      caseSummary: nodes {
        userProfileByBookingFor {
          firstName
        }
        assignedDoctor
        appointmentDate
        appointmentTimeSlot
        appointmentStatus
        userProfileByAssignedDoctor {
          id
          firstName
          profilePic
        }
        visitSummariesByAppointmentId {
          Report: nodes {
            visitReportUrl
          }
        }
      }
    }
  }
  
  `;
};

export const getFollowUp = (profileId) => {
  return gql`
  query myFollowUp {
    allAppointments(
      condition: {
        isActive: true
        appointmentStatus: 4
      }
      filter: {
      or: [
        {
          bookedBy: { equalTo: "${profileId}" }
        },
        {
          bookingFor: { equalTo: "${profileId}" }
        }
      ]
    }
    ) {
      followUp:nodes {
        assignedDoctor
        appointmentDateTime
        id
        isSelfBooking
        followUpdetails:visitSummariesByAppointmentId(
          filter: { followupDateTime: { greaterThanOrEqualTo: "${moment
            .utc()
            .format("YYYY-MM-DD HH:mm:ss")}" },
           }
          orderBy: FOLLOWUP_DATE_TIME_ASC
        ) {
          followUpprofile:nodes {
            followupDateTime
            visitReportUrl
            Doctor:userProfileByAttendedDoctor {
              firstName
              consulationCharge
              id
              profilePic
            }
            userProfileByPatientId {
              firstName
              id
              gender
              age
              mobileNoCountryCode
              mobileNo
            }
          }
        }
      }
    }
  }
  
  `;
};

export const getAppointmentSummaryView = (appointementId) => {
  return gql`
    query myAppointmentSummary {
      allAppointments(
        condition: {isActive: true, id: "${appointementId}"}
      )
       {
        appointment: nodes {
          id
          appointmentStatus
          appointmentDate
          appointmentDateTime
          appointmentTimeSlot
          userProfileByAssignedDoctor {
            firstName
            consulationCharge
            profilePic
          }
          userProfileByBookingFor {
            firstName
            gender
            id
            age
            mobile
            patientActiveMedicationsByPatientId {
              Medications:  nodes {
                symptom:  masterDrugByDrugId {
                  label: drugName
                  }
                }
              }
          }
          healthIssues
          appointmentSymptomsByAppointmentId {
           symptoms: nodes {
            symptom:  masterSymptomBySymptomId {
                label: symptoms
              }
            }
          }
          appointmentHealthProblemsByAppointmentId {
           HealthProblems: nodes {
            symptom: masterHealthProblemByHealthProblemId {
              label: healthProblem
              }
            }
          }
          appointmentAllergiesByAppointmentId {
          Allergies:  nodes {
            symptom:  masterAllergyByAllergyId {
              label: allergy
              }
            }
          }
          appointmentUploadsByAppointmentId {
            UploadDetails: nodes {
            Upload:  uploadByUploadId {
                uploadData
              }
            }
          }
          patientVitalByVitals {
            bpDia
            bpSys
            tempInF
            pulsBpm
            heightInCm
            weightInCm
            o2Level
          }
        }
      }
    }
    `;
};

export const getRelations = (profileId) => {
  return gql`
  query myRelations {
    allUserProfiles(
      condition: {
        isActive: true
        releationOf: "${profileId}"
      }
    ) {
      data:nodes {
        id
        firstName
        emailId
        gender
        age
        dob
        mobileNo
        mobileNoCountryCode
        releationship
        relations: masterRelationshipByReleationship {
          value: id
          label: releationship
        }
      }
    }
  }
  
  `;
};

export const getMasterMind = () => {
  return gql`
    query mindHabit {
      allMasterHabits(
        condition: { isActive: true }
        filter: { type: { equalTo: MIND } }
      ) {
        data: nodes {
          habitName
          type
          id
        }
      }
    }
  `;
};

export const getMasterBody = () => {
  return gql`
    query bodyHabit {
      allMasterHabits(
        condition: { isActive: true }
        filter: { type: { equalTo: BODY } }
      ) {
        data: nodes {
          habitName
          type
          id
        }
      }
    }
  `;
};

export const getMasterFood = () => {
  return gql`
    query foodHabit {
      allMasterHabits(
        condition: { isActive: true }
        filter: { type: { equalTo: FOOD } }
      ) {
        data: nodes {
          habitName
          type
          id
        }
      }
    }
  `;
};

export const getMasterSmoking = () => {
  return gql`
    query smokingHabit {
      allMasterHabits(
        condition: { isActive: true }
        filter: { type: { equalTo: STOP_SMOKING } }
      ) {
        data: nodes {
          habitName
          type
          id
        }
      }
    }
  `;
};

export const getMasterPhysical = () => {
  return gql`
    query physicalHabit {
      allMasterHabits(
        condition: { isActive: true }
        filter: { type: { equalTo: BE_PHYSICALLY_ACTIVE } }
      ) {
        data: nodes {
          habitName
          type
          id
        }
      }
    }
  `;
};

export const getMasterEatHealthy = () => {
  return gql`
    query eatHealthyHabit {
      allMasterHabits(
        condition: { isActive: true }
        filter: { type: { equalTo: EATING_HEALTHY } }
      ) {
        data: nodes {
          habitName
          type
          id
        }
      }
    }
  `;
};


export const getHabitActivity = (profileId) => {
  return gql`
    query habitActivity {
      allUserHabitActivities(
        condition: {
          isActive: true
          userId: "${profileId}"
        }
        orderBy: START_DATE_ASC
      ) {
        data: nodes {
          id
          isCompleted
          dateTime: startDate
          userHabits: userHabitByUserHabitId {
            endDate
            startDate
            habits: customHabitByCustomHabitId {
              habitName
            }
            habit: masterHabitByHabitId {
              habitName
            }
          }
        }
      }
    }
  `;
};

export const getActivity = (activityId) => {
  return gql`
  query MyQuery {
    Habits:userHabitActivityById(id: "${activityId}") {
      time:startDate
      data:userHabitByUserHabitId {
        endDate
        habit:customHabitByCustomHabitId {
          habitName
        }
        habits:masterHabitByHabitId {
          habitName
        }
      }
    }
  }
  `;
};
