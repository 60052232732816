import React from "react";
import { Themes, AlertProps, DrawerProps } from "../utils";

/**
 * ThemeContext store the current theme of the app,which is provided
 * at the /src/App.js using the /src/App.theme.js.
 */
export let ThemeContext = React.createContext({
  name: Themes.default,
  setTheme: () => null,
});

/**
 * AuthContext store some basic detail of the user when the user logged
 * into the application, which is provided at the /src/App.js using
 * the /src/App.auth.js.
 */
export let AuthContext = React.createContext({
  reload: () => null,
  user: {},
  setAuth: () => null,
});

/**
 * AlertContext store the props which are neccessary to show the Alert component,
 * which is provided at the /src/App.js using the /src/App.alert.js.
 */
export let AlertContext = React.createContext({
  open: false,
  severity: AlertProps.severity.success,
  msg: "",
  vertical: AlertProps.vertical.top,
  horizontal: AlertProps.horizontal.center,
  onclose: () => null,
  setSnack: () => null,
});

/**
 * DialogContext store the props of a Dialog, which is provided at the /src/App.js using
 * the /src/App.dialog.js
 */
export let DialogContext = React.createContext({
  open: true,
  title: "",
  body: "",
  positiveBtn: "Ok",
  negativeBtn: "Cancel",
  onOk: () => null,
  setDialog: () => null,
});

/**
 * BackdropContext store the props of the Backdrop Component, which is provided at the /src/App.js using
 * the /src/App.backdrop.js
 */
export let BackdropContext = React.createContext({
  open: true,
  message: "",
  setBackDrop: () => null,
});

/**
 * DrawerContext store the props of the Drawer component, which is provided at the /src/App.js using
 * the /src/App.drawer.js
 */
export const DrawerContext = React.createContext({
  open: true,
  drawerClassName: false,
  direction: DrawerProps.direction.right,
  variant: DrawerProps.variant.temporary,
  onClose: () => null,
  setDrawer: () => null,
});

/**
 * FullScreenContext store the props of the FullScreen Component, which is provided at the /src/App.js using
 * the /src/App.fullScreenLoader.js
 */
export let FullScreenContext = React.createContext({
  open: true,
  message: "",
  setLoader: () => null,
});

/**
 * AppointmentContext store the props of the FullScreen Component
 */
export let AppointmentContextIntializer = {
  is_self_booking: null,
  booking_for: {
    id: null,
    releation_id: null,
    name: {},
    gender: null,
    dob_age: {},
    releationship: {},
    mobile: {},
    email: null,
    error: {
      name: false,
      gender: false,
      dob_age: false,
      mobile: false,
      email: false,
      releationship: false,
    },
  },
  reason_for_consultation: null,
  health_issues: [],
  symptoms: [],
  health_problems: [],
  allergies: [],
  drugs: [],
  appointed_doctor_details: {
    doctor_name: null,
    doctor_fee: null,
    doctor_profile_pic: null,
    appointment_date: null,
    appointment_time_slot: null,
  },
  reports: [],
  vitals: {
    height_in_cm: null,
    weight_in_cm: null,
    bmi_in_kg_m2: null,
    temp_in_f: null,
    puls_bpm: null,
    bp_sys: null,
    bp_dia: null,
    o2_level: null,
  },
  bookedBy: null,
  dateFilter: 'today',
  customDate: null,
  data: [],
  selectedDoctor: null
};

export let AppointmentContext = React.createContext(
  AppointmentContextIntializer
);

/* 
Firebase context is for getting a token,deleting a token and requesting permission.
**/
export let FirebaseContext = React.createContext({
  token: "",
  isTokenFound: false,
  getToken: () => false,
  requestPermission: () => false,
  deleteToken: () => false
})
