import React from "react";
import { makeStyles, Typography, Grid } from "@material-ui/core";
import { Togglecard, Header } from "../../components";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
import { AppointmentContext, AuthContext } from "../../contexts";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    paddingTop: 80,
  },
  header: {
    position: "fixed",
    zIndex: 1000,
    [theme.breakpoints.up("md")]: {
      width: 768,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

export const SelectPatient = (props) => {
  const classes = useStyles(props);
  const history = useHistory();
  const auth = React.useContext(AuthContext);
  const appointmentContext = React.useContext(AppointmentContext);

  const goBack = () => {
    history.goBack();
  };

  const goHome = () => {
    history.push(Routes.home);
  };

  const handleMyself = () => {
    appointmentContext.is_self_booking = true;
    appointmentContext.booking_for["id"] = auth?.user?.profile?.id;
    history.push(Routes.consultationReason);
  };

  const handleOthers = () => {
    appointmentContext.is_self_booking = false;
    appointmentContext.booking_for["id"] = "";
    history.push(Routes.patientDetails);
  };

  return (
    <>
      <div className={classes.header}>
        <Header
          showBackIcon
          showHomeIcon
          handleBack={goBack}
          handleHome={goHome}
        />
      </div>
      <div className={classes.root} noValidate>
        <Typography variant="body1" color={"textPrimary"}>
          Who are you booking for?
        </Typography>
        <Grid>
          <Grid item>
            <Togglecard
              label="Myself"
              backgroundColor="#FFD8BE"
              src="/images/Group 4590.svg"
              handleCard={handleMyself}
            />
          </Grid>
          <Grid item>
            <Togglecard
              label="Others"
              backgroundColor="#CCE2CB"
              src="/images/Group 4601.svg"
              handleCard={handleOthers}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
