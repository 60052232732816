import React from "react";
import { makeStyles, Typography, Grid } from "@material-ui/core";
import { Input, TabSelect } from "../../components";
import { habitNewCategories, Message } from "../../utils";
import moment from "moment";
import TimePicker from "./timePicker";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    height: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export const HabitInputs = ({
  custom_habit_name = "",
  custom_habit_type = "",
  start_date = "",
  end_date = "",
  activity_per_day = "",
  remind_before = "",
  motivation_message = "",
  attitude = "",
  subjective_norm = "",
  perceived_behavioral_norm = "",
  error = {},
  habitName = "",
  habitCategory = [],
  selectedTime = [],
  activityInputs = {},
  changeState = () => false,
  changeActivity = () => false,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.rootContainer}>
      <Grid container direction="row">
        <Grid item xs={12}>
          <Input
            label={"Name"}
            isRequired
            type={"text"}
            onChange={(value) => changeState("custom_habit_name", value)}
            error={error.custom_habit_name}
            disable={habitName ? true : false}
            helperText={
              error.custom_habit_name === true ? Message.requiredMessage : ""
            }
            value={custom_habit_name}
            disableBackground={habitName}
          />
        </Grid>
        <Grid item xs={12}>
          <TabSelect
            label={"Category"}
            isrequired
            toggleValue={custom_habit_type}
            items={habitCategory?.length > 0 ? habitCategory : habitNewCategories}
            onClick={(value) => changeState("custom_habit_type", value)}
            error={error.custom_habit_type}
            helperText={
              error.custom_habit_type === true ? Message.requiredMessage : ""
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" style={{ width: "100%" }}>
            <Grid item style={{ marginRight: "2%", width: "48%" }}>
              <Input
                label={"Start Date & End Date"}
                isRequired
                type={"date"}
                onChange={(value) => changeState("start_date", value)}
                error={error.start_date}
                min={moment().format("YYYY-MM-DD")}
                helperText={
                  error.start_date === true ? Message.requiredMessage : ""
                }
                value={start_date}
              />
            </Grid>
            <Grid
              item
              style={{ marginLeft: "2%", width: "48%", marginTop: "24px" }}
            >
              <Input
                type={"date"}
                onChange={(value) => changeState("end_date", value)}
                min={
                  start_date
                    ? moment(start_date).format("YYYY-MM-DD")
                    : moment().format("YYYY-MM-DD")
                }
                error={error.end_date}
                helperText={
                  error.end_date === true ? Message.requiredMessage : ""
                }
                value={end_date}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="body2" color="primary" align="left" gutterBottom>
            Activity Per Day &nbsp;
            <Typography color="error" variant="caption">
              *
            </Typography>
          </Typography>
          <div style={{ width: "25%" }}>
            <Input
              type={"number"}
              onChange={(value) => changeState("activity_per_day", value)}
              error={error.activity_per_day}
              helperText={
                error.activity_per_day === true ? Message.requiredMessage : ""
              }
              value={activity_per_day}
            />
          </div>
        </Grid>
        {activityInputs?.map((item, i) => (
          <Grid item xs={12}>
            <TimePicker
              selectedTime={selectedTime[item?.id - 1] ?? null}
              onChange={changeActivity}
              itemId={item?.id}
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <TabSelect
            isrequired
            label={"Remind Before"}
            toggleValue={remind_before}
            items={[
              {
                value: "dont_remind",
                label: "Don't Remind",
              },
              {
                value: "15 Mins",
                label: "15 Mins",
              },
              {
                value: "30 Mins",
                label: "30 Mins",
              },
              {
                value: "45 Mins",
                label: "45 Mins",
              },
              {
                value: "1 Hour",
                label: "1 Hour",
              },
            ]}
            onClick={(value) => changeState("remind_before", value)}
            error={error.remind_before}
            helperText={
              error.remind_before === true ? Message.requiredMessage : ""
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            label={"Motivation Message"}
            type={"text"}
            onChange={(value) => changeState("motivation_message", value)}
            value={motivation_message}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            label={"Attitude"}
            type={"text"}
            onChange={(value) => changeState("attitude", value)}
            value={attitude}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            label={"Subjective Norm"}
            type={"text"}
            onChange={(value) => changeState("subjective_norm", value)}
            value={subjective_norm}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            label={"Perceived Behavioral Norm"}
            type={"text"}
            onChange={(value) =>
              changeState("perceived_behavioral_norm", value)
            }
            value={perceived_behavioral_norm}
          />
        </Grid>
      </Grid>
    </div>
  );
};
