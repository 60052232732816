import React from "react";
import { withRouter } from "react-router-dom";
import { withNavBars } from "../../HOCs";
import { Routes } from "../../router/routes";
import { LocalStorageKeys } from "../../utils";
import { OTPVerifier } from "./otpVerifier";

class OTPVerifierParent extends React.Component {

    componentDidMount() {
        if (localStorage.getItem(LocalStorageKeys.authToken)) {
            this.props.history.push(Routes.home)
        }
    }

    render() {

        const {
            mobile = "",
            mobile_code = ""
        } = this.props.location?.state ?? {};

        return <OTPVerifier mobile={mobile} mobile_code={mobile_code} />;
    }
}

export default withRouter(withNavBars(OTPVerifierParent));
