import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  textContainer:{
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  }
}));
export const TextwithTextcount = ({ firstText = "", textCount = "" }) => {
  const classes = useStyles();

  return (
    <div className={classes.textContainer}>
      <Typography variant="subtitle1" color="primary">{firstText}</Typography>
      <Typography variant="body2" color="textSecondary">&nbsp;{`(${textCount} Habits)`}</Typography>
    </div>
  );
};
