import React from "react";
import { withRouter } from "react-router-dom";
import { withNavBars } from "../../HOCs";
import { GoalTracker } from "./goalTracker";

class GoalTrackerParent extends React.Component {
  render() {
    return <GoalTracker />;
  }
}

export default withRouter(withNavBars(GoalTrackerParent));
