import React from "react";
import { Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%"
  },
  contentParent: {
    height: '100%',
    overflow: "auto",
    backgroundColor: theme.palette.background.default
  },
  content: {
    [theme.breakpoints.up("md")]: {
      width: 768
    },
    [theme.breakpoints.down("sm")]: {
      width: '100%'
    },
    height: '100%'
  }
}));

const withNavBars = (Component) => (props) => {

  const classes = useStyles({ props });

  return (
    <div className={classes.root}>
      {/* Content */}
      <Grid
        className={classes.contentParent}
        container
        direction={"row"}
        justify={"center"}
        alignItems={"center"}
      >
        <Grid item className={classes.content}>
          <Component {...props}>{props.children}</Component>
        </Grid>
      </Grid>
    </div>
  );
};

export default withNavBars;
