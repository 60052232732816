import React from "react";
import { makeStyles, Card, CardHeader, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  card: {
    cursor: (props) => (props.status === true || props.status === false ? "" : "pointer"),
    background: (props) => (props.cardType || props.status ? "#F5F5F5" : ""),
    borderRadius: (props) => (props.cardType || props.status ? "8px" : ""),
    boxShadow: "none",
    marginBottom: "8px",
    "& .MuiCardHeader-root": {
      padding: (props) => (props.cardType ? "" : 0),
    },
    "& .MuiCardHeader-content": {
      padding: (props) => (props.status ? "16px" : 0),
    },
    "& .MuiCardHeader-action": {
      paddingRight: (props) =>
        props.status ? "16px" : props.cardType ? "8px" : 0,
    },
  },
  cardActions: {
    padding: "8px",
    marginTop: (props) => (props.status ? "" : "8px"),
    paddingRight: "8px",
  },
}));

export const Textwithbutton = (props) => {
  const classes = useStyles(props);

  return (
    <Card
      className={classes.card}
      onClick={props.status === true || props.status === false ? "" : props.onClick}
    >
      <CardHeader
        action={
          <div className={classes.cardActions}>
            {props.status === true ? (
              <div style={{ textAlign: "right" }}>
                <img src="/images/completedstatus.svg" alt="No" />
                <Typography variant="body2">{props.statusText}</Typography>
              </div>
            ) : 
            props.status === false ? (
              <div style={{ textAlign: "right" }}>
                {/* <img src="/images/completedstatus.svg" alt="No" /> */}
                <Typography variant="body2">Not Completed</Typography>
              </div>) :
            (
              props.actions
            )}
          </div>
        }
        title={props.cardText}
        subheader={props.subText && <> {props.subText} </>}
      />
    </Card>
  );
};
