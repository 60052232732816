import React from "react";
import {
  makeStyles,
  Chip,
  Typography,
  Avatar,
  Grid,
  IconButton,
} from "@material-ui/core";

import clsx from "clsx";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
// import GetAppIcon from "@material-ui/icons/GetApp";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //maxWidth: 360,
    border: "1px dashed #C5C5C5",
    borderTop: 0,
    backgroundColor: theme.palette.background.paper,
    "& .MuiListItem-gutters": {
      paddingLeft: "12px",
      paddingRight: "12px",
      paddingBottom: "3px",
      paddingTop: "3px",

      "& .MuiListItem-root": {
        "& .MuiListItemText-root": {
          "& .MuiTypography-body1": {
            fontSize: "12px",
            textTransform: "uppercase",
            color: theme.palette.text.primary,
          },
        },
      },
    },

    "& .MuiListItemIcon-root": {
      minWidth: "25px",
    },
  },
  nested: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: 0,
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  chip: {
    height: "24px",
    borderRadius: "5px",
    backgroundColor: "#F5F5F5",
    border: "1px solid #18445714",
    marginLeft: "10px",
    marginBottom: "5px",
    "& .MuiChip-label": {
      padding: 5,
      color: "#0F0B117A",
      fontSize: "12px",
      fontFamily: "healthcircles_medium",
    },
  },
  symptomchip: {
    height: "28px",
    borderRadius: "4px",
    marginRight: "10px",
    marginBottom: "8px",
    background: "#18445714 0% 0% no-repeat padding-box",
    border: "1px solid #18445728",
    opacity: 1,
    "& .MuiChip-label": {
      paddingLeft: "7px",
      paddingRight: "7px",
    },
  },
  primaryText: {
    fontSize: "14px",
    fontFamily: "healthcircles_medium",
    marginLeft: "3px",
  },
  primarydiv: {
    display: "flex",
    "& .MuiTypography-body2": {
      fontSize: "12px",
    },
  },
  divider: {
    borderBottom: "1px dashed #C5C5C5",
  },
  healthissue: {
    "& .MuiListItemText-root": {
      color: theme.palette.text.primary,
      fontSize: "14px",
    },
    "& .MuiTypography-body1": {
      color: "#0F0B11",
      fontSize: "14px",
      fontFamily: "healthcircles_medium",
    },
  },
  symptomdiv: {
    display: "flex",
  },
  docdiv: {
    display: "flex",
    fontSize: "14px",
    color: theme.palette.text.primary,
  },
  listTitle: {
    "& .MuiTypography-body1": {
      color: "#184457B7",
      fontSize: "12px",
      fontFamily: "healthcircles_medium",
      textTransform: "uppercase",
    },
  },
  countchip: {
    width: "20px",
    height: "20px",
    fontSize: "10px",
    padding: "5px",
    marginTop: "3px",
    backgroundColor: "#18445714",
    color: "#0F0B117A",
  },
}));

export const Cardsection = ({
  Header = "",
  headericon = "",
  primarytext = "",
  secondartext = "",
  label = "",
  vitals = "",
  hidevitals = false,
  hideEdit = false,
  healthissue = [],
  symptoms = [],
  documents = [],
  onClick = () => false,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <List
      component="div"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      <ListItem>
        <ListItemIcon>
          <img src={headericon} alt="edit" />
        </ListItemIcon>
        <ListItemText className={classes.listTitle} primary={Header} />
        {!hideEdit &&
        <ListItemIcon
          onClick={() => onClick(Header)}
          style={{ cursor: "pointer" }}
        >
          <img src="/images/icons8-edit.svg" alt="edit" />
        </ListItemIcon>}

        <ListItemIcon
          style={{ cursor: "pointer" }}
          className={clsx(classes.expand, {
            [classes.expandOpen]: open,
          })}
          onClick={handleClick}
        >
          <img src="/images/Group 5083.svg" alt="arrow" />
        </ListItemIcon>
      </ListItem>
      {/* primary text and seconday text */}
      {primarytext && secondartext && (
        <List component="div" disablePadding>
          <ListItem button className={classes.nested}>
            <ListItemText
              primary={
                <div className={classes.primarydiv}>
                  <Typography
                    className={classes.primaryText}
                    color="textPrimary"
                  >
                    {primarytext}
                  </Typography>

                  <Chip
                    className={classes.chip}
                    variant="outlined"
                    label={label}
                  />
                </div>
              }
              secondary={
                <Typography className={classes.primaryText}>
                  {secondartext}
                </Typography>
              }
            />
          </ListItem>
        </List>
      )}
      {/* health issue array */}
      {healthissue?.length > 0 && (
        <List component="div" disablePadding>
          <ListItem className={classes.healthissue}>
            <ListItemText
              className={classes.primaryText}
              primary={healthissue ? `• ${healthissue[0]}` : ""}
            />
          </ListItem>
        </List>
      )}
      {/* symptoms array */}
      {!open && symptoms?.length > 0 && (
        <List component="div" disablePadding>
          <ListItem className={classes.healthissue}>
            <ListItemText
              primary={
                <div className={classes.symptomdiv}>
                  <div>
                    {symptoms?.slice(0, 2).map((v, i) => {
                      return (
                        <Chip
                          key={i}
                          className={classes.symptomchip}
                          variant="outlined"
                          label={<Typography variant="subtitle1" color="textPrimary">{v?.symptom?.label ? v?.symptom?.label : v.label}</Typography>}
                        />
                      );
                    })}
                  </div>
                  <div>
                    {symptoms?.length > 2 && (
                      <Avatar className={classes.countchip} sizes="small">
                        +{symptoms?.length - 2}
                      </Avatar>
                    )}
                  </div>
                </div>
              }
            />
          </ListItem>
        </List>
      )}
      {/* documents array */}
      {!open && documents?.length > 0 && (
        <List component="div" disablePadding>
          <ListItem className={classes.healthissue}>
            <ListItemText
              primary={
                <div className={classes.docdiv}>
                  <div>{documents[0].Upload?.uploadData ? JSON.parse(documents[0].Upload?.uploadData).filename : documents[0].filename}</div>
                  <div>
                    {documents?.length > 1 && (
                      <Avatar className={classes.countchip} sizes="small">
                        +{documents?.length - 1}
                      </Avatar>
                    )}
                  </div>
                </div>
              }
            />
          </ListItem>
        </List>
      )}
      {/* Vitals */}
      {hidevitals && (
        <List component="div" disablePadding>
          <ListItem className={classes.healthissue}>
            <ListItemText
              primary={
                <Grid container>
                  <Grid item>
                    {vitals?.height_in_cm ? (
                      <span>
                        &nbsp;{vitals?.height_in_cm}&nbsp;cm
                        <span style={{ color: "#0F0B117A" }}>&nbsp;(Height)</span>
                        ,
                      </span>
                    ) : null}
                    {vitals?.weight_in_cm ? (
                      <span>
                        &nbsp;{vitals?.weight_in_cm}&nbsp;kg
                        <span style={{ color: "#0F0B117A" }}>&nbsp;(Weight)</span>
                        ,
                      </span>
                    ) : null}
                  </Grid>
                  <Grid item>
                    {vitals?.temp_in_f ? (
                      <span>
                        &nbsp;{vitals?.temp_in_f}&nbsp;F
                        <span style={{ color: "#0F0B117A" }}>&nbsp;(Temp)</span>,
                      </span>
                    ) : null}
                    {vitals?.puls_bpm ? (
                      <span>
                        &nbsp;{vitals?.puls_bpm}&nbsp;bpm
                        <span style={{ color: "#0F0B117A" }}>&nbsp;(Pulse)</span>,
                      </span>
                    ) : null}
                  </Grid>
                  <Grid item>
                    {vitals?.bp_sys ? (
                      <span>
                        &nbsp;{vitals?.bp_sys}/{vitals?.bp_dia}&nbsp;mmHg
                        <span style={{ color: "#0F0B117A" }}>(BP)</span>,
                      </span>
                    ) : null}
                    {vitals?.o2_level ? (
                      <span>
                        &nbsp;{vitals?.o2_level}&nbsp;%
                        <span style={{ color: "#0F0B117A" }}>&nbsp;(SPO2)</span>,
                      </span>
                    ) : null}
                  </Grid>
                </Grid>
              }
            />
          </ListItem>
        </List>
      )}

      <Collapse in={open} timeout="auto" unmountOnExit>
        {/* health issue array */}
        {healthissue?.length > 0 && (
          <List component="div" disablePadding>
            {healthissue.slice(1, healthissue.length)?.map((v, i) => {
              return (
                <ListItem key={i} className={classes.healthissue}>
                  <ListItemText
                    className={classes.primaryText}
                    primary={`• ${v}`}
                  />
                </ListItem>
              );
            })}
          </List>
        )}
        {/* symptoms array */}
        {symptoms?.length > 0 && (
          <List component="div" disablePadding>
            <ListItem className={classes.healthissue}>
              <ListItemText
                primary={
                  <div className={classes.symptomdiv}>
                    <div>
                      {symptoms?.map((v, i) => {
                        return (
                          <Chip
                            key={i}
                            className={classes.symptomchip}
                            variant="outlined"
                            label={v?.symptom?.label ? v?.symptom?.label : v.label}
                          />
                        );
                      })}
                    </div>
                  </div>
                }
              />
            </ListItem>
          </List>
        )}
        {/* documents array */}
        {documents?.length > 0 && (
          <List component="div" disablePadding>
            {documents?.map((doc, i) => {
              return (
                <ListItem key={i} className={classes.healthissue}>
                  <ListItemText
                    className={classes.docdiv}
                    primary={doc.Upload?.uploadData ? JSON.parse(doc.Upload?.uploadData).filename : doc.filename}
                  />
                  <a href={doc.Upload?.uploadData ? JSON.parse(doc.Upload?.uploadData).downloadUrl : doc.downloadUrl} target="_blank" rel="noreferrer" style={{ textDecoration: "none" }}>
                    <IconButton style={{ padding: "5px" }}>
                    <img src="/images/icons8-download.svg" alt="no" />
                    </IconButton>
                  </a>
                </ListItem>
              );
            })}
          </List>
        )}
      </Collapse>
    </List>
  );
};
